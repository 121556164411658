<template>
  <v-container class="pa-0" fluid style="height: 100vh">
    <v-row class="h-100" no-gutters>
      <v-col
        class="hidden-sm-and-down"
        md="7"
        style="background-color: #0d01c7"
      >
        <img
          src="@/assets/img/bg.jpg"
          width="100%"
          height="100%"
          alt="bg-image"
          style="width: 100%; height: 100%"
        />
      </v-col>
      <v-col cols="12" sm="8" md="5" class="mx-auto relative">
        <notification-message
          :message.sync="message"
          :type="error && message ? 'error' : 'success'"
        />
        <v-container class="d-flex align-center justify-center h-100">
          <v-card
            width="340"
            flat
            class="text-center animate__animated animate__zoomIn"
          >
            <v-col>
              <a
                class="black--text text-subtitle-1 d-flex justify-center align-center mb-4"
                href="https://snappyexchange.com"
              >
                <img
                  :src="logoSnappy"
                  alt="Snappy Exchange"
                  width="45"
                  height="45"
                />
                <span class="font-weight-bold">SnappyExchange</span>
              </a>
              <h3>Hello, {{ username }}</h3>
              <h5 class="font-weight-regular">
                Enter the code sent to your Email
              </h5>
            </v-col>
            <v-col>
              <v-form ref="verify" @submit.prevent="validate">
                <v-container>
                  <v-text-field
                    type="text"
                    outlined
                    rounded
                    :disabled="resending || loading"
                    hint="Enter the code sent to your email"
                    prepend-inner-icon="mdi-asterisk"
                    label="Code"
                    v-model="code"
                    :rules="[rules.required]"
                  ></v-text-field>

                  <v-btn
                    class="mt-5"
                    block
                    rounded
                    :loading="loading"
                    :disabled="resending"
                    color="primary"
                    elevation="2"
                    type="submit"
                    >Verify</v-btn
                  >
                  <div class="d-flex justify-end mt-5">
                    <v-btn
                      text
                      @click="resendCode"
                      :loading="resending"
                      :disabled="loading"
                      class="grey--text text--darken-2 text-decoration-none text-subtitle-2"
                      >Resend Code</v-btn
                    >
                  </div>
                </v-container>
              </v-form>
            </v-col>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import logoSnappy from '../../assets/logo.png'
import notificationMessage from '../../components/notification/notificationMessage.vue'
import api from '../../api/admin/auth'
import rules from '../../utils/rules'
import permissionApi from '../../api/admin/permissions'

export default {
  components: { notificationMessage },
  data() {
    return {
      error: false,
      message: null,
      loading: false,
      logoSnappy,
      resending: false,
      username: '',
      code: '',
      rules: {
        required: rules.required,
        email: rules.email
      }
    }
  },
  methods: {
    validate() {
      if (this.$refs.verify.validate()) this.verifyCode()
    },
    async resendCode() {
      this.resending = true
      const res = await api.auth().resendOTP({ userId: this.userId })
      if (res.error) {
        this.error = true
        this.message = res.errorMessage.message
        this.resending = false
        return
      }
      this.message = 'Code sent successfully, kindly check your email'
      this.error = false
      this.resending = false
    },
    async verifyCode() {
      this.loading = true
      const res = await api
        .auth()
        .verifyOTP({ userId: this.userId, otp: this.code })

      if (res.error) {
        this.error = true
        this.message = res.errorMessage.message
        this.loading = false
        return
      }

      // this.message = 'Login successful'
      this.error = false

      const token = res.data.data.token
      const admin = res.data.data.admin

      this.$store.commit('admin/setToken', token)
      this.$store.commit('admin/updateAdminData', admin)

      try {
        const profileReq = await permissionApi
          .permissions()
          .getCurrentUserPermissions()
        this.$store.commit('admin/updateAdminPermissions', profileReq.data.data)
      } catch (err) {}

      setTimeout(() => {
        if (this.redirect) {
          this.$router.push(this.redirect)
          return
        }
        this.$router.push('/yurd2m/yurd2m')
      }, 1000)
    }
  },
  created() {
    this.userId = this.$route.params.userId
    this.username = this.$route.query.username
  }
}
</script>

<style></style>
