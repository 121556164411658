import base64 from 'base-64'
import notificationsApi from '../../api/notifications'
import dataApi from '../../api/systemData'

const state = () => ({
  user: {},
  wallet: {},
  cashback: {},
  token: '',
  siteSettings: {},
  countryCurrency: {},
  currentRank: {},
  notifications: [],
  viewRankDialog: false
})

const getters = {
  getRequestHeaders: (state) => ({
    headers: {
      Authorization: 'Bearer ' + base64.decode(state.token)
    }
  })
}

const actions = {
  async getSiteSettings({ state }) {
    const res = await dataApi.data().siteSettings('user')
    state.siteSettings = res.data.data
  },
  async getCountryCurrency({ state }) {
    const res = await dataApi.data().countrySetting('user')
    const nigeria = res.data.data.find(
      (d) => d.country.toLowerCase() === 'nigeria'
    )
    const ghana = res.data.data.find((d) => d.country.toLowerCase() === 'ghana')
    const kenya = res.data.data.find((d) => d.country.toLowerCase() === 'kenya')

    state.countryCurrency = {
      nigeria,
      ghana,
      kenya
    }
  },
  async getNotifications({ state, commit }) {
    const res = await notificationsApi.notification().getForUser(state.user._id)
    commit(
      'updateNotification',
      res.data.data.data.filter((d) => !d.isRead)
    )
  },
  async markNotificationAsRead({ dispatch }, ids = []) {
    const res = await notificationsApi.notification().markAsReadUser(ids)
    if (res.error) {
      return
    }
    dispatch('getNotifications')
  }
}

const mutations = {
  toggleRankDialog(state) {
    state.viewRankDialog = !state.viewRankDialog
  },
  updateNotification(state, notifications) {
    state.notifications = notifications
  },
  updateUserData(state, user = {}) {
    if (Object.keys(user).length === 0) {
      state.notifications = []
      state.wallet = {}
      state.user = {}
      return
    }
    state.user = { ...state.user, ...user }
  },
  setWalletData(state, wallet) {
    if (wallet === null) {
      state.wallet = {}
      return
    }
    state.wallet = { ...state.wallet, ...wallet }
  },
  setCashback(state, cashback) {
    if (cashback === null) {
      state.cashback = {}
      return
    }
    state.cashback = { ...state.cashback, ...cashback }
  },
  setToken(state, token) {
    if (token === '') {
      localStorage.removeItem('jwt')
      state.token = ''
      return
    }
    const encodedToken = base64.encode(token)
    localStorage.setItem('jwt', encodedToken)
    state.token = encodedToken
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
