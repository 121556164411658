<template>
  <v-container class="py-10">
    <user-details :open.sync="userDetailsDialog" :user="user" />
    <suspend-dialog
      :open.sync="actionOnDialog"
      :type="actionType"
      :id="actionUserId"
      :callback="getUsers"
    />
    <v-card flat color="white">
      <v-card-title>
        <div class="">
          <h4>Filter</h4>
          <div>
            <v-select
              v-model="selectedCountry"
              :items="['All', 'Nigeria', 'Ghana', 'Kenya']"
              label="Country"
            />
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search..."
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :loading="loadingUser"
        :items="users"
        :search="search"
        :headers="headers"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 15, 25, 50, 100]
        }"
        @update:items-per-page="updateItemPerPage"
        @update:page="updatePage"
        :server-items-length="apiData.total"
      >
        <template v-slot:[`item.user`]="{ item }">
          <router-link
            :to="{ name: 'ViewUser', params: { id: item._id } }"
            class="font-weight-medium text-capitalize d-block"
            >{{ `${item.fname} ${item.lname}` }}
          </router-link>
          <small
            class="font-weight-regular grey--text text--darken-2 d-block"
            >{{ item.username }}</small
          >
        </template>
        <!-- <template v-slot:[`item.referral`]="{ item }">
          <span>{{
            item.referrals !== undefined ? item.referrals.length : 0
          }}</span>
        </template> -->
        <template v-slot:[`item.status`]="{ item }">
          <span
            :class="`text-capitalize rounded py-2 px-3 white--text ${
              statusInfo(item.status, item.activeStatus).color
            }`"
            >{{ statusInfo(item.status, item.activeStatus).text }}</span
          >
        </template>
        <template v-slot:[`item.registered`]="{ item }">
          <small>{{ item.createdAt }}</small>
        </template>
        <template v-slot:[`item.balance`]="{ item }">
          <span
            >{{ item.balance
            }}<small class="grey--text text--darken-2"> NGN</small></span
          >
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item ripple @click="selectUser(item)">
                <v-list-item-title>View Details</v-list-item-title>
              </v-list-item>
              <!--              <v-list-item-->
              <!--                ripple-->
              <!--                @click="actionOnUser(item, 'activate')"-->
              <!--                v-if="-->
              <!--                  item.activeStatus === 'deactivated' &&-->
              <!--                  checkIfPermission('manage user', admin.permissions)-->
              <!--                "-->
              <!--              >-->
              <!--                <v-list-item-title>Activate</v-list-item-title>-->
              <!--              </v-list-item>-->
              <!--              <v-list-item-->
              <!--                ripple-->
              <!--                @click="actionOnUser(item, 'suspend')"-->
              <!--                v-if="-->
              <!--                  item.activeStatus === 'active' &&-->
              <!--                  checkIfPermission('manage user', admin.permissions)-->
              <!--                "-->
              <!--              >-->
              <!--                <v-list-item-title>Suspend</v-list-item-title>-->
              <!--              </v-list-item>-->
            </v-list>
          </v-menu>
        </template>
        <template v-slot:[`item.verification`]="{ item }">
          <div class="d-flex">
            <span
              :class="`mx-1 font-weight-medium d-block ${
                item.email_verify ? 'success--text' : 'error--text'
              }`"
              >Email</span
            >

            <span
              :class="`mx-1 font-weight-medium d-block ${
                item.kycverify ? 'success--text' : 'error--text'
              }`"
              >KYC</span
            >
            <span
              :class="`mx-1 font-weight-medium d-block ${
                item.bvnVerified ? 'success--text' : 'error--text'
              }`"
              >BVN</span
            >
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import userDetails from '../userDetails.vue'
import suspendDialog from '../../components/actionOnDialog.vue'
import { mapState } from 'vuex'
import userManagement from '../../api/admin/userManagement'
import _ from 'lodash'
import dayjs from 'dayjs'

export default {
  components: { userDetails, suspendDialog },
  name: 'UserListTable',
  data: () => ({
    search: '',
    userDetailsDialog: false,
    actionOnDialog: false,
    user: {},
    userTransactions: [],
    headers: [
      {
        text: 'User',
        align: 'start',
        sortable: false,
        value: 'user'
      },
      {
        text: 'Email',
        value: 'email',
        sortable: false
      },
      {
        text: 'Country',
        value: 'country',
        sortable: false
      },
      {
        text: 'Verification',
        value: 'verification',
        sortable: false
      },
      {
        text: 'Status',
        value: 'status',
        sortable: false
      },
      {
        text: 'Referrals',
        value: 'referrals.length'
      },
      {
        text: 'Registered Date',
        value: 'registered'
      },
      {
        text: '',
        value: 'action'
      }
    ],
    actionType: {},
    actionUserId: '',
    selectedCountry: 'All',
    users: [],
    loadingUser: false,
    perPage: 10,
    apiData: {},
    lastPage: 1
  }),
  props: {
    userToGet: String
  },
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    }),
    statusInfo: () => (status, activeStatus) => {
      if (status && activeStatus === 'active') {
        return {
          color: 'success',
          text: 'Active'
        }
      }
      if (status && activeStatus === 'deactivated') {
        return {
          color: 'error',
          text: 'Deactivated/Deleted'
        }
      }
      if (!status && activeStatus === 'active') {
        return {
          color: 'error',
          text: 'Suspended'
        }
      }
      if (!status && activeStatus === 'deactivated') {
        return {
          color: 'error',
          text: 'Deactivated/Deleted'
        }
      }
    }
  },
  created() {
    this.getUsers()
    this.debounceSearch = _.debounce(this.searchData, 500)
  },
  watch: {
    search(newValue) {
      if (newValue === '') {
        this.getUsers(0)
        return
      }
      this.debounceSearch()
    },
    selectedCountry(newValue, prevValues) {
      if (newValue === prevValues) return
      this.getUsers(0)
    }
  },
  methods: {
    actionOnUser(user, type) {
      if (type === 'activate') {
        this.actionType = { action: 'activate', on: 'user' }
        this.actionUserId = user._id
        this.actionOnDialog = true
      } else if (type === 'suspend') {
        this.actionType = { action: 'suspend', on: 'user' }
        this.actionUserId = user._id
        this.actionOnDialog = true
      } else {
      }
    },
    updatePage(page) {
      if (this.lastPage - page > 2) {
        page = this.lastPage
      }
      this.lastPage = page
      this.getUsers((page - 1) * this.perPage)
    },
    updateItemPerPage(count) {
      this.perPage = count
      this.getUsers((this.lastPage - 1) * this.perPage)
    },
    selectUser(user) {
      this.user = user
      // console.log(user)
      this.userDetailsDialog = true
    },
    async searchData() {
      this.loadingUser = true
      const get = this.userToGet === 'inactive' ? undefined : this.userToGet
      const res = await userManagement.users().getAll(get, {
        limit: this.perPage,
        keyword: this.search,
        ...this.getQuery()
      })
      if (res.error) {
        this.alert({
          message: 'Unable to fetch users',
          error: true,
          time: 4000,
          status: true
        })
        this.loadingActive = false
        return
      }
      this.users = res.data.data.data.map((d) => ({
        ...d,
        user: `${d.fname} ${d.lname} ${d.username}`,
        createdAt: dayjs(d.createdAt)
          .subtract(1, 'hour')
          .format('dddd, MMMM D, YYYY h:mm A')
      }))
      this.apiData = res.data.data.meta
      this.loadingUser = false
    },

    async getUsers(length) {
      this.loadingUser = true
      const get = this.userToGet === 'inactive' ? undefined : this.userToGet
      const res = await userManagement.users().getAll(get, {
        limit: this.perPage,
        country: this.selectedCountry === 'All' ? null : this.selectedCountry,
        offset: length,
        ...this.getQuery()
      })
      if (res.error) {
        this.alert({
          message: 'Unable to fetch users',
          error: true,
          time: 4000,
          status: true
        })
        this.loadingActive = false
        return
      }
      this.users = res.data.data.data.map((d) => ({
        ...d,
        user: `${d.fname} ${d.lname} ${d.username}`,
        createdAt: dayjs(d.createdAt)
          .subtract(1, 'hour')
          .format('dddd, MMMM D, YYYY h:mm A')
      }))
      this.apiData = res.data.data.meta
      this.loadingUser = false
    },

    getQuery() {
      switch (this.userToGet) {
        case 'all':
          return {
            activeStatus: 'active',
            status: true
          }
        case 'active':
          return {
            activeStatus: 'active',
            status: true,
            isActive: true
          }
        case 'inactive':
          return {
            activeStatus: 'active',
            status: true,
            isActive: false
          }
        case 'deactivated':
          return {
            activeStatus: 'deactivated'
          }
        default:
          return {
            activeStatus: 'active',
            status: true
          }
      }
    }
  }
}
</script>

<style></style>
