<template>
  <v-app id="app">
    <v-overlay
      opacity="0.95"
      class="text-center"
      style="z-index: 10000"
      :value="tokenExpired"
    >
      <v-progress-circular
        class="text-center"
        indeterminate
        size="64"
      ></v-progress-circular>
      <p class="text-center text-h6" v-if="tokenExpired">
        Sorry, your session has expired!
      </p>
    </v-overlay>
    <v-alert
      :value="alert.status"
      style="
        right: 15px;
        text-transform: capitalize;
        top: 30px;
        z-index: 5000;
        position: fixed;
      "
      :type="`${alert.message && alert.error ? 'error' : 'success'}`"
      transition="slide-y-transition"
      dismissible
    >
      {{ alert.message }}
    </v-alert>
    <router-view />
    <!--    <whatsapp-->
    <!--      v-if="-->
    <!--        !$route.fullPath.includes('admin') && !$route.fullPath.includes('chat')-->
    <!--      "-->
    <!--    />-->
  </v-app>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// import whatsapp from './components/whatsapp.vue'

export default {
  components: {},
  computed: {
    ...mapState({
      alert: (state) => state.alert,
      tokenExpired: (state) => state.security.tokenExpired
    })
  },
  watch: {
    tokenExpired(newValue, oldValue) {
      if (newValue === true) {
        setTimeout(() => {
          if (this.$route.matched[0].path === '/yurd2m') {
            this.$router.push(`/yurd2m?redirect=${this.$route.fullPath}`)
          } else {
            this.$router.push(`/login?redirect=${this.$route.fullPath}`)
          }
        }, 3500)
      }
    }
  },
  methods: {
    ...mapMutations(['setTokenExpiredState'])
  }
}
</script>
