import Store from '../../store'
import { errorHandler, successHandler } from '../helpers/handler'
import adminHttp from './http'

const axios = adminHttp
const URL = `wallets`

export default {
  data() {
    return {
      getUserWallets: async ({ ...params }) => {
        const endpoint = `${URL}`
        // console.log(encodeURI(endpoint))
        try {
          const res = await axios.get(endpoint, {
            params,
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getWalletHistoryForAUser: async (userId) => {
        const endpoint = `wallet-histories?user=${userId}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getWalletHistory: async ({ params }) => {
        const endpoint = `wallet-histories`
        try {
          const res = await axios.get(endpoint, {
            params,
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getCashbackWalletBalance: async (params) => {
        const endpoint = `${URL}/cashback-histories`
        try {
          const res = await axios.get(endpoint, {
            params,
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      viewFundRequests: async (params = {}) => {
        const endpoint = `wallet/fund-request`
        try {
          const res = await axios.get(endpoint, {
            params,
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      updateFundingRequest: async (id, data) => {
        const endpoint = `wallet/fund-request/${id}`
        try {
          const res = await axios.put(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getUserCashback: async (params) => {
        const endpoint = `${URL}/cashback`
        try {
          const res = await axios.get(endpoint, {
            params,
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
