<template>
  <v-container>
    <div
      v-if="!checkIfPermission('view wallet', admin.permissions)"
      class="w-100 h-100 d-flex justify-center align-center"
    >
      <h1 class="error--text">You don't have access to this page</h1>
    </div>
    <div>
      <h1 class="mb-5">Cashback</h1>
      <user-details-min :userId="minUserId" :open.sync="openMin" />
      <v-row>
        <v-col>
          <v-card flat color="white">
            <v-card-title>
              <div class="flex items-center">
                <div class="mr-2">
                  <p class="mb-0 text-sm">Order By</p>
                  <v-select
                    v-model="selectedOrderBy"
                    :items="[
                      {
                        text: 'Points',
                        value: 'balance'
                      },
                      {
                        text: 'Total Earned',
                        value: 'totalEarned'
                      },
                      {
                        text: 'Total Redeemed',
                        value: 'totalRedeemed'
                      },
                      {
                        text: 'Country',
                        value: 'country'
                      }
                    ]"
                    label="Order By"
                  />
                </div>
                <div>
                  <p class="mb-0 text-sm">Order</p>
                  <v-select
                    v-model="selectedOrder"
                    :items="[
                      {
                        text: 'Ascending',
                        value: 'asc'
                      },
                      {
                        text: 'Descending',
                        value: 'desc'
                      }
                    ]"
                    label="Order"
                  />
                </div>
                <div v-if="selectedOrderBy === 'country'">
                  <p class="mb-0 text-sm">Country</p>
                  <v-select
                    v-model="selectedCountry"
                    :items="['All', 'Nigeria', 'Ghana', 'Kenya']"
                    label="Country"
                  />
                </div>
              </div>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search..."
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :search="search"
              :headers="headers"
              :items="arrangedTransaction"
              :items-per-page="perPage"
              :loading="loadingTransaction"
              :footer-props="{
                itemsPerPageOptions: [5, 10, 15, 25, 50, 100]
              }"
              @update:items-per-page="updateItemPerPage"
              @update:page="updatePage"
              :server-items-length="apiData.total"
            >
              <template v-slot:[`item.user`]="{ item }">
                <span
                  class="font-weight-medium cursor-pointer primary--text text-capitalize d-block"
                  @click="openMinDialog(item.user_id)"
                >
                  {{ item.user }}
                </span>
              </template>
              <template v-slot:[`item.balance`]="{ item }">
                <span> {{ numberWithCommas(Number(item.balance)) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import walletApi from '../../../api/admin/wallet'
import { mapState } from 'vuex'
import UserDetailsMin from '../../../components/userDetailsMin.vue'
import { numberWithCommas } from '../../../utils/helpers'
import _ from 'lodash'
import { getCurrencyCode } from '../../../utils/countryHelpers'

export default {
  components: {
    UserDetailsMin
  },
  data: () => ({
    numberWithCommas,
    openMin: false,
    minUserId: '',
    progress: 'starting',
    search: '',
    transactionOpen: false,
    dialogDelete: false,
    headers: [
      {
        text: 'User',
        sortable: false,
        value: 'user'
      },
      {
        text: 'Name',
        sortable: false,
        value: 'name'
      },
      {
        text: 'Email',
        sortable: false,
        value: 'email'
      },
      {
        text: 'Points',
        value: 'balance'
      },
      {
        text: 'Total Redeemed',
        value: 'totalRedeemed'
      },
      {
        text: 'Total Earned',
        value: 'totalEarned'
      },
      {
        text: 'Country',
        value: 'country'
      }
    ],
    transactions: [],
    transaction: {},
    perPage: 10,
    loadingTransaction: false,
    apiData: {},
    lastPage: 1,
    selectedOrderBy: 'balance',
    selectedOrder: 'desc',
    selectedCountry: 'All'
  }),
  created() {
    this.getTransactions(0)
    this.debounceSearch = _.debounce(this.searchData, 500)
  },
  watch: {
    progress(newValue, oldValue) {
      if (newValue === 'done') {
        this.getTransactions()
      }
      this.progress = 'starting'
    },
    search(newValue) {
      if (newValue === '') {
        this.getTransactions(0)
        return
      }
      this.debounceSearch()
    },
    selectedOrderBy() {
      this.getTransactions(0)
    },
    selectedOrder() {
      this.getTransactions(0)
    },
    selectedCountry() {
      this.getTransactions(0)
    }
  },
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    }),
    arrangedTransaction() {
      if (this.transactions.length !== 0) {
        return this.transactions.map((transaction) => ({
          ...transaction,
          id: transaction._id,
          user: transaction.user ? transaction.user.username : '',
          email: transaction.user ? transaction.user.email : '',
          name: transaction.user
            ? transaction.user.fname + ' ' + transaction.user.lname
            : '',
          user_id: transaction.user ? transaction.user._id : '',
          currency:
            transaction.currencyCode ??
            getCurrencyCode(transaction?.user?.country)
        }))
      }
      return []
    }
  },
  methods: {
    openMinDialog(id) {
      this.openMin = true
      this.minUserId = id
    },
    async searchData() {
      this.loadingTransaction = true
      this.transactionsM = []
      const res = await walletApi.data().getUserCashback({
        populate: ['user'],
        limit: this.perPage,
        keyword: this.search,
        orderBy: this.selectedOrderBy,
        order: this.selectedOrder,
        country:
          this.selectedCountry === 'All' ? undefined : this.selectedCountry
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        return
      }
      this.transactions = res.data.data.data
      this.apiData = res.data.data.meta
      this.loadingTransaction = false
    },
    updatePage(page) {
      if (this.lastPage - page > 2) {
        page = this.lastPage
      }
      this.lastPage = page
      this.getTransactions((page - 1) * this.perPage)
    },
    updateItemPerPage(count) {
      this.perPage = count
      this.getTransactions((this.lastPage - 1) * this.perPage)
    },
    async getTransactions(length) {
      this.loadingTransaction = true
      const res = await walletApi.data().getUserCashback({
        populate: ['user'],
        limit: this.perPage,
        offset: length,
        orderBy: this.selectedOrderBy,
        order: this.selectedOrder,
        country:
          this.selectedCountry === 'All' ? undefined : this.selectedCountry
      })

      this.transactions = res.data.data.data
      this.apiData = res.data.data.meta
      this.loadingTransaction = false
    }
  }
}
</script>

<style></style>
