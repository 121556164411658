<template>
  <v-container class="py-10">
    <user-details-min :userId="minUserId" :open.sync="openMin" />
    <user-details :open.sync="userDetailsDialog" :user="user" />
    <v-card flat color="white">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search..."
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :loading="loadingUser"
        :items="users"
        :search="search"
        :headers="headers"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 15, 25, 50, 100]
        }"
        @update:items-per-page="updateItemPerPage"
        @update:page="updatePage"
        :server-items-length="apiData.total"
      >
        <template v-slot:[`item.user`]="{ item }">
          <!-- <router-link
            :to="{ name: 'ViewUser', params: { id: item._id } }"
            class="font-weight-medium text-capitalize d-block"
            >{{ `${item.fname} ${item.lname}` }}</router-link
          > -->
          <span
            class="font-weight-medium cursor-pointer primary--text text-capitalize d-block"
            @click="openMinDialog(item._id)"
          >
            {{ item.fname }} {{ item.lname }}
          </span>
          <small
            class="font-weight-regular grey--text text--darken-2 d-block"
            >{{ item.username }}</small
          >
        </template>
        <template v-slot:[`item.created`]="{ item }">
          <span>{{
            new Date(item.created_at || item.createdAt).toDateString()
          }}</span>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import userDetails from '../userDetails.vue'
import { mapState } from 'vuex'
import UserDetailsMin from '../userDetailsMin.vue'
import userManagement from '../../api/admin/userManagement'
import _ from 'lodash'

export default {
  components: { userDetails, UserDetailsMin },
  name: 'UserListTableReferral',
  data: () => ({
    openMin: false,
    minUserId: '',
    search: '',
    userDetailsDialog: false,
    actionOnDialog: false,
    user: {},
    userTransactions: [],
    headers: [
      {
        text: 'User',
        align: 'start',
        sortable: false,
        value: 'user'
      },
      {
        text: 'Referrals',
        value: 'referrals.length'
      },
      {
        text: 'Date Joined',
        value: 'created'
      }
    ],
    actionType: {},
    actionUserId: '',
    users: [],
    loadingUser: false,
    perPage: 10,
    apiData: {},
    lastPage: 1
  }),
  props: {
    userToGet: String
  },
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    })
  },
  created() {
    this.getUsers()
    this.debounceSearch = _.debounce(this.searchData, 500)
  },
  watch: {
    search(newValue) {
      if (newValue === '') {
        this.getTransactions(0)
        return
      }
      this.debounceSearch()
    }
  },
  methods: {
    openMinDialog(id) {
      this.openMin = true
      this.minUserId = id
    },
    selectUser(user) {
      this.user = user
      // console.log(user)
      this.userDetailsDialog = true
    },
    updatePage(page) {
      if (this.lastPage - page > 2) {
        page = this.lastPage
      }
      this.lastPage = page
      this.getUsers((page - 1) * this.perPage)
    },
    updateItemPerPage(count) {
      this.perPage = count
      this.getUsers((this.lastPage - 1) * this.perPage)
    },
    async searchData() {
      this.loadingUser = true
      const res = await userManagement.users().getReferrals({
        limit: this.perPage,
        keyword: this.search
      })

      if (res.error) {
        this.alert({
          message: 'Unable to fetch users',
          error: true,
          time: 4000,
          status: true
        })
        this.loadingActive = false
        return
      }

      this.users = res.data.data.data.map((d) => ({
        ...d,
        user: `${d.fname} ${d.lname} ${d.username}`
      }))
      this.apiData = res.data.data.meta
      this.loadingUser = false
    },

    async getUsers(length) {
      this.loadingUser = true
      const res = await userManagement.users().getReferrals({
        limit: this.perPage,
        offset: length
      })
      if (res.error) {
        this.alert({
          message: 'Unable to fetch users',
          error: true,
          time: 4000,
          status: true
        })
        this.loadingActive = false
        return
      }
      this.users = res.data.data.data.map((d) => ({
        ...d,
        user: `${d.fname} ${d.lname} ${d.username}`
      }))
      this.apiData = res.data.data.meta
      this.loadingUser = false
    }
  }
}
</script>

<style></style>
