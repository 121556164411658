import axios from 'axios'
import { ADMIN_BASEURL } from '../const'

const URL = `${ADMIN_BASEURL}`

const adminHttp = axios.create({
  baseURL: URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

adminHttp.interceptors.request.use(
  (config) => {
    const deviceId = localStorage.getItem('deviceId')
    if (deviceId) {
      config.headers['x-auth-token'] = deviceId
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
export default adminHttp
