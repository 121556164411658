var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(!_vm.checkIfPermission('view wallet', _vm.admin.permissions))?_c('div',{staticClass:"w-100 h-100 d-flex justify-center align-center"},[_c('h1',{staticClass:"error--text"},[_vm._v("You don't have access to this page")])]):_vm._e(),_c('div',[_c('h1',{staticClass:"mb-5"},[_vm._v("Cashback")]),_c('user-details-min',{attrs:{"userId":_vm.minUserId,"open":_vm.openMin},on:{"update:open":function($event){_vm.openMin=$event}}}),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"flat":"","color":"white"}},[_c('v-card-title',[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"mr-2"},[_c('p',{staticClass:"mb-0 text-sm"},[_vm._v("Order By")]),_c('v-select',{attrs:{"items":[
                    {
                      text: 'Points',
                      value: 'balance'
                    },
                    {
                      text: 'Total Earned',
                      value: 'totalEarned'
                    },
                    {
                      text: 'Total Redeemed',
                      value: 'totalRedeemed'
                    },
                    {
                      text: 'Country',
                      value: 'country'
                    }
                  ],"label":"Order By"},model:{value:(_vm.selectedOrderBy),callback:function ($$v) {_vm.selectedOrderBy=$$v},expression:"selectedOrderBy"}})],1),_c('div',[_c('p',{staticClass:"mb-0 text-sm"},[_vm._v("Order")]),_c('v-select',{attrs:{"items":[
                    {
                      text: 'Ascending',
                      value: 'asc'
                    },
                    {
                      text: 'Descending',
                      value: 'desc'
                    }
                  ],"label":"Order"},model:{value:(_vm.selectedOrder),callback:function ($$v) {_vm.selectedOrder=$$v},expression:"selectedOrder"}})],1),(_vm.selectedOrderBy === 'country')?_c('div',[_c('p',{staticClass:"mb-0 text-sm"},[_vm._v("Country")]),_c('v-select',{attrs:{"items":['All', 'Nigeria', 'Ghana', 'Kenya'],"label":"Country"},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}})],1):_vm._e()]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search...","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.arrangedTransaction,"items-per-page":_vm.perPage,"loading":_vm.loadingTransaction,"footer-props":{
              itemsPerPageOptions: [5, 10, 15, 25, 50, 100]
            },"server-items-length":_vm.apiData.total},on:{"update:items-per-page":_vm.updateItemPerPage,"update:page":_vm.updatePage},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium cursor-pointer primary--text text-capitalize d-block",on:{"click":function($event){return _vm.openMinDialog(item.user_id)}}},[_vm._v(" "+_vm._s(item.user)+" ")])]}},{key:"item.balance",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.numberWithCommas(Number(item.balance))))])]}}],null,true)})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }