<template>
  <v-container class="pb-10">
    <div>
      <h1 class="mb-5">Referrals</h1>
      <v-row>
        <v-col class="" cols="12">
          <v-tabs color="accent" v-model="tab">
            <v-tab>User Referrals </v-tab>
            <v-tab>Top Referrers List</v-tab>
            <v-tab>Referrers Earnings</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <user-list-table />
            </v-tab-item>

            <v-tab-item>
              <user-list-table-referral />
            </v-tab-item>

            <v-tab-item>
              <user-list-table-referral-earnings />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import UserListTable from '../../../components/admin/userListReferrals'
import UserListTableReferral from '../../../components/admin/userListTopReferrals'
import UserListTableReferralEarnings from '../../../components/admin/userListReferralEarnings'

export default {
  components: {
    UserListTable,
    UserListTableReferral,
    UserListTableReferralEarnings
  },
  name: 'Users',

  data: () => ({
    tab: null,
    transactionTab: null,
    userDetailsDialog: false,
    active: [],
    inactive: [],
    loadingActive: false,
    loadingInactive: false
  }),
  created() {},
  methods: {
    ...mapActions({
      alert: 'alert'
    })
  },
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    })
  }
}
</script>

<style></style>
