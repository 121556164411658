<template>
  <v-container>
    <div
      v-if="!checkIfPermission('view wallet', admin.permissions)"
      class="w-100 h-100 d-flex justify-center align-center"
    >
      <h1 class="error--text">You don't have access to this page</h1>
    </div>
    <div v-else>
      <h1 class="mb-5">Cashback</h1>
      <user-details-min :userId="minUserId" :open.sync="openMin" />

      <v-card :loading="loadingStats">
        <v-card-title>
          <h3 class="text-h5">Statistics</h3>
        </v-card-title>
        <div class="p-2">
          <div v-if="info.totalEarnedPoints" class="grid grid-cols-4 gap-5">
            <div class="bg-yellow-100 rounded-md px-4 py-6 w-full shadow">
              <p class="text-xs text-opacity-75 m-0 mb-0">
                Total Earned Points
              </p>
              <p class="m-0 mb-0 text-2xl font-medium">
                {{ info.totalEarnedPoints }}
              </p>
            </div>
            <div class="bg-blue-100 rounded-md px-4 py-6 w-full shadow">
              <p class="text-xs text-opacity-75 m-0 mb-0">
                Total Redeemed Points
              </p>
              <p class="m-0 mb-0 text-2xl font-medium">
                {{ info.totalRedeemedPoints }}
              </p>
            </div>
            <div class="bg-green-100 rounded-md px-4 py-6 w-full shadow">
              <p class="text-xs text-opacity-75 m-0 mb-0">
                Total Users With Points
              </p>
              <p class="m-0 mb-0 text-2xl font-medium">
                {{ info.usersWithPoints }}
              </p>
            </div>

            <div class="bg-purple-100 rounded-md px-4 py-6 w-full shadow">
              <p class="text-xs text-opacity-75 m-0 mb-0">
                Total Unredeemed Points
              </p>
              <p class="m-0 mb-0 text-2xl font-medium">
                {{ info.totalUnredeemedPoints }}
              </p>
            </div>
          </div>
        </div>
      </v-card>

      <v-card class="mt-3">
        <v-card-title> Filter</v-card-title>
      </v-card>

      <v-row class="mt-5">
        <v-col>
          <v-tabs v-model="activeTab">
            <v-tab>Earned</v-tab>
            <v-tab>Redeemed</v-tab>
          </v-tabs>
          <v-tabs-items v-model="activeTab">
            <v-tab-item>
              <v-card flat color="white">
                <v-card-title>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="searchCredit"
                    append-icon="mdi-magnify"
                    label="Search..."
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :search="searchCredit"
                  :headers="headers"
                  :items="arrangedTransactionCredit"
                  :items-per-page="perPage"
                  :loading="loadingTransactionCredit"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10, 15, 25, 50, 100]
                  }"
                  @update:items-per-page="updateItemPerPageCredit"
                  @update:page="updatePageCredit"
                  :server-items-length="apiDataCredit.total"
                >
                  <template v-slot:[`item.user`]="{ item }">
                    <span
                      class="font-weight-medium cursor-pointer primary--text text-capitalize d-block"
                      @click="openMinDialog(item.user_id)"
                    >
                      {{ item.user }}
                    </span>
                  </template>

                  <template v-slot:[`item.createdAt`]="{ item }">
                    <span>{{
                      new Date(item.date_string).toLocaleDateString()
                    }}</span>
                  </template>

                  <template v-slot:[`item.name`]="{ item }">
                    <span>{{ item.name }}</span>
                    <br />
                    <span class="text-gray-500">{{ item.email }}</span>
                  </template>

                  <template v-slot:[`item.source`]="{ item }">
                    <div>
                      <span class="capitalize">{{ item.source }}</span>
                      <div v-if="item.source === 'transaction'">
                        <span class="capitalize text-sm text-gray-500"
                          >Item: {{ item.category }}</span
                        >
                      </div>
                    </div>
                  </template>

                  <!-- <template v-slot:[`item.date`]="{ item }">
                <small class="font-weight-medium">{{ item.date }}</small>
              </template> -->
                  <template v-slot:[`item.amount`]="{ item }">
                    <span> {{ Number(item.amount).toFixed(2) }}</span>
                  </template>
                </v-data-table>
                <!-- <delete-dialog :open.sync="dialogDelete" type="sales" /> -->
                <fund-details
                  :actionProgress.sync="progress"
                  :open.sync="transactionOpen"
                  :transaction="transaction"
                />
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat color="white">
                <v-card-title>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="searchDebit"
                    append-icon="mdi-magnify"
                    label="Search..."
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :search="searchDebit"
                  :headers="headers"
                  :items="arrangedTransaction"
                  :items-per-page="perPage"
                  :loading="loadingTransaction"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10, 15, 25, 50, 100]
                  }"
                  @update:items-per-page="updateItemPerPage"
                  @update:page="updatePage"
                  :server-items-length="apiData.total"
                >
                  <template v-slot:[`item.user`]="{ item }">
                    <!-- <router-link
                  :to="{ name: 'ViewUser', params: { id: item.user_id } }"
                  class="font-weight-medium text-capitalize d-block"
                  >{{ item.user }}</router-link
                > -->
                    <span
                      class="font-weight-medium cursor-pointer primary--text text-capitalize d-block"
                      @click="openMinDialog(item.user_id)"
                    >
                      {{ item.user }}
                    </span>
                  </template>

                  <template v-slot:[`item.createdAt`]="{ item }">
                    <span>{{
                      new Date(item.date_string).toLocaleDateString()
                    }}</span>
                  </template>

                  <template v-slot:[`item.name`]="{ item }">
                    <span>{{ item.name }}</span>
                    <br />
                    <span class="text-sm text-gray-500">{{ item.email }}</span>
                  </template>

                  <template v-slot:[`item.source`]="{ item }">
                    <div>
                      <span class="capitalize">{{ item.source }}</span>
                    </div>
                  </template>

                  <!-- <template v-slot:[`item.date`]="{ item }">
                <small class="font-weight-medium">{{ item.date }}</small>
              </template> -->
                  <template v-slot:[`item.amount`]="{ item }">
                    <span> {{ Number(item.amount).toFixed(2) }}</span>
                  </template>
                </v-data-table>
                <!-- <delete-dialog :open.sync="dialogDelete" type="sales" /> -->
                <fund-details
                  :actionProgress.sync="progress"
                  :open.sync="transactionOpen"
                  :transaction="transaction"
                />
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import fundDetails from '../../../components/fundDetails.vue'
import walletApi from '../../../api/admin/wallet'
import { mapState } from 'vuex'
import UserDetailsMin from '../../../components/userDetailsMin.vue'
import { numberWithCommas } from '../../../utils/helpers'
import _ from 'lodash'
import systemData from '../../../api/systemData'

export default {
  components: {
    fundDetails,
    UserDetailsMin
  },
  data: () => ({
    numberWithCommas,
    activeTab: null,
    openMin: false,
    minUserId: '',
    progress: 'starting',

    searchCredit: '',
    searchDebit: '',
    transactionOpen: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Date',
        value: 'createdAt'
      },
      {
        text: 'User',
        sortable: false,
        value: 'user'
      },
      {
        text: 'Name',
        sortable: false,
        value: 'name'
      },
      {
        text: 'Amount',
        value: 'amount'
      },
      {
        text: 'Source',
        value: 'source'
      },
      {
        text: 'Country',
        value: 'country'
      }
    ],
    transactions: [],
    transactionsCredit: [],
    transaction: {},
    perPage: 10,
    loadingTransaction: false,
    loadingTransactionCredit: false,
    apiData: {},
    apiDataCredit: {},
    lastPage: 1,
    lastPageCredit: 1,
    info: {
      totalEarnedPoints: 0,
      totalRedeemedPoints: 0,
      usersWithPoints: 0,
      totalUnredeemedPoints: 0
    },
    loadingStats: false
  }),
  created() {
    this.getCashbackStats()
    this.getTransactions(0)
    this.getTransactionsCredit(0)
    this.debounceSearch = _.debounce(this.searchData, 500)
    this.debounceSearchCredit = _.debounce(this.searchDataCredit, 500)
  },
  watch: {
    searchCredit(newValue) {
      if (newValue === '') {
        this.getTransactionsCredit(0)
        return
      }
      this.debounceSearchCredit()
    },
    searchDebit(newValue) {
      if (newValue === '') {
        this.getTransactions(0)
        return
      }
      this.debounceSearch()
    }
  },
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    }),
    arrangedTransaction() {
      if (this.transactions.length !== 0) {
        return this.transactions.map((transaction) => ({
          ...transaction,
          id: transaction._id,
          user: transaction.user ? transaction.user.username : '',
          email: transaction.user ? transaction.user.email : '',
          name: transaction.user
            ? transaction.user.fname + ' ' + transaction.user.lname
            : '',
          user_id: transaction.user ? transaction.user._id : '',
          currency:
            transaction.currencyCode ?? transaction.user.country === 'Ghana'
              ? 'GHC'
              : 'NGN'
        }))
      }
      return []
    },
    arrangedTransactionCredit() {
      if (this.transactionsCredit.length !== 0) {
        return this.transactionsCredit.map((transaction) => ({
          ...transaction,
          id: transaction._id,
          user: transaction.user ? transaction.user.username : '',
          email: transaction.user ? transaction.user.email : '',
          name: transaction.user
            ? transaction.user.fname + ' ' + transaction.user.lname
            : '',
          user_id: transaction.user ? transaction.user._id : '',
          currency: this.countryToCurrency(transaction.country)
        }))
      }
      return []
    }
  },
  methods: {
    countryToCurrency(country) {
      switch (country) {
        case 'Nigeria':
          return 'NGN'
        case 'Ghana':
          return 'GHC'
        case 'Kenya':
          return 'KES'
        default:
          return 'NGN'
      }
    },
    openMinDialog(id) {
      this.openMin = true
      this.minUserId = id
    },
    async searchData() {
      this.loadingTransaction = true
      const res = await walletApi.data().getWalletHistory({
        populate: ['user'],
        limit: this.perPage,
        type: 'credit',
        keyword: this.searchDebit
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        return
      }
      this.transactions = res.data.data.data
      this.apiData = res.data.data.meta
      this.loadingTransaction = false
    },
    async searchDataCredit() {
      this.loadingTransactionCredit = true
      const res = await walletApi.data().getCashbackWalletBalance({
        populate: ['user'],
        limit: this.perPage,
        type: 'credit',
        keyword: this.searchCredit
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        return
      }
      this.transactionsCredit = res.data.data.data
      this.apiDataCredit = res.data.data.meta
      this.loadingTransactionCredit = false
    },
    updatePage(page) {
      if (this.lastPage - page > 2) {
        page = this.lastPage
      }
      this.lastPage = page
      this.getTransactions((page - 1) * this.perPage)
    },
    updatePageCredit(page) {
      if (this.lastPageCredit - page > 2) {
        page = this.lastPageCredit
      }
      this.lastPageCredit = page
      this.getTransactionsCredit((page - 1) * this.perPage)
    },
    updateItemPerPage(count) {
      this.perPage = count
      this.getTransactions((this.lastPage - 1) * this.perPage)
    },
    updateItemPerPageCredit(count) {
      this.perPage = count
      this.getTransactionsCredit((this.lastPageCredit - 1) * this.perPage)
    },
    async getTransactions(length) {
      this.loadingTransaction = true
      const res = await walletApi.data().getCashbackWalletBalance({
        populate: ['user'],
        limit: this.perPage,
        offset: length,
        type: 'debit'
      })

      this.transactions = res.data.data.data
      this.apiData = res.data.data.meta
      this.loadingTransaction = false
    },
    async getTransactionsCredit(length) {
      this.loadingTransactionCredit = true
      const res = await walletApi.data().getCashbackWalletBalance({
        populate: ['user'],
        limit: this.perPage,
        offset: length,
        type: 'credit'
      })

      this.transactionsCredit = res.data.data.data
      this.apiDataCredit = res.data.data.meta
      this.loadingTransactionCredit = false
    },
    async getCashbackStats() {
      this.loadingStats = true

      const res = await systemData.data().cashbackStats()
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.loadingStats = false

        return
      }

      this.info = res.data.data
      this.loadingStats = false
      console.log(this.info)
    }
  }
}
</script>

<style></style>
