<template>
  <v-container class="pb-10">
    <div
      v-if="!checkIfPermission('view ambassador', admin.permissions)"
      class="w-100 h-100 d-flex justify-center align-center"
    >
      <h1 class="error--text">You don't have access to this page</h1>
    </div>
    <div v-else>
      <v-dialog v-model="dialogCreateStaff" max-width="700px">
        <v-form ref="createStaffForm" @submit.prevent="validate">
          <v-card class="py-4 pt-0">
            <v-toolbar flat class="mb-2" color="primary">
              <v-card-title class="white--text">
                {{
                  isEditAmbassador ? 'Update Ambassador' : 'Create Ambassador'
                }}
              </v-card-title>
            </v-toolbar>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      filled
                      :rules="[rules.required]"
                      :disabled="loading"
                      v-model="createStaff.firstName"
                      label="First Name"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      filled
                      :disabled="loading"
                      :rules="[rules.required]"
                      v-model="createStaff.lastName"
                      label="Last Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      filled
                      :disabled="loading"
                      :rules="[rules.required]"
                      v-model="createStaff.referralCode"
                      label="Referral Code"
                      type="email"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                :disabled="loading"
                @click="dialogCreateStaff = false"
              >
                Cancel
              </v-btn>
              <v-btn
                :loading="loading"
                color="blue darken-1"
                text
                type="submit"
              >
                {{
                  isEditAmbassador ? 'Update Ambassador' : 'Create Ambassador'
                }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <h1 class="mb-5">Ambassador</h1>
      <v-row>
        <v-col class="" cols="12">
          <v-container class="py-10">
            <v-card flat color="white">
              <v-card-title class="d-flex align-baseline">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search..."
                  single-line
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  v-if="
                    checkIfPermission('create ambassador', admin.permissions)
                  "
                  @click="dialogCreateStaff = true"
                  class="ml-4 font-weight-bold"
                  >Add Ambassador
                </v-btn>
              </v-card-title>
              <v-data-table
                :loading="loadingAmbassador"
                :items="data"
                :search="search"
                :headers="headers"
                :footer-props="{
                  itemsPerPageOptions: [5, 10, 15, 25, 50, 100]
                }"
                @update:items-per-page="updateItemPerPage"
                @update:page="updatePage"
                :server-items-length="apiData.total"
              >
                <template v-slot:[`item.user`]="{ item }">
                  <span class="font-weight-medium d-block">
                    {{ item.lastName }}
                    {{ item.firstName }}</span
                  >
                </template>
                <template v-slot:[`item.referrals`]="{ item }">
                  <span>{{ item.referrals.length }}</span>
                </template>
                <template v-slot:[`item.referralCode`]="{ item }">
                  <span>{{ item.referralCode }}</span>
                  <span class="block text-gray-600 text-xs">
                    app.snappyexchange.com/register?ref={{ item.referralCode }}
                  </span>
                </template>
                <template v-slot:[`item.createdAt`]="{ item }">
                  <span>{{
                    new Date(item.createdAt).toLocaleDateString('en-US', {})
                  }}</span>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        @click="onEditAmbassador(item)"
                        v-if="
                          checkIfPermission(
                            'update ambassador',
                            admin.permissions
                          )
                        "
                        ripple
                      >
                        <v-list-item-title>Edit</v-list-item-title>
                      </v-list-item>

                      <v-list-item
                        @click="onDeleteAmbassador(item)"
                        v-if="
                          checkIfPermission(
                            'delete ambassador',
                            admin.permissions
                          )
                        "
                        ripple
                      >
                        <v-list-item-title>Delete</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <template v-slot:[`item.registered`]="{ item }">
                  <small>{{ item.registered }}</small>
                </template>
              </v-data-table>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import userApi from '../../../api/admin/userManagement'
import rules from '../../../utils/rules'
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  components: {},
  name: 'Ambassadors',
  data: () => ({
    loading: false,
    dialogCreateStaff: false,
    createStaff: {},
    rules: {
      required: rules.required,
      minimum: rules.valueWithAtLeast8Characters,
      email: rules.email
    },
    headers: [
      {
        text: 'Name',
        align: 'start',
        sortable: false,
        value: 'user'
      },
      {
        text: 'Referral Code',
        value: 'referralCode'
      },
      {
        text: 'Trade Amount',
        value: 'tradeAmount'
      },
      {
        text: 'Referrals',
        value: 'referrals'
      },
      {
        text: 'Trade Count',
        value: 'tradeCount'
      },
      {
        text: 'Created On',
        value: 'createdAt'
      },
      {
        text: '',
        value: 'action'
      }
    ],
    staff: {},
    isEditAmbassador: false,
    data: [],
    loadingAmbassador: false,
    perPage: 10,
    apiData: {},
    lastPage: 1,
    search: ''
  }),
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin,
      siteSettings: (state) => state.admin.siteSettings
    })
  },
  created() {
    this.getAmbassadors(0)
    this.debounceSearch = _.debounce(this.searchData, 500)
  },
  watch: {
    search(newValue) {
      if (newValue === '') {
        this.getAmbassadors(0)
        return
      }
      this.debounceSearch()
    }
  },
  methods: {
    validate() {
      if (this.$refs.createStaffForm.validate()) {
        if (this.isEditAmbassador) {
          this.updateAmbassador()
        } else {
          this.createAmbassador()
        }
      }
    },
    updatePage(page) {
      if (this.lastPage - page > 2) {
        page = this.lastPage
      }
      this.lastPage = page
      this.getAmbassadors((page - 1) * this.perPage)
    },
    updateItemPerPage(count) {
      this.perPage = count
      this.getAmbassadors((this.lastPage - 1) * this.perPage)
    },
    onEditAmbassador(ambassador) {
      this.createStaff = ambassador
      this.isEditAmbassador = true
      this.dialogCreateStaff = true
    },
    async searchData() {
      this.loadingAmbassador = true
      const res = await userApi.users().getAmbassadors({
        limit: this.perPage,
        keyword: this.search,
        populate: ['referrals']
      })

      if (res.error) {
        this.alert({
          message: 'Unable to fetch ambassadors',
          error: true,
          time: 4000,
          status: true
        })
        this.loadingAmbassador = false
        return
      }

      this.data = res.data.data.data
      this.apiData = res.data.data.meta
      this.loadingAmbassador = false
    },

    async getAmbassadors(length) {
      this.loadingAmbassador = true
      const res = await userApi.users().getAmbassadors({
        limit: this.perPage,
        offset: length,
        populate: ['referrals']
      })

      if (res.error) {
        this.alert({
          message: 'Unable to fetch ambassadors',
          error: true,
          time: 4000,
          status: true
        })
        this.loadingAmbassador = false
        return
      }

      this.data = res.data.data.data
      this.apiData = res.data.data.meta
      this.loadingAmbassador = false
    },
    async createAmbassador() {
      this.loading = true
      const res = await userApi.users().createAmbassador(this.createStaff)
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        this.loading = false
        return
      }

      this.$store.dispatch('alert', {
        message: 'Ambassador created successfully',
        error: false,
        status: true
      })
      this.loading = false
      this.dialogCreateStaff = false
      this.createStaff = {}
      this.getAmbassadors(0)
    },
    async updateAmbassador() {
      this.loading = true
      const res = await userApi.users().updateAmbassador(this.createStaff)
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        this.loading = false
        return
      }

      this.$store.dispatch('alert', {
        message: 'Ambassador updated successfully',
        error: false,
        status: true
      })
      this.loading = false
      this.dialogCreateStaff = false
      this.createStaff = {}
      this.getAmbassadors()
    },
    async onDeleteAmbassador(ambassador) {
      const res = await userApi.users().deleteAmbassador(ambassador._id)
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        return
      }

      this.$store.dispatch('alert', {
        message: 'Ambassador deleted successfully',
        error: false,
        status: true
      })
      this.getAmbassadors()
    }
  }
}
</script>

<style></style>
