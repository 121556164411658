<template>
  <v-container>
    <div
      v-if="!checkIfPermission('view subscriber', admin.permissions)"
      class="w-100 h-100 d-flex justify-center align-center"
    >
      <h1 class="error--text">You don't have access to this page</h1>
    </div>
    <div v-else>
      <h1>All Subscribers</h1>
      <v-dialog max-width="600" v-model="sendEmailDialog">
        <v-card>
          <v-tabs fixed-tabs color="accent" v-model="emailTabs">
            <v-tab>Send Email to Subscribers</v-tab>
            <v-tab>Send Email to Users</v-tab>
          </v-tabs>
          <v-tabs-items v-model="emailTabs">
            <v-tab-item>
              <v-card flat class="pa-5">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="sub.subject"
                      label="Title"
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <quill-editor
                      v-model="sub.message"
                      ref="myQuillEditor"
                      :options="editorOption"
                    />
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text color="error" @click="sendEmailDialog = false"
                    >Close</v-btn
                  >
                  <v-btn
                    :loading="sendingSub"
                    color="accent"
                    @click="sendEmailToSubscribers"
                    >Send</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat class="pa-5">
                <v-card-text>
                  <p>
                    Include any of the follow code to generate user details
                    <br />
                    <span class="font-weight-medium">${user.fname}</span> -
                    User's First Name,
                    <span class="font-weight-medium">${user.lname}</span> -
                    User's Last Name,
                    <span class="font-weight-medium">${user.email}</span> -
                    User's Email
                  </p>
                </v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="users.subject"
                      label="Title"
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <quill-editor
                      v-model="users.message"
                      ref="myQuillEditor"
                      :options="editorOption"
                    />
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text color="error" @click="sendEmailDialog = false"
                    >Close</v-btn
                  >
                  <v-btn
                    :loading="sendingUser"
                    @click="sendEmailToUsers"
                    color="accent"
                    >Send</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-dialog>
      <v-row>
        <v-col>
          <v-card flat class="pa-4">
            <v-card-title class="d-flex align-baseline">
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search..."
                single-line
                hide-details
              ></v-text-field>
              <v-btn
                v-if="checkIfPermission('send newsletter', admin.permissions)"
                color="accent"
                depressed
                @click.stop="sendEmailDialog = true"
                class="ml-4 font-weight-bold"
                >Send Email to Subscribers</v-btn
              >
            </v-card-title>
            <v-data-table
              :loading="data.length === 0"
              :search="search"
              :items="data"
              :headers="headers"
            >
              <template v-slot:[`item.status`]="{ item }">
                <span
                  :class="`text-capitalize rounded py-2 px-3 white--text ${
                    item.status ? 'success' : 'error'
                  }`"
                  >{{ item.status ? 'Active' : 'Suspended' }}</span
                >
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-menu rounded="md" bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list
                    dense
                    v-if="
                      checkIfPermission('manage subscriber', admin.permissions)
                    "
                  >
                    <v-list-item
                      @click="actionOnSubscriber(item, true)"
                      v-if="!item.status"
                      ripple
                    >
                      <v-list-item-title>Activate</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="actionOnSubscriber(item, false)"
                      v-if="item.status"
                      ripple
                    >
                      <v-list-item-title>Suspend</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import userApi from '../../../api/admin/userManagement'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import { mapState } from 'vuex'

export default {
  components: {
    quillEditor
  },
  data: () => ({
    search: '',
    emailTabs: null,
    sendingSub: false,
    sendingUser: false,
    sendEmailDialog: false,
    sendingEmailToUser: false,
    sub: {},
    editorOption: {},
    users: {},
    headers: [
      {
        text: 'Email',
        value: 'email'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        text: '',
        value: 'action'
      }
    ],
    data: []
  }),
  created() {
    this.getSubscribers()
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill
    },
    ...mapState({
      admin: (state) => state.admin.admin
    })
  },
  methods: {
    async getSubscribers() {
      const res = await userApi.users().listSubscribers()
      this.data = res.data.data.data
    },
    async sendEmailToSubscribers() {
      this.sendingSub = true
      const res = await userApi.users().sendEmailToSubscribers({ ...this.sub })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        this.sendingSub = false
        return
      }
      this.sendingSub = false
      this.sendEmailDialog = false
      this.sub = {}
      this.$store.dispatch('alert', {
        message: 'Email sent successful',
        status: true
      })
    },
    async sendEmailToUsers() {
      this.sendingUser = true
      const res = await userApi.users().sendEmailToUsers({ ...this.users })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        this.sendingUser = false
        return
      }
      this.sendingUser = false
      this.sendEmailDialog = false
      this.users = {}
      this.$store.dispatch('alert', {
        message: 'Email sent successful',
        status: true
      })
    },
    async actionOnSubscriber(user, status) {
      const res = await userApi
        .users()
        .updateSubscriber({ id: user._id, status })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        return
      }
      this.getSubscribers()
      this.$store.dispatch('alert', {
        message: 'Status changed successful',
        status: true
      })
    }
  }
}
</script>

<style></style>
