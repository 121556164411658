<template>
  <v-container class="pb-10">
    <div
      v-if="!checkIfPermission('view user', admin.permissions)"
      class="w-100 h-100 d-flex justify-center align-center"
    >
      <h1 class="error--text">You don't have access to this page</h1>
    </div>
    <div v-else>
      <div class="mb-5 flex items-center justify-between">
        <h1>All Users</h1>
        <div
          v-if="checkIfPermission('export user', admin.permissions)"
          class="grid grid-cols-2 items-center gap-10"
        >
          <v-select
            v-model="selectedCountry"
            :loading="exportingData"
            :items="['Nigeria', 'Ghana', 'Kenya']"
            label="Country"
          />
          <v-btn color="primary" :loading="exportingData" @click="exportData">
            Export User
          </v-btn>
        </div>
      </div>
      <v-card :loading="loadingStats">
        <v-card-title>
          <h3 class="text-h5">User Statistics</h3>
        </v-card-title>
        <div class="p-2">
          <div
            v-if="info.totalUsers"
            class="grid grid-cols-1 md:grid-cols-3 gap-5"
          >
            <div class="bg-yellow-100 rounded-md px-4 py-6 w-full shadow">
              <p class="text-xs text-opacity-75 m-0 mb-0">Total Users</p>
              <p class="m-0 mb-0 text-2xl font-medium">
                {{ info.totalUsers }}
              </p>
            </div>
            <div class="bg-blue-100 rounded-md px-4 py-6 w-full shadow">
              <p class="text-xs text-opacity-75 m-0 mb-0">Total Active Users</p>
              <p class="m-0 mb-0 text-2xl font-medium">
                {{ info.totalActiveUsers }}
              </p>
            </div>
            <div class="bg-green-100 rounded-md px-4 py-6 w-full shadow">
              <p class="text-xs text-opacity-75 m-0 mb-0">
                Total Inactive Users
              </p>
              <p class="m-0 mb-0 text-2xl font-medium">
                {{ info.totalInactiveUsers }}
              </p>
            </div>
            <div class="bg-red-100 rounded-md px-4 py-6 w-full shadow">
              <p class="text-xs text-opacity-75 m-0 mb-0">
                Total Crypto Active Users
              </p>
              <p class="m-0 mb-0 text-2xl font-medium">
                {{ info.totalCryptoActiveUsers }}
              </p>
            </div>
            <div class="bg-purple-100 rounded-md px-4 py-6 w-full shadow">
              <p class="text-xs text-opacity-75 m-0 mb-0">
                Total Gift Card Active Users
              </p>
              <p class="m-0 mb-0 text-2xl font-medium">
                {{ info.totalGiftCardActiveUsers }}
              </p>
            </div>
            <div class="bg-yellow-100 rounded-md px-4 py-6 w-full shadow">
              <p class="text-xs text-opacity-75 m-0 mb-0">
                Total Purchase Users
              </p>
              <p class="m-0 mb-0 text-2xl font-medium">
                {{ info.totalPurchaseUsers }}
              </p>
            </div>
          </div>
        </div>
      </v-card>

      <v-row class="mt-5">
        <v-col class="" cols="12">
          <v-tabs color="accent" v-model="transactionTab">
            <v-tab>All User</v-tab>
            <v-tab>Active User</v-tab>
            <v-tab>Inactive User</v-tab>
            <v-tab>Suspended User</v-tab>
          </v-tabs>
          <v-tabs-items v-model="transactionTab">
            <!-- All -->
            <v-tab-item>
              <user-list-table userToGet="all" />
            </v-tab-item>
            <!-- Active -->
            <v-tab-item>
              <user-list-table userToGet="active" />
            </v-tab-item>

            <!-- Inactive -->
            <v-tab-item>
              <user-list-table userToGet="inactive" />
            </v-tab-item>

            <!-- Suspended -->
            <v-tab-item>
              <user-list-table userToGet="deactivated" />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import UserListTable from '../../../components/admin/userListTable.vue'
import { mapActions, mapState } from 'vuex'
import userManagement from '../../../api/admin/userManagement'
import systemData from '../../../api/systemData'

// import userManagement from '../../../api/admin/userManagement'

export default {
  components: { UserListTable },
  name: 'Users',
  data: () => ({
    transactionTab: null,
    userDetailsDialog: false,
    active: [],
    inactive: [],
    loadingActive: false,
    loadingInactive: false,
    selectedCountry: 'Nigeria',
    exportingData: false,
    loadingStats: false,
    info: {
      totalUsers: 0,
      totalActiveUsers: 0,
      totalInactiveUsers: 0,
      totalCryptoActiveUsers: 0,
      totalGiftCardActiveUsers: 0,
      totalPurchaseUsers: 0
    }
  }),
  created() {
    // this.getActiveUser()
    // this.getInactiveUser()
    // this.getUsersAll()
    this.getUserStats()
  },
  methods: {
    ...mapActions({
      alert: 'alert'
    }),
    async exportData() {
      this.exportingData = true

      const res = await userManagement.users().exportData(this.selectedCountry)
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.exportingData = false
        return
      }
      this.$store.dispatch('alert', {
        message: res.data.data,
        status: true
      })
      this.exportingData = false
    },
    async getUserStats() {
      this.loadingStats = true

      const res = await systemData.data().userStats()
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.loadingStats = false

        return
      }

      this.info = res.data.data
      this.loadingStats = false
    }
  },
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    })
  }
}
</script>

<style></style>
