// Admin Dashboard
const AdminDashboardMenu = [
  {
    title: 'Dashboard',
    href: '/yurd2m/yurd2m/dashboard',
    icon: 'mdi-view-dashboard'
  },
  {
    title: 'Transaction Calculator',
    href: '/yurd2m/yurd2m/transaction-calculator',
    icon: 'mdi-calculator-variant-outline'
  },
  {
    title: 'Giftcards',
    href: '/yurd2m/yurd2m/giftcard',
    icon: 'mdi-wallet-giftcard',
    children: [
      {
        title: 'Processed Giftcards',
        href: '/yurd2m/yurd2m/giftcard/processed'
      },
      { title: 'Pending Giftcards', href: '/yurd2m/yurd2m/giftcard/pending' },
      { title: 'Declined Giftcards', href: '/yurd2m/yurd2m/giftcard/declined' }
    ]
  },
  {
    title: 'Purchase',
    href: '/yurd2m/yurd2m/purchase',
    icon: 'mdi-cart-arrow-up',
    children: [
      {
        title: 'Processed Purchase',
        href: '/yurd2m/yurd2m/purchase/processed'
      },
      { title: 'Pending Purchase', href: '/yurd2m/yurd2m/purchase/pending' },
      { title: 'Declined Purchase', href: '/yurd2m/yurd2m/purchase/declined' }
    ]
  },
  {
    title: 'Sales',
    href: '/yurd2m/yurd2m/sales',
    icon: 'mdi-cart-arrow-down',
    children: [
      {
        title: 'Processed Sales',
        href: '/yurd2m/yurd2m/sales/processed'
      },
      { title: 'Pending Sales', href: '/yurd2m/yurd2m/sales/pending' },
      { title: 'Declined Sales', href: '/yurd2m/yurd2m/sales/declined' }
    ]
  },
  // {
  //   title: 'Airtime',
  //   href: '/yurd2m/yurd2m/airtime',
  //   icon: 'mdi-cards'
  // },
  {
    title: 'Users Management',
    href: '/yurd2m/yurd2m/users-management',
    icon: 'mdi-account-cog',
    children: [
      {
        title: 'Users',
        href: '/yurd2m/yurd2m/users-management/users'
      },
      {
        title: 'User Stats',
        href: '/yurd2m/yurd2m/users-management/user-stats-filter'
      },
      {
        title: 'Feedbacks',
        href: '/yurd2m/yurd2m/users-management/feedbacks'
      },
      {
        title: 'Staffs',
        href: '/yurd2m/yurd2m/users-management/staffs'
      },
      {
        title: 'Ambassador',
        href: '/yurd2m/yurd2m/users-management/ambassador'
      },
      {
        title: 'Funds Transfer',
        href: '/yurd2m/yurd2m/users-management/funds-transfer'
      },
      // {
      //   title: 'Admin Funds Transfer',
      //   href: '/yurd2m/yurd2m/users-management/yurd2m-funds-transfer'
      // },
      {
        title: 'User Referrals',
        href: '/yurd2m/yurd2m/users-management/referrals'
      },
      {
        title: 'Bonus Conversion',
        href: '/yurd2m/yurd2m/users-management/bonus-conversion'
      },
      {
        title: 'Token Conversion',
        href: '/yurd2m/yurd2m/users-management/token-conversion'
      },
      {
        title: 'Wallet',
        href: '/yurd2m/yurd2m/users-management/user-wallet'
      },
      {
        title: 'Cashback',
        href: '/yurd2m/yurd2m/users-management/user-cashback'
      },
      {
        title: 'Wallet History',
        href: '/yurd2m/yurd2m/users-management/user-wallet-history'
      },
      {
        title: 'Cashback History',
        href: '/yurd2m/yurd2m/users-management/cashback-wallet-history'
      },
      {
        title: 'Bill History',
        href: '/yurd2m/yurd2m/users-management/user-bill-history'
      }
    ]
  },
  {
    title: 'Wallet Funding Requests',
    href: '/yurd2m/yurd2m/wallet-funding',
    icon: 'mdi-wallet-plus'
  },
  {
    title: 'Withdrawals',
    href: '/yurd2m/yurd2m/withdrawals',
    icon: 'mdi-bank-transfer',
    children: [
      {
        title: 'Processed',
        href: '/yurd2m/yurd2m/withdrawals/processed'
      },
      {
        title: 'Pending',
        href: '/yurd2m/yurd2m/withdrawals/pending'
      },
      {
        title: 'Declined',
        href: '/yurd2m/yurd2m/withdrawals/declined'
      }
    ]
  },
  // {
  //   title: 'Message Center',
  //   href: '/yurd2m/yurd2m/messages',
  //   icon: 'mdi-message',
  //   children: [
  //     {
  //       title: "Customers' Request",
  //       href: '/yurd2m/yurd2m/messages/customers-request'
  //     },
  //     {
  //       title: "Customers' Testimonials",
  //       href: '/yurd2m/yurd2m/messages/customers-testimonials'
  //     },
  //     {
  //       title: 'Broadcast',
  //       href: '/yurd2m/yurd2m/messages/broadcast-message'
  //     },
  //     {
  //       title: 'All Subscribers',
  //       href: '/yurd2m/yurd2m/messages/all-subscribers'
  //     }
  //   ]
  // },
  {
    title: 'Settings',
    href: '/yurd2m/yurd2m/settings',
    icon: 'mdi-cogs',
    children: [
      {
        title: 'Giftcards',
        href: '/yurd2m/yurd2m/settings/giftcard'
      },
      {
        title: 'Airtime',
        href: '/yurd2m/yurd2m/settings/airtime'
      },
      {
        title: 'System Setttings',
        href: '/yurd2m/yurd2m/settings/system-settings'
      },
      {
        title: 'System Currencies',
        href: '/yurd2m/yurd2m/settings/system-currencies'
      },
      {
        title: 'Currencies Rate',
        href: '/yurd2m/yurd2m/settings/system-currencies-rate'
      },
      {
        title: 'Payment Gateways',
        href: '/yurd2m/yurd2m/settings/payment-gateway'
      },
      {
        title: 'Bank Account Settings',
        href: '/yurd2m/yurd2m/settings/bank-account'
      },
      {
        title: 'Withdrawal Methods',
        href: '/yurd2m/yurd2m/settings/withdrawal-methods'
      },
      {
        title: 'Trustee',
        href: '/yurd2m/yurd2m/settings/trustees'
      },
      {
        title: 'Rank & Rewards',
        href: '/yurd2m/yurd2m/settings/rank-rewards'
      }
    ]
  },
  {
    title: 'Promo Codes',
    href: '/yurd2m/yurd2m/promo-codes/promo-codes',
    icon: 'mdi-percent-outline',
    children: [
      {
        title: 'Promo Codes',
        href: '/yurd2m/yurd2m/promo-codes/promo-codes'
      },
      {
        title: 'Promo Code History',
        href: '/yurd2m/yurd2m/promo-codes/promo-code-history'
      }
    ]
  },
  {
    title: "Trader's Post",
    href: '/yurd2m/yurd2m/traders-post',
    icon: 'mdi-message-text-outline'
  },
  {
    title: 'Leaderboard',
    href: '/yurd2m/yurd2m/leaderboard',
    icon: 'mdi-view-dashboard'
  }
]

export { AdminDashboardMenu }
