<template>
  <v-container>
    <v-overlay opacity="0.8" :value="loading">
      <v-progress-circular size="50" indeterminate></v-progress-circular>
    </v-overlay>

    <div class="d-flex justify-space-between">
      <h1>View Feedback</h1>
      <!--      <v-btn @click="$router.go(-1)"> Back</v-btn>-->
    </div>

    <v-card class="mt-5" flat>
      <v-container>
        <div class="flex justify-between">
          <div class="text-subtitle-2">
            <p class="mb-0">
              {{
                new Date(message.created_at || message.createdAt).toDateString()
              }}
            </p>
            <p>
              {{
                new Date(message.created_at || message.createdAt).toTimeString()
              }}
            </p>
          </div>
        </div>
        <v-divider></v-divider>
        <v-card-text>
          <p>
            {{ message.details }}
          </p>
        </v-card-text>
        <v-card-title>Reply</v-card-title>

        <v-textarea
          filled
          auto-grow
          v-model="reply.details"
          label="Your reply"
        ></v-textarea>
        <!--        <v-file-input v-model="reply.image" label="Add Image"></v-file-input>-->
        <v-card-actions
          v-if="checkIfPermission('send message', admin.permissions)"
        >
          <v-spacer></v-spacer>
          <v-btn
            :loading="sendingReply"
            color="accent"
            @click="replyToMessage"
            depressed
            >Send Message
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import userApi from '../../../api/admin/userManagement'

export default {
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    })
  },
  data() {
    return {
      messageId: '',
      reply: {},
      message: {},
      sendingReply: false,
      loading: false
    }
  },
  methods: {
    async replyToMessage() {
      this.sendingReply = true
      const res = await userApi.users().sendMessageToUser({
        userId: this.message.user,
        messageId: this.message._id,
        responded: true,
        ...this.reply
      })
      if (res.error) {
        this.$store.dispatch({
          messages:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        this.sendingReply = false
        return
      }
      this.$store.dispatch('alert', { message: 'Message sent', status: true })
      this.reply = {}
      this.sendingReply = false
      this.viewMessageDialog = false
      this.$router.push('/yurd2m/yurd2m/messages/customers-request')
    },
    async getMessage() {
      this.loading = true
      const res = await userApi
        .users()
        .markMessageRead({ messageId: this.messageId })

      this.message = res.data.data
      this.loading = false
    }
  },
  created() {
    this.messageId = this.$route.params.id
    this.getMessage()
  }
}
</script>

<style></style>
