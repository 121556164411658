import axios from 'axios'
import Store from '../../store'
import { errorHandler, successHandler } from '../helpers/handler'
import { BASEURL } from '../const'

const URL = `${BASEURL}/messages`

export default {
  data() {
    return {
      createMessage: async ({ message, image, title }) => {
        const endpoint = `${URL}`
        const formData = new FormData()
        formData.append('image', image)
        formData.append('details', message)
        formData.append('title', title)
        try {
          const res = await axios.post(endpoint, formData, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers,
              'content-type': 'multipart/form-data'
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      markMessageRead: async ({ messageId }) => {
        const endpoint = `${URL}/${messageId}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getMessages: async (type) => {
        const endpoint = `${URL}/${type}?user=${Store.state.user.user._id}&admin=false`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
