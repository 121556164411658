// Message
import MessageCenter from '@/views/admin/MessageCenter.vue'
import AllSubscribers from '@/views/admin/messageCenter/AllSubscribers.vue'
import CustomersTestimonials from '@/views/admin/messageCenter/CustomerTestimonials.vue'
import CustomersMessage from '@/views/admin/messageCenter/CustomerMessage.vue'
import BroadcastMessage from '@/views/admin/messageCenter/Broadcast.vue'
import ViewMessage from '@/views/admin/messageCenter/viewMessage.vue'

const messageRoutes = {
  path: '/yurd2m/yurd2m/messages',
  component: MessageCenter,
  redirect: '/yurd2m/yurd2m/messages/message',
  name: 'MessageCenter',
  meta: {
    title: 'Message Center'
  },
  children: [
    {
      path: '/yurd2m/yurd2m/messages/all-subscribers',
      component: AllSubscribers,
      name: 'All Subscribers',
      meta: {
        title: 'All Subscribers'
      }
    },
    {
      path: '/yurd2m/yurd2m/messages/view/:id',
      component: ViewMessage,
      name: 'ViewMessage',
      meta: {
        title: 'View Message'
      }
    },
    {
      path: '/yurd2m/yurd2m/messages/customers-testimonials',
      component: CustomersTestimonials,
      name: 'CustomersSubscribers',
      meta: {
        title: 'Customers Subscribers'
      }
    },
    {
      path: '/yurd2m/yurd2m/messages/customers-request',
      component: CustomersMessage,
      name: 'CustomersMessage',
      meta: {
        title: 'Customers Message'
      }
    },
    {
      path: '/yurd2m/yurd2m/messages/broadcast-message',
      component: BroadcastMessage,
      name: 'BroadcastMessage',
      meta: {
        title: 'Broadcast Message'
      }
    }
  ]
}

export default messageRoutes
