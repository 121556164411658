// Purchase
import Purchase from '@/views/admin/Purchase.vue'
import PDeclined from '@/views/admin/purchase/Declined.vue'
import PAccepted from '@/views/admin/purchase/Accepted.vue'
import PPending from '@/views/admin/purchase/Pending.vue'

const purchaseRoutes = {
  path: '/yurd2m/yurd2m/purchase',
  component: Purchase,
  redirect: '/yurd2m/yurd2m/purchase/processed',
  name: 'Purchase',
  meta: {
    title: 'Sales'
  },
  children: [
    {
      path: '/yurd2m/yurd2m/purchase/declined',
      component: PDeclined,
      name: 'DeclinedPurchase',
      meta: {
        title: 'Declined Purchase'
      }
    },
    {
      path: '/yurd2m/yurd2m/purchase/processed',
      component: PAccepted,
      name: 'AcceptedPurchase',
      meta: {
        title: 'Accepted Purchase'
      }
    },
    {
      path: '/yurd2m/yurd2m/purchase/pending',
      component: PPending,
      name: 'PendingPurchase',
      meta: {
        title: 'Pending Purchase'
      }
    }
  ]
}

export default purchaseRoutes
