import Store from '../../store'
import { errorHandler, successHandler } from '../helpers/handler'

import adminHttp from './http'
const axios = adminHttp

const URL = `/transfers`

export default {
  data() {
    return {
      getTransfers: async ({ ...params }) => {
        const endpoint = `${URL}`
        try {
          const res = await axios.get(endpoint, {
            params,
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getTransfer: async (transferId) => {
        const endpoint = `${URL}/${transferId}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      updateTransfer: async ({ transferId, status, details }) => {
        const endpoint = `${URL}/${transferId}`
        const data = { status, remarks: details }
        try {
          const res = await axios.put(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      transferToAUser: async ({ amount, userUsername }) => {
        const endpoint = `fund-user`
        const data = { amount, send_details: userUsername }
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      creditUser: async ({ amount, userId, reason }) => {
        const endpoint = `credit-user`
        const data = { amount, userId, reason }
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      debitUser: async ({ amount, userId, reason }) => {
        const endpoint = `debit-user`
        const data = { amount, userId, reason }
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      creditCashbackPoints: async ({ amount, userId }) => {
        const endpoint = `credit-cashback-points`
        const data = { amount, userId }
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      debitCashbackPoints: async ({ amount, userId }) => {
        const endpoint = `deduct-cashback-points`
        const data = { amount, userId }
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
