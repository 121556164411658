import User from '../layouts/User.vue'

// User
// import Dashboard from '../views/user/Dashboard.vue'
// import Transaction from '../views/user/Transactions.vue'
// import Rates from '../views/user/Rates.vue'
import Products from '../views/user/Products.vue'
import Wallet from '../views/user/Wallet.vue'
import Setting from '../views/user/Setting.vue'
// import Message from '../views/user/Message.vue'

// Message
// import CreateMessage from '../views/user/message/CreateMessage.vue'
// import CreateTestimonial from '../views/user/message/CreateTestimonial.vue'

// Products
// import AllProducts from '../views/user/products/allProducts.vue'
// import Airtime from '../views/user/products/airtime.vue'
// import Cryto from '../views/user/products/crypto.vue'
// import Giftcard from '../views/user/products/giftcard.vue'
// import sellGifcard from '../views/user/products/giftcards/sellGiftcard.vue'
// import TradeCryto from '../views/user/products/crypto/trade.vue'

// Wallets
// import WalletMain from '../views/user/wallet/wallet.vue'
// import WalletDeposit from '../views/user/wallet/deposit.vue'
// import WalletTransfer from '../views/user/wallet/transfer.vue'
// import WalletWithdraw from '../views/user/wallet/withdraw.vue'

const userRoutes = {
  path: '/user',
  redirect: '/user/dashboard',
  component: User,
  children: [
    {
      path: '/user/dashboard',
      component: () => import('../views/user/Dashboard.vue'),
      name: 'Dashboard',
      meta: {
        title: 'Dashboard'
      }
    },
    {
      path: '/user/transactions',
      component: () => import('../views/user/Transactions.vue'),
      name: 'Transactions',
      meta: {
        title: 'Transactions'
      }
    },
    {
      path: '/user/referral-system',
      component: () => import('../views/user/ReferralSystem.vue'),
      name: 'ReferralSystem',
      meta: {
        title: 'Referral System'
      }
    },
    {
      path: '/user/leaderboard',
      component: () => import('../views/user/Leaderboards.vue'),
      name: 'Leaderboard',
      meta: {
        title: 'Leaderboard'
      }
    },
    {
      path: '/user/reward',
      component: () => import('../views/user/Reward.vue'),
      name: 'Rewards',
      meta: {
        title: 'Rewards'
      }
    },
    {
      path: '/user/wallet',
      component: Wallet,
      redirect: '/user/wallet/main',
      name: 'Wallet',
      meta: {
        title: 'Wallet'
      },
      children: [
        {
          path: '/user/wallet/main',
          component: () => import('../views/user/wallet/wallet.vue'),
          name: 'WalletMain',
          meta: {
            title: 'Wallet'
          }
        },
        {
          path: '/user/wallet/fund',
          component: () => import('../views/user/wallet/fund.vue'),
          name: 'FundWallet',
          meta: {
            title: 'Wallet Fund'
          }
        },
        {
          path: '/user/wallet/transfer',
          component: () => import('../views/user/wallet/transfer.vue'),
          name: 'WalletTransfer',
          meta: {
            title: 'Wallet Transfer'
          }
        },
        {
          path: '/user/wallet/withdraw',
          component: () => import('../views/user/wallet/withdraw.vue'),
          name: 'WalletWithdraw',
          meta: {
            title: 'Wallet Withdraw'
          }
        }
      ]
    },
    {
      path: '/user/rates',
      component: () => import('../views/user/Rates.vue'),
      name: 'Rates',
      meta: {
        title: 'Rates'
      }
    },
    {
      path: '/user/download',
      component: () => import('../views/user/Download.vue'),
      name: 'Download',
      meta: {
        title: 'Download'
      }
    },
    {
      path: '/user/promo-codes',
      component: () => import('../views/user/PromoCode.vue'),
      name: 'PromoCode',
      meta: {
        title: 'Use our Promo Code'
      }
    },
    {
      path: '/user/promo',
      component: () => import('../views/user/LeaderboardInfo.vue'),
      name: 'Promo',
      meta: {
        title: 'Promo'
      }
    },
    {
      path: '/user/level',
      component: () => import('../views/user/Level.vue'),
      name: 'Levels',
      meta: {
        title: 'Levels'
      }
    },
    {
      path: '/user/products',
      component: Products,
      redirect: '/user/products/all',
      name: 'Product',
      children: [
        {
          path: '/user/products/all',
          component: () => import('../views/user/products/allProducts.vue'),
          name: 'Products',
          meta: {
            title: 'All Products'
          }
        },
        {
          path: '/user/products/not-available/:service',
          component: () => import('../views/user/products/not-available.vue'),
          name: 'NotAvailable',
          meta: {
            title: 'Not available'
          }
        },
        {
          path: '/user/products/airtime',
          component: () => import('../views/user/products/airtime.vue'),
          name: 'Airtime',
          meta: {
            title: 'Airtime'
          }
        },
        {
          path: '/user/products/crypto',
          component: () => import('../views/user/products/crypto.vue'),
          name: 'Cryto-currency',
          meta: {
            title: 'Cryto'
          }
        },
        {
          path: '/user/products/giftcards',
          component: () => import('../views/user/products/giftcard.vue'),
          name: 'Giftcard',
          meta: {
            title: 'Giftcard'
          }
        },
        {
          path: '/user/products/giftcards/sell/:id',
          component: () =>
            import('../views/user/products/giftcards/sellGiftcard.vue'),
          name: 'SellGiftcard',
          meta: {
            title: 'Sell Giftcard'
          }
        },
        {
          path: '/user/products/crypto/trade/:id',
          component: () => import('../views/user/products/crypto/trade.vue'),
          name: 'TradeCryto',
          meta: {
            title: 'Trade Cryto'
          }
        },
        {
          path: '/user/products/crypto/auto-trade/:id',
          component: () =>
            import('../views/user/products/crypto/auto-trade.vue'),
          name: 'AutoTradeCryto',
          meta: {
            title: 'Automatic Sell Cryto'
          }
        },
        {
          path: '/user/products/bill-payment',
          component: () => import('../views/user/products/bill.vue'),
          name: 'BillPayment',
          meta: {
            title: 'Bill Payment'
          }
        },
        {
          path: '/user/products/bills/:name',
          component: () => import('../views/user/products/bills/name.vue'),
          name: 'BillPaymentType',
          meta: {
            title: 'Bill Payment'
          }
        },
        {
          path: '/user/products/bills/:name/:service',
          component: () =>
            import('../views/user/products/bills/name-provider.vue'),
          name: 'BillPaymentTypeService',
          meta: {
            title: 'Bill Payment Service'
          }
        }
      ]
    },
    // {
    //   path: '/user/messages',
    //   component: Message,
    //   name: 'Message',
    //   redirect: '/user/messages/create-message',
    //   meta: {
    //     title: 'Message'
    //   },
    //   children: [
    //     {
    //       path: '/user/messages/create-message',
    //       component: () => import('../views/user/message/CreateMessage.vue'),
    //       name: 'CreateMessage',
    //       meta: {
    //         title: 'Create Message'
    //       }
    //     },
    //     {
    //       path: '/user/messages/create-testimonial',
    //       component: () =>
    //         import('../views/user/message/CreateTestimonial.vue'),
    //       name: 'CreateTestimonial',
    //       meta: {
    //         title: 'Create Testimonial'
    //       }
    //     }
    //   ]
    // },

    {
      path: '/user/settings',
      component: Setting,
      name: 'ContactSupport',
      meta: {
        title: 'Contact Support'
      }
    },
    {
      path: '/user/contact-support',
      component: () => import('../views/user/ContactSupport.vue'),
      name: 'Settings',
      meta: {
        title: 'Settings'
      }
    }
  ]
}

export default userRoutes
