<template>
  <v-container>
    <div v-if="checkIfPermission('view rank', admin.permissions)">
      <div>
        <h1 class="mb-5">Rank and Rewards</h1>

        <v-dialog
          v-model="createDialog"
          max-width="600"
          @click:outside="
            () => {
              handleClickOutside()
            }
          "
        >
          <v-card>
            <v-toolbar flat>
              <v-card-title
                >{{ isEditing ? 'Edit' : 'Create' }} Rank
              </v-card-title>
              <v-spacer></v-spacer>
            </v-toolbar>

            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="newRank.title"
                    label="Rank title"
                    filled
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="newRank.reward"
                    label="Rank Reward"
                    type="number"
                    filled
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="newRank.level"
                    label="Rank Level"
                    type="number"
                    filled
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="newRank.rewardType"
                    label="Rank Level"
                    type="number"
                    :items="[
                      {
                        text: 'Percentage',
                        value: 'percentage'
                      },
                      {
                        text: 'Fixed',
                        value: 'fixed'
                      }
                    ]"
                    filled
                  ></v-select>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    v-model="newRank.rankValues.NGN.lowerLimit"
                    label="NGN Lower Limit"
                    type="number"
                    filled
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="newRank.rankValues.NGN.upperLimit"
                    label="NGN Upper Limit"
                    type="number"
                    filled
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    v-model="newRank.rankValues.GHC.lowerLimit"
                    label="GHC Lower Limit"
                    type="number"
                    filled
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="newRank.rankValues.GHC.upperLimit"
                    label="GHC Upper Limit"
                    type="number"
                    filled
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    v-model="newRank.rankValues.KSH.lowerLimit"
                    label="KSH Lower Limit"
                    type="number"
                    filled
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="newRank.rankValues.KSH.upperLimit"
                    label="KSH Upper Limit"
                    type="number"
                    filled
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-file-input
                    label="Rank Badge"
                    v-model="newRank.image"
                    filled
                    prepend-icon="mdi-camera"
                  >
                  </v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    label="Rank Description"
                    v-model="newRank.description"
                    filled
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer> </v-spacer>
                <v-btn
                  depressed
                  color="accent"
                  @click="
                    () => {
                      isEditing ? updateRank() : createRank()
                    }
                  "
                  :loading="creatingRank"
                  >{{ isEditing ? 'Save' : 'Create' }}</v-btn
                >
              </v-card-actions>
            </v-container>
          </v-card>
        </v-dialog>

        <v-card flat>
          <v-container>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-btn
                v-if="checkIfPermission('create rank', admin.permissions)"
                @click="openCreateRankDialog"
                color="accent"
                depressed
                class="mr-3"
              >
                <v-icon left>mdi-plus</v-icon>
                Create Rank</v-btn
              >
            </v-toolbar>
            <v-tabs v-model="activeTab">
              <v-tab>Ranks</v-tab>
              <v-tab>Users in Ranks</v-tab>
              <v-tab>Manage Rank Movement</v-tab>
              <v-tab>Rank & Reward History</v-tab>
            </v-tabs>
            <v-tabs-items v-model="activeTab">
              <v-tab-item>
                <v-card-text>
                  <v-data-table
                    :footer-props="{
                      itemsPerPageOptions: [5, 10, 15, 25, 50]
                    }"
                    :loading="ranks.length === 0"
                    :headers="headers"
                    :items="ranks"
                  >
                    <template v-slot:[`item.image`]="{ item }">
                      <v-img
                        :src="item.badge"
                        max-width="50px"
                        max-height="50px"
                      />
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                      <v-menu
                        v-if="
                          checkIfPermission('update rank', admin.permissions)
                        "
                        bottom
                        left
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="onEditRank(item)" ripple>
                            <v-list-item-title>Edit</v-list-item-title>
                          </v-list-item>

                          <v-list-item
                            @click="deleteRank(item._id)"
                            color="red"
                            :loading="item._id === deletingRank"
                            ripple
                          >
                            <v-list-item-title class="text--error"
                              >Delete</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-tab-item>
              <v-tab-item>
                <!-- <v-card-text>
                <v-spacer>
                  <v-select
                    :items="ranksListFilter"
                    item-text="text"
                    item-value="value"
                    v-model="selectedRankFilter"
                  />
                </v-spacer>
              </v-card-text> -->
                <v-card-text>
                  <v-data-table
                    :loading="loadingUsersInRank"
                    :footer-props="{
                      itemsPerPageOptions: [5, 10, 15, 25, 50]
                    }"
                    :headers="userRankHeaders"
                    :items="ranksUser"
                    @update:items-per-page="updateItemPerPage"
                    @update:page="updatePage"
                    :server-items-length="apiData.total"
                  >
                    <template v-slot:[`item.user`]="{ item }">
                      <router-link
                        :to="{
                          name: 'ViewUser',
                          params: { id: item.user._id }
                        }"
                        class="font-weight-medium text-capitalize d-block"
                        >{{
                          `${item.user.fname} ${item.user.lname}`
                        }}</router-link
                      >
                      <small
                        class="font-weight-regular grey--text text--darken-2 d-block"
                        >{{ item.user.username }}</small
                      >
                    </template>
                    <template v-slot:[`item.rank`]="{ item }">
                      <span>{{ item.rank ? item.rank.title : '' }}</span>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-tab-item>
              <v-tab-item>
                <v-card-text>
                  <v-data-table
                    :loading="loadingRankMovement"
                    :footer-props="{
                      itemsPerPageOptions: [5, 10, 15, 25, 50]
                    }"
                    :headers="rankMovementHeaders"
                    :items="ranksMovement"
                    @update:items-per-page="updateItemPerPageMovement"
                    @update:page="updatePageMovement"
                    :server-items-length="ranksMovementApiData.total"
                  >
                    <template v-slot:[`item.user`]="{ item }">
                      <router-link
                        :to="{
                          name: 'ViewUser',
                          params: { id: item.user._id }
                        }"
                        class="font-weight-medium text-capitalize d-block"
                        >{{
                          `${item.user.fname} ${item.user.lname}`
                        }}</router-link
                      >
                      <small
                        class="font-weight-regular grey--text text--darken-2 d-block"
                        >{{ item.user.username }}</small
                      >
                    </template>
                    <template v-slot:[`item.oldRank`]="{ item }">
                      <span>{{
                        item.oldRank ? item.oldRank.title : 'No rank available'
                      }}</span>
                    </template>
                    <template v-slot:[`item.newRank`]="{ item }">
                      <span>{{
                        item.newRank ? item.newRank.title : 'No rank available'
                      }}</span>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                      <v-btn
                        @click="
                          () => {
                            confirmRewardApproval = true
                            actionOnApprove = () => {
                              updateRankReward(item._id)
                            }
                          }
                        "
                        color="accent"
                      >
                        Click to Approve
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-tab-item>
              <v-tab-item>
                <v-card-text>
                  <v-data-table
                    :loading="loadingRankMovementFull"
                    :footer-props="{
                      itemsPerPageOptions: [5, 10, 15, 25, 50]
                    }"
                    :headers="rankMovementHeadersFull"
                    :items="ranksMovementFull"
                    @update:items-per-page="updateItemPerPageFull"
                    @update:page="updatePageFull"
                    :server-items-length="apiDataFull.total"
                  >
                    <template v-slot:[`item.user`]="{ item }">
                      <router-link
                        :to="{
                          name: 'ViewUser',
                          params: { id: item.user._id }
                        }"
                        class="font-weight-medium text-capitalize d-block"
                        >{{
                          `${item.user.fname} ${item.user.lname}`
                        }}</router-link
                      >
                      <small
                        class="font-weight-regular grey--text text--darken-2 d-block"
                        >{{ item.user.username }}</small
                      >
                    </template>
                    <template v-slot:[`item.oldRank`]="{ item }">
                      <span>{{
                        item.oldRank ? item.oldRank.title : 'Rank not available'
                      }}</span>
                    </template>
                    <template v-slot:[`item.newRank`]="{ item }">
                      <span>{{
                        item.newRank ? item.newRank.title : 'Rank no available'
                      }}</span>
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                      <v-card
                        :color="
                          item.status == 'approved'
                            ? 'success'
                            : item.status == 'pending'
                            ? 'warning'
                            : 'error'
                        "
                        flat
                        rounded="md"
                        outlined
                        class="text-capitalize text-center white--text pa-1"
                      >
                        {{ item.status }}
                      </v-card>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                      <v-btn
                        v-if="
                          checkIfPermission(
                            'update rank reward',
                            admin.permissions
                          )
                        "
                        @click="
                          () => {
                            confirmRewardApproval = true
                            actionOnApprove = () => {
                              updateRankReward(item._id)
                            }
                          }
                        "
                        color="success"
                      >
                        Approve
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-card>
      </div>

      <v-dialog v-model="confirmRewardApproval" max-width="700">
        <v-card flat color="warning">
          <v-container>
            <v-card-title class="white--text text-capitalize">
              Confirm Reward
            </v-card-title>
            <v-card-text>
              <p class="body-2 white--text font-weight-medium">
                Confirm this level promotion and reward user?
              </p>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                :disabled="updatingRewards"
                @click="
                  () => {
                    confirmRewardApproval = false
                    actionOnApprove = null
                  }
                "
                >NO</v-btn
              >
              <v-btn
                depressed
                :loading="updatingRewards"
                @click="actionOnApprove ? actionOnApprove() : () => {}"
                >Yes</v-btn
              >
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
    </div>
    <div v-else>
      <h1 class="error--text">You don't have access to this page</h1>
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import rank from '../../../api/admin/rank'

const defaultNewRank = {
  title: '',
  reward: '',
  level: '',
  image: null,
  description: '',
  rewardType: 'percentage',
  values: [
    { currency: 'NGN', lowerLimit: 0, upperLimit: 0 },
    { currency: 'GHC', lowerLimit: 0, upperLimit: 0 },
    { currency: 'KSH', lowerLimit: 0, upperLimit: 0 }
  ],
  rankValues: {
    NGN: {
      lowerLimit: 0,
      upperLimit: 0
    },
    GHC: {
      lowerLimit: 0,
      upperLimit: 0
    },
    KSH: {
      lowerLimit: 0,
      upperLimit: 0
    }
  }
}

export default {
  data: () => ({
    headers: [
      {
        value: 'title',
        text: 'Rank Title'
      },
      {
        value: 'reward',
        text: 'Reward'
      },
      {
        value: 'level',
        text: 'Level'
      },
      {
        value: 'rewardType',
        text: 'Reward Type'
      },
      {
        value: 'image',
        text: 'Badge'
      },
      {
        value: 'action',
        text: '',
        sortable: false
      }
    ],
    userRankHeaders: [
      {
        value: 'user',
        text: 'User'
      },
      {
        value: 'rank',
        text: 'Rank'
      }
    ],
    rankMovementHeaders: [
      {
        value: 'user',
        text: 'User'
      },
      {
        value: 'reward',
        text: 'Reward'
      },
      {
        value: 'oldRank',
        text: 'Previous Rank'
      },
      {
        value: 'newRank',
        text: 'New Rank'
      },
      {
        value: 'action',
        text: '',
        sortable: false
      }
    ],
    rankMovementHeadersFull: [
      {
        value: 'user',
        text: 'User'
      },
      {
        value: 'reward',
        text: 'Reward'
      },
      {
        value: 'oldRank',
        text: 'Previous Rank'
      },
      {
        value: 'newRank',
        text: 'New Rank'
      },
      {
        value: 'status',
        text: 'Status'
      }
    ],
    updatingRewards: false,
    actionOnApprove: null,
    confirmRewardApproval: false,
    ranks: [],
    ranksMovement: [],
    ranksUser: [],
    ranksListFilter: [],
    activeTab: null,
    createDialog: false,
    creatingRank: false,
    deletingRank: null,
    loadingUsersInRank: false,
    loadingRankMovement: false,
    isEditing: false,
    selectedRankFilter: null,
    loadingRankMovementFull: false,
    ranksMovementFull: [],
    ranksMovementApiData: {},
    newRank: defaultNewRank,
    apiDataMovement: {},
    perPage: 10,
    perPageFull: 10,
    apiData: {},
    apiDataFull: {},
    lastPage: 1,
    lastPageFull: 1
  }),
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    })
  },
  watch: {
    selectedRankFilter: function (val) {
      this.getUsersInRank(val)
    }
  },
  created() {
    this.getRanks()
    this.getUsersInRank()
    this.getRankRewardFull()
    this.getRankReward()
  },
  methods: {
    handleClickOutside() {
      this.createDialog = false
      this.isEditing = false
      this.newRank = defaultNewRank
    },
    openCreateRankDialog() {
      this.createDialog = true
      this.isEditing = false
      this.newRank = defaultNewRank
    },
    updatePage(page) {
      if (this.lastPage - page > 2) {
        page = this.lastPage
      }
      this.lastPage = page
      this.getUsersInRank((page - 1) * this.perPage)
    },
    updateItemPerPage(count) {
      this.perPage = count
      this.getUsersInRank((this.lastPage - 1) * this.perPage)
    },
    updatePageFull(page) {
      if (this.lastPageFull - page > 2) {
        page = this.lastPageFull
      }
      this.lastPageFull = page
      this.getRankRewardFull((page - 1) * this.perPage)
    },
    updateItemPerPageFull(count) {
      this.perPageFull = count
      this.getRankRewardFull((this.lastPageFull - 1) * this.perPageFull)
    },
    updatePageMovement(page) {
      if (this.lastPageFull - page > 2) {
        page = this.lastPageFull
      }
      this.lastPageFull = page
      this.getRankReward((page - 1) * this.perPage)
    },
    updateItemPerPageMovement(count) {
      this.perPageFull = count
      this.getRankReward((this.lastPageFull - 1) * this.perPageFull)
    },
    onEditRank(rank) {
      this.createDialog = true
      this.newRank = rank
      this.isEditing = true
    },
    async getRanks() {
      const res = await rank.ranksApi().getRanks({
        limit: 0,
        orderBy: 'level',
        order: 'asc'
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        return
      }
      this.ranks = res.data.data.data.map((rank) => {
        const ngnValues = rank.values.find((v) => v.currency === 'NGN')
        const ghcValues = rank.values.find((v) => v.currency === 'GHC')
        const kshValues = rank.values.find((v) => v.currency === 'KSH')

        return {
          ...rank,
          badge: rank.image,
          rankValues: {
            NGN: {
              lowerLimit: ngnValues.lowerLimit,
              upperLimit: ngnValues.upperLimit
            },
            GHC: {
              lowerLimit: ghcValues.lowerLimit,
              upperLimit: ghcValues.upperLimit
            },
            KSH: {
              lowerLimit: kshValues.lowerLimit,
              upperLimit: kshValues.upperLimit
            }
          }
        }
      })
      this.ranksListFilter = res.data.data.data.map((rank) => {
        return {
          text: rank.title,
          value: rank._id
        }
      })
      this.ranksListFilter.unshift({
        text: 'All',
        value: null
      })
    },
    async getRankReward(length) {
      this.loadingRankMovement = true

      const res = await rank.ranksApi().getRankReward({
        limit: this.perPage,
        populate: ['newRank', 'oldRank', 'user'],
        status: 'pending',
        offset: length,
        order: 'desc'
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.loadingRankMovement = false
        return
      }

      this.ranksMovement = res.data.data.data
      this.ranksMovementApiData = res.data.data.meta
      this.loadingRankMovement = false
    },
    async getRankRewardFull(length) {
      this.loadingRankMovementFull = true

      const res = await rank.ranksApi().getRankReward({
        limit: this.perPageFull,
        populate: ['newRank', 'oldRank', 'user'],
        offset: length,
        order: 'desc'
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.loadingRankMovementFull = false
        return
      }

      this.apiDataFull = res.data.data.meta
      this.ranksMovementFull = res.data.data.data
      this.loadingRankMovementFull = false
    },
    async updateRankReward(id, status = 'approved') {
      this.updatingRewards = true
      const res = await rank.ranksApi().updateRankReward({
        id,
        status
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.updatingRewards = false
        return
      }

      this.confirmRewardApproval = false
      this.updatingRewards = false
      this.$store.dispatch('alert', {
        message: 'Rank reward updated successfully',
        status: true,
        error: false
      })
      this.getRankRewardFull(0)
    },
    async createRank() {
      this.creatingRank = true

      const formData = new FormData()
      formData.append('title', this.newRank.title)
      formData.append('reward', this.newRank.reward)
      formData.append('level', this.newRank.level)
      formData.append('image', this.newRank.image)
      formData.append('description', this.newRank.description)
      formData.append('rewardType', this.newRank.rewardType)

      Object.keys(this.newRank.rankValues).forEach((key, index) => {
        const v = this.newRank.rankValues[key]

        formData.append(`values[${index}][currency]`, key)
        formData.append(`values[${index}][upperLimit]`, v.upperLimit)
        formData.append(`values[${index}][lowerLimit]`, v.lowerLimit)
      })

      const res = await rank.ranksApi().createRank(formData)
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.creatingRank = false
        return
      }

      this.getRanks()
      this.createDialog = false
      this.creatingRank = false
      this.newRank = defaultNewRank
    },
    async deleteRank(id) {
      this.deletingRank = id
      const res = await rank.ranksApi().deleteRank(id)
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.deletingRank = null
        return
      }

      this.getRanks()
      this.deletingRank = null
    },
    async updateRank() {
      this.creatingRank = true

      const formData = new FormData()
      formData.append('title', this.newRank.title)
      formData.append('reward', this.newRank.reward)
      formData.append('level', this.newRank.level)
      formData.append('description', this.newRank.description)
      formData.append('rewardType', this.newRank.rewardType)

      if (this.newRank.image) {
        formData.append('image', this.newRank.image)
      }

      Object.keys(this.newRank.rankValues).forEach((key, index) => {
        const v = this.newRank.rankValues[key]

        formData.append(`values[${index}][currency]`, key)
        formData.append(`values[${index}][upperLimit]`, v.upperLimit)
        formData.append(`values[${index}][lowerLimit]`, v.lowerLimit)
      })

      const res = await rank.ranksApi().updateRank(this.newRank._id, formData)
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.creatingRank = false
        return
      }

      this.getRanks()
      this.createDialog = false
      this.creatingRank = false
      this.newRank = defaultNewRank
    },
    async getUsersInRank(length) {
      this.loadingUsersInRank = true
      const res = await rank.ranksApi().getUsersByRank({
        limit: this.perPage,
        rank: this.selectedRankFilter,
        offset: length,
        order: 'desc'
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.loadingUsersInRank = false
        return
      }
      this.apiData = res.data.data.meta
      this.ranksUser = res.data.data.data
      this.loadingUsersInRank = false
    }
  }
}
</script>

<style></style>
