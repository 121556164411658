<template>
  <v-container>
    <div
      v-if="!checkIfPermission('view subscriber', admin.permissions)"
      class="w-100 h-100 d-flex justify-center align-center"
    >
      <h1 class="error--text">You don't have access to this page</h1>
    </div>
    <div v-else>
      <h1 class="mb-4">Broadcast Message</h1>
      <v-card flat color="#fff" class="pa-4">
        <v-row>
          <v-col cols="12">
            <v-text-field
              :disabled="isSending"
              v-model="data.title"
              label="Title"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              :disabled="isSending"
              v-model="data.message"
              label="Message"
            ></v-textarea>
          </v-col>
          <v-col>
            <v-btn
              @click="sendNotification"
              :loading="isSending"
              depressed
              color="primary"
              >Broadcast</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import notificationsApi from '../../../api/notifications'

export default {
  components: {},
  data: () => ({
    isSending: false,
    data: {
      title: '',
      message: ''
    }
  }),
  created() {},
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    })
  },
  methods: {
    async sendNotification() {
      this.isSending = true
      const res = await notificationsApi
        .notification()
        .sendNotificationToAllUsers({
          title: this.data.title,
          message: this.data.message
        })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        this.isSending = false
        return
      }

      this.data = { title: '', message: '' }
      this.$store.dispatch('alert', {
        message: 'Notification sent!',
        status: true
      })
      this.isSending = false
    }
  }
}
</script>

<style></style>
