var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"},attrs:{"id":"dashboard"}},[_c('v-app-bar',{attrs:{"flat":"","app":"","light":"","color":"white"}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.sidebarMenu = !_vm.sidebarMenu}}}),_c('v-toolbar-title',{staticClass:"d-flex align-center"},[_c('a',{staticClass:"d-flex align-center",attrs:{"href":"https://snappyexchange.com"}},[_c('v-img',{staticClass:"d-none d-md-block mr-2",attrs:{"src":_vm.logo,"width":"45"}}),_c('span',{staticClass:"d-none black--text d-md-block"},[_vm._v("Snappy Exchange")])],1)]),_c('v-spacer'),_c('span',{staticClass:"text-subtitle-2 text-md-h6 text--primary mr-3"},[_vm._v(_vm._s(_vm.wallet ? _vm.numberWithCommas(_vm.wallet.balance) : 0)+" "+_vm._s(_vm.userCurrency)+" ")]),_c('v-badge',{attrs:{"content":_vm.notifications.length,"value":_vm.notifications.length > 0,"overlap":"","color":"red"}},[_c('v-icon',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.notificationModal = !_vm.notificationModal}}},[_vm._v("mdi-bell-outline ")])],1),_c('v-menu',{attrs:{"transition":"scale-transition","origin":"center center","left":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-outline")])],1)]}}])},[_c('v-list',{staticClass:"text-center",attrs:{"dense":"","elevation":"2","min-width":"100"}},[_c('v-list-item',{attrs:{"ripple":"","to":"/user/settings"}},[_c('v-list-item-title',[_vm._v("Settings")])],1),_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.logout}},[_c('v-list-item-title',[_vm._v("Logout")])],1)],1)],1)],1),_c('v-navigation-drawer',{attrs:{"width":"300","temporary":"","app":"","right":""},model:{value:(_vm.notificationModal),callback:function ($$v) {_vm.notificationModal=$$v},expression:"notificationModal"}},[_c('v-sheet',{attrs:{"color":"py-4 pa-4"}},[_c('h4',{staticClass:"text-h6 text-right py-2"},[_vm._v("Notification")]),_c('v-divider',{staticClass:"mb-2"}),_vm._l((_vm.notifications),function(notification){return _c('v-card',{key:notification._id,staticClass:"my-2"},[_c('v-toolbar',{attrs:{"color":"primary"}},[_c('p',{staticClass:"white--text overline mb-0"},[_vm._v(_vm._s(notification.title))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dark":"","disabled":_vm.marking,"color":"error"},on:{"click":function($event){return _vm.readNotification(notification._id)}}},'v-icon',attrs,false),on),[_vm._v("mdi-close ")])]}}],null,true)},[_c('span',[_vm._v("Mark as Read")])])],1),_c('v-card-text',[_c('p',{staticClass:"text-capitalize grey--text my-2"},[_vm._v(" "+_vm._s(_vm.timeAgo.format(new Date(notification.createdAt)))+" ")]),_c('p',{staticClass:"grey--text text--darken-1 mb-0"},[_vm._v(" "+_vm._s(notification.message)+" ")])])],1)})],2)],1),_c('v-navigation-drawer',{staticClass:"primary",staticStyle:{"height":"100%","max-height":"100%"},attrs:{"app":"","mini-variant":_vm.toggleMini},on:{"update:miniVariant":function($event){_vm.toggleMini=$event},"update:mini-variant":function($event){_vm.toggleMini=$event}},model:{value:(_vm.sidebarMenu),callback:function ($$v) {_vm.sidebarMenu=$$v},expression:"sidebarMenu"}},[_c('v-list-item',{staticClass:"px-2",on:{"click":function($event){_vm.toggleMini = !_vm.toggleMini}}},[_c('v-btn',{attrs:{"icon":"","small":""}},[_c('v-icon',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.toggleMini ? 'mdi-chevron-right' : 'mdi-chevron-left')+" ")])],1)],1),_c('div',{staticClass:"py-3 text-center"},[_c('v-list-item',{staticClass:"px-2"},[_c('v-list-item-avatar',{staticClass:"mx-auto",attrs:{"width":_vm.toggleMini ? '' : 105,"height":_vm.toggleMini ? '' : 105}},[_c('v-img',{attrs:{"src":_vm.user.image}})],1)],1),_c('v-list-item',{attrs:{"link":"","to":"/user/settings"}},[_c('v-list-item-content',[(
              _vm.user.fname == 'UNDEFINED' ||
              _vm.user.lname == 'UNDEFINED' ||
              !_vm.user.fname ||
              !_vm.user.lname
            )?_c('span',{staticClass:"subtitle text-error white--text"},[_vm._v(_vm._s(_vm.toggleMini ? '' : 'Kindly update your profile'))]):_c('v-list-item-title',{staticClass:"title white--text"},[_vm._v(" "+_vm._s(_vm.user.fname == 'UNDEFINED' || _vm.user.lname == 'UNDEFINED' || !_vm.user.fname ? "" : (" " + (_vm.user.fname)))+" ")]),_c('v-list-item-subtitle',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.user.email)+" ")])],1)],1),_c('v-list-item',{on:{"click":_vm.toggleRankDialog}},[_c('v-list-item-content',[(_vm.currentRank)?_c('v-chip',{attrs:{"color":"accent rank"}},[_vm._v(" "+_vm._s(_vm.currentRank.title)+" ")]):_vm._e()],1)],1)],1),_c('v-list',{attrs:{"dark":""}},[_vm._l((_vm.menus),function(item){return [(item.children && item.children.length > 0)?[_c('v-list-group',{key:item.title,attrs:{"active-class":"border","ripple":"","prepend-icon":item.icon,"no-action":""},scopedSlots:_vm._u([{key:"prependIcon",fn:function(){return [_c('v-icon',{attrs:{"color":("" + (_vm.$route.path.split('/').includes(item.href.split('/')[2])
                    ? 'primary'
                    : ''))}},[_vm._v(_vm._s(item.icon)+" ")])]},proxy:true},{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)]},proxy:true}],null,true)},_vm._l((item.children),function(child){return _c('v-list-item',{key:child.title,attrs:{"link":"","to":child.href}},[_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"color":"white"},domProps:{"textContent":_vm._s(child.title)}})],1)],1)}),1)]:[_c('v-list-item-group',{key:item.title},[_c('v-list-item',{key:item.title,staticClass:"my-3",attrs:{"ripple":"","link":"","to":item.href,"active-class":"border","color":("" + (_vm.$route.path.split('/').includes(item.href.split('/')[2])
                  ? 'primary'
                  : ''))}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":("" + (_vm.$route.path.split('/').includes(item.href.split('/')[2])
                      ? 'primary'
                      : '#fff'))}},[_vm._v(_vm._s(item.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:("" + (_vm.$route.path.split('/').includes(item.href.split('/')[2])
                      ? 'font-weight-medium'
                      : 'white--text font-weight-regular'))},[_vm._v(_vm._s(item.title)+" ")])],1)],1)],1)]]})],2)],1),_c('v-main',{staticClass:"grey lighten-4 pb-0 overflow-hidden",staticStyle:{"height":"100%"}},[_c('v-container',{staticClass:"px-4 py-0 fill-height",attrs:{"fluid":""}},[_c('v-row',{staticClass:"fill-height"},[_c('v-col',[_c('transition',[_c('router-view')],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500"},on:{"click:outside":_vm.toggleRankDialog},model:{value:(_vm.viewRankDialog),callback:function ($$v) {_vm.viewRankDialog=$$v},expression:"viewRankDialog"}},[(_vm.currentRank !== null)?_c('v-card',[_c('v-container',[_c('div',{staticClass:"py-10"},[_c('v-img',{staticStyle:{"margin":"0 auto"},attrs:{"src":_vm.currentRank.badge,"alt":_vm.currentRank.title,"width":"105","height":"105"}})],1),_c('div',{staticClass:"text-center px-10"},[_vm._v(" You are a "),_c('span',{staticClass:"primary--text bold"},[_vm._v(_vm._s(_vm.currentRank.title))]),_vm._v(" trade up to "),_c('span',{staticClass:"primary--text bold"},[_vm._v(_vm._s(_vm.ranksAmount.minTransactionAmount))]),_vm._v(" to become a "),_c('span',{staticClass:"primary--text bold"},[_vm._v(_vm._s(_vm.nextRank.title))]),_vm._v(", and earn Cashback Points. ")]),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"mt-5",attrs:{"color":"primary","href":"https://blog.snappyexchange.com/category/userranking","target":"_blank"}},[_vm._v(" Learn More ")])],1)])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }