<template>
  <v-container class="py-10">
    <user-details-min :userId="minUserId" :open.sync="openMin" />
    <user-details :open.sync="userDetailsDialog" :user="user" />
    <v-card flat color="white">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-select
          v-model="selectedCountry"
          :items="['All', 'Nigeria', 'Ghana', 'Kenya']"
          label="Country"
        />
      </v-card-title>
      <v-data-table :loading="loadingUser" :items="users" :headers="headers">
        <template v-slot:[`item.user`]="{ item }">
          <span
            class="font-weight-medium cursor-pointer primary--text text-capitalize d-block"
            @click="openMinDialog(item._id)"
          >
            {{ item.fname }} {{ item.lname }}
          </span>
          <small
            class="font-weight-regular grey--text text--darken-2 d-block"
            >{{ item.username }}</small
          >
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import userDetails from '../userDetails.vue'
import { mapState } from 'vuex'
import UserDetailsMin from '../userDetailsMin.vue'
import userApi from '../../api/admin/userManagement'
// import _ from 'lodash'

export default {
  components: { userDetails, UserDetailsMin },
  name: 'UserListReferralEarnings',
  data: () => ({
    selectedCountry: 'All',
    openMin: false,
    minUserId: '',
    search: '',
    userDetailsDialog: false,
    actionOnDialog: false,
    user: {},
    userTransactions: [],
    headers: [
      {
        text: 'User',
        align: 'start',
        sortable: false,
        value: 'user'
      },
      {
        text: 'Referral Points',
        value: 'totalReferralPoints'
      }
    ],
    actionType: {},
    actionUserId: '',
    users: [],
    loadingUser: false,
    perPage: 10,
    apiData: {},
    lastPage: 1
  }),
  props: {
    userToGet: String
  },
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    })
  },
  created() {
    this.getReferralSummary()
  },
  watch: {
    search(newValue) {
      if (newValue === '') {
        this.getTransactions(0)
        return
      }
      this.debounceSearch()
    },
    selectedCountry(newValue) {
      this.getReferralSummary()
    }
  },
  methods: {
    openMinDialog(id) {
      this.openMin = true
      this.minUserId = id
    },
    selectUser(user) {
      this.user = user
      this.userDetailsDialog = true
    },
    // updatePage(page) {
    //   if (this.lastPage - page > 2) {
    //     page = this.lastPage
    //   }
    //   this.lastPage = page
    //   this.getUsers((page - 1) * this.perPage)
    // },
    // updateItemPerPage(count) {
    //   this.perPage = count
    //   this.getUsers((this.lastPage - 1) * this.perPage)
    // },
    // async searchData() {
    //   this.loadingUser = true
    //   const res = await userManagement.users().getReferrals({
    //     limit: this.perPage,
    //     keyword: this.search
    //   })

    //   if (res.error) {
    //     this.alert({
    //       message: 'Unable to fetch users',
    //       error: true,
    //       time: 4000,
    //       status: true
    //     })
    //     this.loadingActive = false
    //     return
    //   }

    //   this.users = res.data.data.data.map((d) => ({
    //     ...d,
    //     user: `${d.fname} ${d.lname} ${d.username}`
    //   }))
    //   this.apiData = res.data.data.meta
    //   this.loadingUser = false
    // },

    async getReferralSummary() {
      this.loadingUser = true
      const res = await userApi.users().getReferralSummary({
        country: this.selectedCountry === 'All' ? '' : this.selectedCountry
      })

      if (res.error) {
        this.alert({
          message: 'Unable to fetch users',
          error: true,
          time: 4000,
          status: true
        })
        this.loadingActive = false
        return
      }

      this.users = res.data.data.map((d) => {
        return {
          ...d,
          ...d.user,
          _id: d.user._id,
          user: `${d.user.fname} ${d.user.lname} ${d.user.username}`
        }
      })

      this.loadingUser = false
    }
  }
}
</script>

<style></style>
