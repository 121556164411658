<template>
  <v-container>
    <h1 class="mb-5">Giftcards</h1>
    <delete-dialog
      :progress.sync="progress"
      :open.sync="deleteDialog"
      :type="typeToDelete"
    ></delete-dialog>

    <v-dialog persistent v-model="editTypeDialog" max-width="600">
      <v-card>
        <v-card-title>Edit Giftcard Type</v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="editType.name"
                required
                label="Type Name"
                hint="e.g Physical Card"
                filled
                persistent-hint
                :disabled="editingType"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-file-input
                filled
                hint="Image to show has explanation for sellers"
                :disabled="editingType"
                counter
                show-size
                v-model="editType.image"
                label="Type Image"
              ></v-file-input>
            </v-col>
            <v-col cols="12">
              <v-textarea
                :disabled="editingType"
                auto-grow
                filled
                v-model="editType.details"
                label="Card Details"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer> </v-spacer>
            <v-btn
              :loading="editingType"
              depressed
              @click="editGiftcardType"
              color="accent"
              >Save</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="createTypeDialog" max-width="600">
      <v-card>
        <v-card-title>New Giftcard Type</v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="newType.name"
                required
                label="Type Name"
                hint="e.g Physical Card"
                filled
                persistent-hint
                :disabled="creatingType"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-file-input
                filled
                hint="Image to show has explanation for sellers"
                :disabled="creatingType"
                counter
                show-size
                v-model="newType.image"
                label="Type Image"
              ></v-file-input>
            </v-col>
            <v-col cols="12">
              <v-textarea
                :disabled="creatingType"
                auto-grow
                filled
                v-model="newType.details"
                label="Card Details"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer> </v-spacer>
            <v-btn
              :loading="creatingType"
              depressed
              :disabled="!newType.details || !newType.image || !newType.name"
              @click="createGiftcardType"
              color="accent"
              >Save</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <v-card flat>
      <v-container>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn
            to="/yurd2m/yurd2m/settings/giftcard/new"
            color="accent"
            depressed
            class="ma-1"
            v-if="checkIfPermission('create giftcard', admin.permissions)"
          >
            <v-icon left>mdi-plus</v-icon>
            New Card</v-btn
          >
          <v-btn
            @click.stop="createTypeDialog = true"
            color="accent"
            depressed
            class="ma-1"
          >
            <v-icon left>mdi-plus</v-icon>
            New Card Type</v-btn
          >
        </v-toolbar>
        <v-tabs v-model="tabs">
          <v-tab>Giftcard</v-tab>
          <v-tab>Giftcard Types</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs">
          <v-tab-item>
            <v-card-text>
              <v-data-table
                :loading="giftcards.length === 0"
                :items="giftcards"
                :headers="headers"
              >
                <template v-slot:[`item.name`]="{ item }">
                  <router-link
                    :to="`/yurd2m/yurd2m/settings/giftcard/edit/${item._id}`"
                    class="font-weight-bold"
                    >{{ item.name }}</router-link
                  >
                </template>
                <template v-slot:[`item.image`]="{ item }">
                  <img width="55" :src="item.image" :alt="item.name" />
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-if="
                          (item.status === false || !item.status) &&
                          checkIfPermission(
                            'edit giftcard',
                            admin.permissions
                          ) &&
                          checkIfPermission('view giftcard', admin.permissions)
                        "
                        link
                        :to="`/yurd2m/yurd2m/settings/giftcard/edit/${item._id}`"
                        ripple
                      >
                        <v-list-item-title>Edit</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="actionStatusOnCard('giftcard', true, item)"
                        v-if="
                          (item.status === false || !item.status) &&
                          checkIfPermission('edit giftcard', admin.permissions)
                        "
                        ripple
                      >
                        <v-list-item-title>Activate</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="actionStatusOnCard('giftcard', false, item)"
                        v-if="
                          (item.status === true || item.status) &&
                          checkIfPermission('edit giftcard', admin.permissions)
                        "
                        ripple
                      >
                        <v-list-item-title>Deactivate</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="
                          checkIfPermission(
                            'delete giftcard',
                            admin.permissions
                          )
                        "
                        ripple
                        @click="actionOnCard('giftcard', item)"
                      >
                        <v-list-item-title class="error--text"
                          >Delete</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <span
                    :class="`text-capitalize  text-center rounded py-2 px-3 white--text ${
                      item.status == true ? 'success' : 'error'
                    }`"
                    >{{ item.status ? 'active' : 'inactive' }}</span
                  >
                </template>
              </v-data-table>
            </v-card-text>
          </v-tab-item>
          <v-tab-item>
            <v-card-text>
              <v-data-table
                :loading="giftcardtypes.length === 0"
                :items="giftcardtypes"
                :headers="headers"
              >
                <template v-slot:[`item.name`]="{ item }">
                  <span class="font-weight-bold">{{ item.name }}</span>
                </template>
                <template v-slot:[`item.image`]="{ item }">
                  <img width="55" :src="item.image" :alt="item.name" />
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        @click="
                          editTypeDialog = true
                          editType = item
                        "
                        ripple
                      >
                        <v-list-item-title>Edit</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="actionStatusOnCard('giftcardtype', true, item)"
                        v-if="!item.status"
                        ripple
                      >
                        <v-list-item-title>Activate</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="actionStatusOnCard('giftcardtype', false, item)"
                        v-if="item.status"
                        ripple
                      >
                        <v-list-item-title>Deactivate</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        ripple
                        @click="actionOnCard('giftcardtype', item)"
                      >
                        <v-list-item-title class="error--text"
                          >Delete</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <span
                    :class="`text-capitalize  text-center rounded py-2 px-3 white--text ${
                      item.status == true ? 'success' : 'error'
                    }`"
                    >{{ item.status ? 'active' : 'inactive' }}</span
                  >
                </template>
              </v-data-table>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import deleteDialog from '../../../components/deleteDialog.vue'
import dataApi from '../../../api/systemData'
import giftcardApi from '../../../api/admin/giftcard'
import { mapState } from 'vuex'

export default {
  components: { deleteDialog },
  data: () => ({
    createTypeDialog: false,
    editingType: false,
    editTypeDialog: false,
    editType: {},
    tabs: 0,
    newType: {},
    progress: 'starting',
    creatingType: false,
    typeToDelete: {
      type: '',
      data: {}
    },
    deleteDialog: false,
    bankAccount: false,
    headers: [
      {
        value: 'image',
        text: '',
        sortable: false
      },
      {
        value: 'name',
        text: 'Name'
      },
      {
        value: 'status',
        text: 'Active'
      },
      {
        value: 'action',
        text: '',
        sortable: false
      }
    ],
    giftcards: [],
    giftcardtypes: []
  }),
  watch: {
    progress(newValue, oldValue) {
      if (newValue === 'done') {
        if (this.typeToDelete.type === 'giftcard') this.getGiftCards()
        else this.getGiftCardsTypes()
      }
      this.progress = 'starting'
    }
  },
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    })
  },
  created() {
    this.getGiftCards()
    this.getGiftCardsTypes()
  },
  methods: {
    actionOnCard(type, data) {
      this.typeToDelete.type = type
      this.typeToDelete.data = data
      this.deleteDialog = true
    },
    async actionStatusOnCard(type, status, data) {
      const res = await dataApi
        .data()
        .editSystemData({ type, typeId: data._id }, { status })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        return
      }
      this.$store.dispatch('alert', {
        message: 'Success',
        status: true
      })
      if (type === 'giftcard') this.getGiftCards()
      else this.getGiftCardsTypes()
    },
    async getGiftCards() {
      const res = await dataApi.data().getData('admin', { type: 'giftcard' })
      this.giftcards = res.data.data.data
    },
    async createGiftcardType() {
      this.creatingType = true
      const res = await giftcardApi.giftcards().createGiftCardType({
        name: this.newType.name,
        image: this.newType.image,
        details: this.newType.details
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.creatingType = false
        return
      }
      this.getGiftCardsTypes()
      this.$store.dispatch('alert', {
        message: 'New card type created successfully',
        status: true
      })
      this.creatingType = false
      this.newType = {}
      this.createTypeDialog = false
    },
    async editGiftcardType() {
      this.editingType = true
      const res = await giftcardApi.giftcards().updateGiftCardType({
        cardTypeId: this.editType._id,
        name: this.editType.name,
        image: this.editType.image,
        details: this.editType.details
      })

      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.editingType = false
        return
      }
      this.getGiftCardsTypes()
      this.$store.dispatch('alert', {
        message: 'New card type created successfully',
        status: true
      })
      this.editingType = false
      this.editType = {}
      this.editTypeDialog = false
    },
    async getGiftCardsTypes() {
      const res = await dataApi
        .data()
        .getData('admin', { type: 'giftcardtype' })
      this.giftcardtypes = res.data.data.data
    }
  }
}
</script>

<style></style>
