<template>
  <v-container>
    <div
      v-if="!checkIfPermission('view message', admin.permissions)"
      class="w-100 h-100 d-flex justify-center align-center"
    >
      <h1 class="error--text">You don't have access to this page</h1>
    </div>
    <div v-else>
      <h1>Users Message</h1>
      <v-dialog max-width="600" v-model="viewMessageDialog"> </v-dialog>

      <v-row>
        <v-col>
          <v-card flat class="pa-4">
            <v-card-title class="d-flex align-baseline">
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search..."
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :search="search"
              :loading="messages.length == 0"
              :items="messages"
              :headers="headers"
            >
              <template v-slot:[`item.sender`]="{ item }">
                <router-link
                  :to="`/yurd2m/yurd2m/messages/view/${item._id}`"
                  class="font-weight-bold"
                  >{{ item.sender }}</router-link
                >
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <span
                  :class="`text-capitalize rounded py-2 px-3 white--text ${
                    item.view ? 'warning' : 'success'
                  }`"
                  >{{ item.view ? 'Checked' : 'New' }}</span
                >
              </template>
              <!-- <template v-slot:[`item.action`]="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item ripple @click="viewMessage(item)">
                      <v-list-item-title>View Message</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="openDeleteDialog = true"
                      ripple
                      class="error--text"
                    >
                      <v-list-item-title
                        >{{ item ? '' : '' }}Delete</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template> -->
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import userApi from '../../../api/admin/userManagement'

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    })
  },
  data: () => ({
    search: '',
    emailTabs: null,
    viewMessageDialog: false,
    openDeleteDialog: false,
    messages: [],
    selectedMessage: {},
    sendingReply: false,
    reply: {},
    headers: [
      {
        text: 'Date',
        value: 'date'
      },
      {
        text: 'Sender',
        value: 'sender'
      },
      {
        text: 'Subject',
        value: 'subject'
      },
      {
        text: 'Status',
        value: 'status',
        sortable: false
      },
      {
        text: '',
        value: 'action'
      }
    ],
    data: []
  }),
  created() {
    this.getMessages()
  },
  methods: {
    async viewMessage(message) {
      this.selectedMessage = message
      this.viewMessageDialog = true
      await userApi.users().markMessageRead({ messageId: message._id })
      this.getMessages()
    },
    async getMessages() {
      const res = await userApi
        .users()
        .getMessages('received', { admin: false })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        return
      }

      this.messages = res.data.data.data
        .map((d) => ({
          ...d,
          date: new Date(d.createdAt).toDateString(),
          sender: d.user.username ? d.user.username : '',
          subject: d.title
        }))
        .sort((a, b) => {
          const textA = a.view.toString().toLowerCase()
          const textB = b.view.toString().toLowerCase()
          return textA < textB ? -1 : textA > textB ? 1 : 0
        })

      // console.log(this.messages)
    }
  }
}
</script>

<style></style>
