
import Store from '../../store'
import { errorHandler, successHandler } from '../helpers/handler'
import adminHttp from './http'


const axios = adminHttp
const URL = `cryptocoin/`

export default {
  crypto() {
    return {
      addImage: async ({ coinId, file, ...params }) => {
        const endpoint = `${URL}/${coinId}/image`
        const formData = new FormData()
        formData.append('image', file)
        try {
          const res = await axios.post(endpoint, formData, {
            params,
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getNetworks: async () => {
        const endpoint = `${URL}/networks`
        try {
          const res = await axios.get(endpoint, {
            params: {
              limit: 0
            },
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      createNetwork: async (data) => {
        const endpoint = `${URL}/networks`
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      updateNetwork: async (id, data) => {
        const endpoint = `${URL}/networks/${id}`
        try {
          const res = await axios.put(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      deleteNetwork: async (id) => {
        const endpoint = `${URL}/networks/${id}`
        try {
          const res = await axios.delete(endpoint, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      addNetworkToCoin: async (coinId, networkIds) => {
        const endpoint = `${URL}/${coinId}/add-currency/`
        try {
          const res = await axios.post(
            endpoint,
            {
              ids: networkIds
            },
            {
              headers: {
                ...Store.getters['admin/getRequestHeaders'].headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      removeNetworkFromCoin: async (coinId, networkIds) => {
        const endpoint = `${URL}/${coinId}/remove-currency/`
        try {
          const res = await axios.post(
            endpoint,
            {
              ids: networkIds
            },
            {
              headers: {
                ...Store.getters['admin/getRequestHeaders'].headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      processCryptoSales: async (data) => {
        const endpoint = `${URL}/process-crypto-sale`
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
