import { errorHandler, successHandler } from '../helpers/handler'
import adminHttp from './http'

const URL = `devices`

const axios = adminHttp

export default {
  device() {
    return {
      register: async ({ visitorId, userId, rawData }) => {
        const endpoint = `${URL}/register/`
        const data = { deviceId: visitorId, userId, userAgent: rawData }
        try {
          const res = await axios.post(endpoint, data)
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
