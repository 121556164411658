<template>
  <v-container>
    <div v-if="checkIfPermission('view cryptowallet', admin.permissions)">
      <h1 class="mb-5">System Currencies</h1>
      <delete-dialog
        :progress.sync="deleteProgress"
        :open.sync="deleteDialog"
        :type="deleteData"
      ></delete-dialog>
      <v-dialog
        @click:outside="editSelectedCurrency = false"
        v-model="editDialog"
        max-width="600"
      >
        <v-card>
          <v-toolbar flat>
            <v-card-title
              >{{ editSelectedCurrency ? 'Edit' : 'View' }} E-Currency
            </v-card-title>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="accent"
              @click="editSelectedCurrency = !editSelectedCurrency"
              >{{ editSelectedCurrency ? 'Cancel Edit' : 'Edit Currency' }}
            </v-btn>
          </v-toolbar>

          <v-container>
            <v-row v-if="currency !== {}">
              <v-col cols="12" md="6">
                <v-text-field
                  :readonly="!editSelectedCurrency"
                  v-model="currency.name"
                  label="Currency Name"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  :readonly="!editSelectedCurrency"
                  v-model="currency.hasAutoProcessing"
                  label="Automatic Processing?"
                  filled
                  item-text="text"
                  item-value="value"
                  :items="[
                    { text: 'Yes', value: 'yes' },
                    { text: 'No', value: 'no' }
                  ]"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="6"
                v-if="currency.hasAutoProcessing === 'yes'"
              >
                <v-select
                  :readonly="!editSelectedCurrency"
                  v-model="currency.symbol"
                  label="Currency Symbol"
                  filled
                  hint="Fixed Symbol for Auto processing transaction"
                  item-text="text"
                  item-value="value"
                  :items="coinList"
                ></v-select>
                <span
                  v-if="currency.symbol === 'TCN'"
                  class="text-red-500 text-xs"
                >
                  You can use this symbol for testing purpose only
                </span>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :readonly="!editSelectedCurrency"
                  v-model="currency.displaySymbol"
                  label="Symbol to display to users"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :readonly="!editSelectedCurrency"
                  v-model="currency.price"
                  label="Currency Price"
                  type="number"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  :readonly="!editSelectedCurrency"
                  label="Payment Type"
                  v-model="currency.type"
                  filled
                  item-text="text"
                  item-value="value"
                  :items="[
                    { text: 'Wallet', value: 'wallet' },
                    { text: 'Account Number', value: 'account_number' }
                  ]"
                >
                </v-select>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  :readonly="
                    !editSelectedCurrency ||
                    !checkIfPermission(
                      'CHANGE_CRYPTO_WALLET',
                      admin.permissions
                    )
                  "
                  v-model="currency.payment_id"
                  label="Wallet Address"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :readonly="
                    !editSelectedCurrency ||
                    !checkIfPermission('change_crypto_rate', admin.permissions)
                  "
                  v-model="currency.sell"
                  label="Sell Rate"
                  filled
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :readonly="
                    !editSelectedCurrency ||
                    !checkIfPermission('change_crypto_rate', admin.permissions)
                  "
                  label="Buy Rate"
                  v-model="currency.buy"
                  filled
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :readonly="!editSelectedCurrency"
                  label="Card Payment Prefix"
                  v-model="currency.prefix"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Account Number"
                  v-model="currency.account_number"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :readonly="!editSelectedCurrency"
                  label="Minimum Buy"
                  v-model="currency.miniorder"
                  type="number"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Coin Transfer Fee"
                  v-model="currency.tcharge"
                  :readonly="!editSelectedCurrency"
                  type="number"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-file-input
                  v-model="currency.imageToUpload"
                  filled
                  :readonly="!editSelectedCurrency"
                  :loading="sendingImage"
                  @change="uploadImage('edit')"
                  counter
                  show-size
                  accept="image/*"
                  label="Image"
                >
                </v-file-input>
              </v-col>
              <!-- <v-col cols="4">
                <v-switch
                  :readonly="!editSelectedCurrency"
                  filled
                  label="Currency Active"
                  v-model="currency.status"
                  hint="Active currency will show to users"
                ></v-switch>
              </v-col>
              <v-col cols="4">
                <v-switch
                  :readonly="!editSelectedCurrency"
                  filled
                  label="Is Coin"
                  v-model="currency.is_coin"
                  hint="Coin currencies will show for user to trade"
                ></v-switch>
              </v-col> -->
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="
                  !editSelectedCurrency || !currency.image || sendingImage
                "
                depressed
                :loading="loadingEdit"
                @click="editCurrency"
                color="accent"
                >Save Changes
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="createDialog" max-width="600">
        <v-card>
          <v-toolbar flat>
            <v-card-title>Create E-Currency</v-card-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            Fill the form below to create a new cryptocurrency for the system.
            Please note that the currency symbol has to conform with the
            standard blockchain currency symbol. Please check
            <a href="https://min-api.cryptocompare.com/">Here</a> to see list of
            supported currency symbols.
          </v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="newCurrency.name"
                  label="Currency Name"
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                  v-model="newCurrency.hasAutoProcessing"
                  label="Automatic Processing?"
                  filled
                  item-text="text"
                  item-value="value"
                  :items="[
                    { text: 'Yes', value: 'yes' },
                    { text: 'No', value: 'no' }
                  ]"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="6"
                v-if="newCurrency.hasAutoProcessing === 'yes'"
              >
                <v-select
                  v-model="newCurrency.symbol"
                  label="Currency Symbol"
                  filled
                  hint="Fixed Symbol for Auto processing transaction"
                  item-text="text"
                  item-value="value"
                  :items="coinList"
                ></v-select>
                <span
                  v-if="newCurrency.symbol === 'TCN'"
                  class="text-red-500 text-xs"
                >
                  You can use this symbol for testing purpose only
                </span>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="newCurrency.displaySymbol"
                  label="Symbol to display to users"
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="newCurrency.price"
                  label="Currency Price"
                  type="number"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  label="Payment Type"
                  v-model="newCurrency.type"
                  filled
                  item-text="text"
                  item-value="value"
                  :items="[
                    { text: 'Wallet', value: 'wallet' },
                    { text: 'Account Number', value: 'account_number' }
                  ]"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Card Payment Prefix"
                  v-model="newCurrency.prefix"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="newCurrency.sell"
                  label="Sell Rate"
                  filled
                  placeholder="0.00"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Buy Rate"
                  v-model="newCurrency.buy"
                  placeholder="0.00"
                  filled
                  type="number"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="newCurrency.payment_id"
                  label="Wallet Address"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Account Number"
                  v-model="newCurrency.account_number"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Minimum Buy"
                  v-model="newCurrency.miniorder"
                  type="number"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Coin Transfer Fee"
                  v-model="newCurrency.tcharge"
                  type="number"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-file-input
                  v-model="newCurrency.imageToUpload"
                  filled
                  :loading="sendingImage"
                  @change="uploadImage('new')"
                  counter
                  show-size
                  accept="image/*"
                  label="Image"
                >
                </v-file-input>
              </v-col>
              <!-- <v-col cols="4">
                <v-switch
                  filled
                  label="Currency Active"
                  v-model="currency.status"
                  persistent-hint
                  hint="Active currency will show to users"
                ></v-switch>
              </v-col>
              <v-col cols="4">
                <v-switch
                  filled
                  label="Is Coin"
                  v-model="currency.is_coin"
                  persistent-hint
                  hint="Coin currencies will show for user to trade"
                ></v-switch>
              </v-col> -->
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                :loading="loading"
                @click="createCurrency"
                color="accent"
                >Create
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="addRates" max-width="600">
        <v-card>
          <v-toolbar flat>
            <v-card-title> Add rates</v-card-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-container>
            <div v-for="(price, i) in currency.priceVariations" :key="i">
              <span
                @click="removeRate(i)"
                class="text-red-500 cursor-pointer block text-right text-sm px-2 mb-1"
                >Remove</span
              >
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="price.min"
                    label="Minimum range"
                    filled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="price.max"
                    label="Maximum range"
                    filled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="price.rate"
                    label="Rate"
                    filled
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                :loading="loadingEdit"
                @click="addMoreRates"
                color="accent"
                >Add More
              </v-btn>
              <v-btn
                depressed
                :loading="loadingEdit"
                @click="editCurrency"
                color="accent"
                >Save all
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="createNetworkDialog" max-width="600">
        <v-card>
          <v-toolbar flat>
            <v-card-title
              >{{ isEditNetwork ? 'Edit' : 'Create' }} Network
            </v-card-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="newNetwork.name"
                  label="Network Name"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="newNetwork.wallet"
                  label="Wallet Address"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-file-input
                  v-model="newNetwork.barcode"
                  label="Network Barcode"
                  filled
                ></v-file-input>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="!newNetwork.barcode && !isEditNetwork"
                depressed
                :loading="isCreating"
                @click="createNetworkCurrency"
                color="accent"
                >Save Changes
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="addNetworkToCurrencyModal" max-width="600">
        <v-card>
          <v-toolbar flat>
            <v-card-title>Add Network To Currency</v-card-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  label="Network"
                  filled
                  item-text="name"
                  item-value="_id"
                  :items="networks"
                  v-model="selectedNetwork"
                  multiple
                  chips
                >
                  <template v-slot:item="data">
                    <v-list-item-avatar>
                      <img :src="data.item.barcode" :alt="data.item.name" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.wallet"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                color="accent"
                @click="addNetworkToCoin"
                :loading="isCreating"
                >Save
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="removeNetworkToCurrencyModal" max-width="600">
        <v-card>
          <v-toolbar flat>
            <v-card-title>Remove Network To Currency</v-card-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  label="Network"
                  filled
                  item-text="name"
                  item-value="_id"
                  :items="networks"
                  v-model="selectedCoinNetwork"
                  multiple
                  chips
                >
                  <template v-slot:item="data">
                    <v-list-item-avatar>
                      <img :src="data.item.barcode" :alt="data.item.name" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.wallet"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                color="accent"
                @click="removeNetworkToCoin"
                :loading="isCreating"
                >Save
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>

      <v-card flat>
        <v-container>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-btn
              v-if="checkIfPermission('manage cryptowallet', admin.permissions)"
              @click="createDialog = true"
              color="accent"
              depressed
              class="mr-3"
            >
              <v-icon left>mdi-plus</v-icon>
              Create New
            </v-btn>
            <v-btn
              v-if="checkIfPermission('manage cryptowallet', admin.permissions)"
              @click="createNetworkDialog = true"
              color="accent"
              depressed
            >
              <v-icon left>mdi-plus</v-icon>
              Create New Network
            </v-btn>
          </v-toolbar>
          <v-tabs v-model="activeTab">
            <v-tab>Cryptocurrency</v-tab>
            <v-tab>Network Currency</v-tab>
          </v-tabs>
          <v-tabs-items v-model="activeTab">
            <v-tab-item>
              <v-card-text>
                <v-data-table
                  :loading="currencies.length === 0"
                  :items="currencies"
                  :headers="headers"
                >
                  <template v-slot:[`item.name`]="{ item }">
                    <span class="font-weight-bold text-capitalize">{{
                      item.name
                    }}</span>
                  </template>
                  <template v-slot:[`item.symbol`]="{ item }">
                    <span>
                      {{ item.symbol || item.displaySymbol }}
                    </span>
                  </template>
                  <template v-slot:[`item.hasAutoProcessing`]="{ item }">
                    <span class="uppercase">
                      {{ item.hasAutoProcessing }}
                    </span>
                  </template>
                  <template v-slot:[`item.networks`]="{ item }">
                    <span v-if="item.networks.length > 0">{{
                      item.networks.map((network) => network.name).join(', ')
                    }}</span>
                  </template>
                  <template v-slot:[`item.image`]="{ item }">
                    <v-img :src="item.image" class="pa-2" width="45px"></v-img>
                  </template>
                  <template v-slot:[`item.rate`]="{ item }">
                    <span class="text-capitalize">
                      1 {{ item.symbol }} = <b>{{ item.price }}</b></span
                    >
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <v-menu
                      v-if="
                        checkIfPermission(
                          'manage cryptowallet',
                          admin.permissions
                        )
                      "
                      bottom
                      left
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="selectCurrency(item)" ripple>
                          <v-list-item-title>View</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                          @click="selectCurrencyForRate(item, 'add')"
                          v-if="
                            item.status === true &&
                            item.hasAutoProcessing === 'yes'
                          "
                          ripple
                        >
                          <v-list-item-title>Add Rates</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                          @click="onSelectCoinForNetwork(item, 'add')"
                          v-if="item.status === true"
                          ripple
                        >
                          <v-list-item-title>Add Networks</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                          @click="onSelectCoinForNetwork(item, 'remove')"
                          v-if="item.status === true"
                          ripple
                        >
                          <v-list-item-title>Remove Networks</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                          @click="actionOnCurrency(item, 'deactivate')"
                          v-if="item.status === true"
                          ripple
                        >
                          <v-list-item-title>Deactivate</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          @click="actionOnCurrency(item, 'activate')"
                          v-if="item.status === false"
                          ripple
                        >
                          <v-list-item-title>Activate</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="deleteCurrency(item)" ripple>
                          <v-list-item-title class="text--error"
                            >Delete
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <span
                      :class="`d-block text-center text-capitalize rounded py-2 px-3 white--text ${
                        item.status == true ? 'success' : 'error'
                      }`"
                      >{{ item.status ? 'Active' : 'Inactive' }}</span
                    >
                  </template>
                </v-data-table>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text>
                <v-data-table
                  :loading="networks.length === 0"
                  :items="networks"
                  :headers="networkHeader"
                >
                  <template v-slot:[`item.name`]="{ item }">
                    <span class="font-weight-bold text-capitalize">{{
                      item.name
                    }}</span>
                  </template>
                  <template v-slot:[`item.barcode`]="{ item }">
                    <v-img
                      :src="item.barcode"
                      class="pa-2"
                      width="45px"
                    ></v-img>
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <v-menu
                      v-if="
                        checkIfPermission(
                          'manage cryptowallet',
                          admin.permissions
                        )
                      "
                      bottom
                      left
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="selectNetwork(item)" ripple>
                          <v-list-item-title>View</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                          @click="actionOnNetwork(item, false)"
                          v-if="item.status === true"
                          ripple
                        >
                          <v-list-item-title>Deactivate</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          @click="actionOnNetwork(item, true)"
                          v-if="item.status === false"
                          ripple
                        >
                          <v-list-item-title>Activate</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          @click="deleteNetworkCurrency(item)"
                          ripple
                        >
                          <v-list-item-title class="text--error"
                            >Delete
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <span
                      :class="`d-block text-center text-capitalize rounded py-2 px-3 white--text ${
                        item.status == true ? 'success' : 'error'
                      }`"
                      >{{ item.status ? 'Active' : 'Inactive' }}</span
                    >
                  </template>
                </v-data-table>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-card>
    </div>
    <div v-else class="w-100 h-100 d-flex justify-center align-center">
      <h1 class="error--text">You don't have access to this page</h1>
    </div>
  </v-container>
</template>

<script>
import deleteDialog from '../../../components/deleteDialog.vue'
import dataApi from '../../../api/systemData'
import cryptoApi from '../../../api/admin/crypto'
import { mapState } from 'vuex'

export default {
  components: { deleteDialog },
  data: () => ({
    addRates: false,
    editDialog: false,
    deleteProgress: 'starting',
    deleteData: {},
    deleteDialog: false,
    loading: false,
    bankAccount: false,
    currency: {},
    editSelectedCurrency: false,
    createDialog: false,
    createNetworkDialog: false,
    coinList: [
      { text: 'Bitcoin (BTC)', value: 'BTC' },
      { text: 'Avalanche (AVA)', value: 'AVA' },
      { text: 'Tether TRC20 (USDTTRC20)', value: 'USDTTRC20' },
      { text: 'Tron (TRX)', value: 'TRX' },
      { text: 'Tether ERC20 (USDTERC20)', value: 'USDTERC20' },
      { text: 'USD Coin ERC20 (USDCERC20)', value: 'USDCERC20' },
      { text: 'Binance Coin (BNB)', value: 'BNB' },
      { text: 'Dogecoin (DOGE)', value: 'DOGE' },
      { text: 'Litecoin (LTC)', value: 'LTC' },
      { text: 'Bitcoin Cash (BCH)', value: 'BCH' },
      { text: 'Solana (SOL)', value: 'SOL' },
      { text: 'Ethereum (ETH)', value: 'ETH' }
    ],
    headers: [
      {
        value: 'name',
        text: 'E-Currency Name'
      },
      {
        value: 'symbol',
        text: 'Symbol'
      },
      {
        value: 'image',
        text: 'Image'
      },
      {
        value: 'payment_id',
        text: 'Address',
        sortable: false
      },
      {
        value: 'networks',
        text: 'Networks',
        sortable: false
      },
      {
        value: 'rate',
        text: 'Rate ($)',
        sortable: false
      },
      {
        value: 'hasAutoProcessing',
        text: 'Auto Processing'
      },
      {
        value: 'status',
        text: 'Status'
      },
      {
        value: 'action',
        text: '',
        sortable: false
      }
    ],
    networkHeader: [
      {
        value: 'name',
        text: 'Name'
      },
      {
        value: 'wallet',
        text: 'Wallet Address'
      },
      {
        value: 'barcode',
        text: 'Barcode'
      },
      {
        value: 'status',
        text: 'Status'
      },
      {
        value: 'action',
        text: '',
        sortable: false
      }
    ],
    loadingEdit: false,
    preset: 'rwy1auea',
    cloudName: 'snappyexchange',
    currencies: [],
    networks: [],
    newNetwork: {},
    isCreating: false,
    addNetworkToCurrencyModal: false,
    removeNetworkToCurrencyModal: false,
    selectedCoinNetwork: {},
    selectedCoin: '',
    selectedNetwork: '',
    isEditNetwork: false,
    newCurrency: {
      name: '',
      symbol: '',
      price: '',
      payment_id: '',
      sell: '',
      buy: '',
      miniorder: '',
      tchange: '',
      status: true
    },
    activeTab: null,
    sendingImage: false
  }),
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    })
  },
  created() {
    this.getSystemCurrencies()
    this.getNetworks()
  },
  watch: {
    deleteProgress(newValue, oldValue) {
      if (newValue === 'done') {
        this.getSystemCurrencies()
        this.deleteProgress = 'starting'
      }
    }
  },
  methods: {
    async uploadImage(type) {
      if (type === 'new') {
        return
      }
      this.sendingImage = true

      try {
        await cryptoApi.crypto().addImage({
          coinId: this.currency._id,
          file: this.currency.imageToUpload
        })
      } catch (error) {
        this.$store.dispatch('alert', {
          message: 'INTERNET ERROR, PLEASE TRY AGAIN',
          error: true,
          status: true
        })
      }
      this.getSystemCurrencies()
      this.sendingImage = false
    },
    deleteCurrency(currency) {
      this.deleteData = {
        type: 'currency',
        data: currency
      }
      this.deleteDialog = true
    },
    async editCurrency() {
      this.loadingEdit = true
      const res = await dataApi.data().editSystemData(
        { type: 'currency', typeId: this.currency._id },
        {
          ...this.currency,
          hasAutoProcessing: this.currency.hasAutoProcessing === 'yes'
        }
      )
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.loadingEdit = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'Currency edited',
        status: true,
        error: false
      })
      this.loadingEdit = false
      this.editDialog = false
      this.currency = {}
      this.editDialog = false
      this.addRates = false
      this.getSystemCurrencies()
    },
    async actionOnCurrency(currency, action) {
      const res = await dataApi.data().editSystemData(
        { type: 'currency', typeId: currency._id },
        {
          ...currency,
          status: action === 'activate',
          hasAutoProcessing: currency.hasAutoProcessing === 'yes'
        }
      )
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        return
      }
      this.$store.dispatch('alert', {
        message: `Currency ${action}`,
        status: true,
        error: false
      })
      this.getSystemCurrencies()
    },
    selectCurrency(currency) {
      this.currency = currency
      this.editDialog = true
    },
    removeRate(index) {
      this.currency.priceVariations.splice(index, 1)
    },
    selectCurrencyForRate(currency) {
      this.currency = currency
      if (currency.priceVariations && currency.priceVariations.length === 0) {
        this.addMoreRates()
      }
      this.addRates = true
    },
    async addMoreRates() {
      this.currency.priceVariations.push({
        min: 0,
        max: 0,
        rate: 0
      })
    },
    selectNetwork(network) {
      this.isEditNetwork = true
      delete network.barcode
      this.newNetwork = network
      this.createNetworkDialog = true
    },
    async createCurrency() {
      this.loading = true
      const res = await dataApi.data().createSystemData('currency', {
        ...this.newCurrency,
        hasAutoProcessing: this.newCurrency.hasAutoProcessing === 'yes'
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.loading = false
        return
      }
      try {
        await cryptoApi.crypto().addImage({
          coinId: res.data.data._id,
          file: this.newCurrency.imageToUpload
        })
      } catch (error) {
        this.$store.dispatch('alert', {
          message: 'INTERNET ERROR, PLEASE TRY AGAIN',
          error: true,
          status: true
        })
      }
      this.$store.dispatch('alert', {
        message: 'New currency created',
        status: true
      })
      this.loading = false
      this.createDialog = false
      this.newCurrency = {
        name: '',
        symbol: '',
        price: '',
        payment_id: '',
        sell: '',
        buy: '',
        miniorder: '',
        tchange: '',
        status: true
      }
      this.getSystemCurrencies()
    },
    async createNetworkCurrency() {
      if (this.isEditNetwork) {
        this.updateNetworkCurrency()
        return
      }

      this.isCreating = true

      const formData = new FormData()
      formData.append('barcode', this.newNetwork.barcode)
      formData.append('name', this.newNetwork.name)
      formData.append('wallet', this.newNetwork.wallet)

      const res = await cryptoApi.crypto().createNetwork(formData)
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.isCreating = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'New network created',
        status: true
      })
      this.isCreating = false
      this.createNetworkDialog = false
      this.newNetwork = {}
      this.getSystemCurrencies()
      this.getNetworks()
    },
    async updateNetworkCurrency() {
      this.isCreating = true

      const formData = new FormData()
      if (this.newNetwork.barcode) {
        formData.append('barcode', this.newNetwork.barcode)
      }
      formData.append('name', this.newNetwork.name)
      formData.append('wallet', this.newNetwork.wallet)

      const res = await cryptoApi
        .crypto()
        .updateNetwork(this.newNetwork._id, formData)
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.isCreating = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'Network updated',
        status: true
      })
      this.isCreating = false
      this.createNetworkDialog = false
      this.isEditNetwork = false
      this.newNetwork = {}
      this.getSystemCurrencies()
      this.getNetworks()
    },
    async deleteNetworkCurrency(network) {
      this.isCreating = true

      const res = await cryptoApi.crypto().deleteNetwork(network._id)
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.isCreating = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'Network deleted',
        status: true
      })
      this.isCreating = false
      this.getSystemCurrencies()
      this.getNetworks()
    },
    async actionOnNetwork(network, actionOnCurrency) {
      this.isCreating = true

      const formData = new FormData()

      formData.append('status', actionOnCurrency)

      const res = await cryptoApi.crypto().updateNetwork(network._id, formData)
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.isCreating = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'Network updated',
        status: true
      })
      this.isCreating = false
      this.createNetworkDialog = false
      this.getSystemCurrencies()
      this.getNetworks()
    },
    async addNetworkToCoin() {
      this.isCreating = true

      const res = await cryptoApi
        .crypto()
        .addNetworkToCoin(this.selectedCoin._id, this.selectedNetwork)

      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.isCreating = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'Network added to coin',
        status: true
      })
      this.isCreating = false
      this.addNetworkToCurrencyModal = false
      this.getSystemCurrencies()
      this.getNetworks()
    },
    async removeNetworkToCoin() {
      this.isCreating = true

      const removedNetwork = this.selectedCoin.networks.filter(
        (network) => !this.selectedCoinNetwork.includes(network._id)
      )

      const res = await cryptoApi
        .crypto()
        .removeNetworkFromCoin(this.selectedCoin._id, removedNetwork)

      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.isCreating = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'Network removed from coin',
        status: true
      })
      this.isCreating = false
      this.removeNetworkToCurrencyModal = false
      this.getSystemCurrencies()
      this.getNetworks()
    },
    async onSelectCoinForNetwork(coin, type) {
      if (type === 'add') {
        this.selectedCoin = coin
        this.addNetworkToCurrencyModal = true
      } else {
        this.selectedCoin = coin
        this.selectedCoinNetwork = [...coin.networks]
        this.removeNetworkToCurrencyModal = true
      }
    },
    async getNetworks() {
      const res = await cryptoApi.crypto().getNetworks()
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        return
      }
      this.networks = res.data.data.data
    },
    async getSystemCurrencies() {
      const res = await dataApi.data().getData('admin', {
        type: 'currency',
        params: {
          populate: 'networks'
        }
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message: 'Unable to get currency list, please check your Internet',
          error: true,
          status: true,
          time: 4500
        })
        setTimeout(() => {
          this.getSystemCurrencies()
        }, 5000)
        return
      }
      this.currencies = res.data.data.data.map((d) => ({
        ...d,
        hasAutoProcessing: d.hasAutoProcessing ? 'yes' : 'no'
      }))
    }
  }
}
</script>

<style></style>
