<template>
  <v-container>
    <div
      v-if="!checkIfPermission('view testimonial', admin.permissions)"
      class="w-100 h-100 d-flex justify-center align-center"
    >
      <h1 class="error--text">You don't have access to this page</h1>
    </div>
    <div v-else>
      <h1>Testimonials</h1>
      <v-dialog v-model="addTestimonialDialog" max-width="500">
        <v-card flat class="pa-5">
          <v-card-title>Create New Testimonial</v-card-title>
          <v-row>
            <v-col cols="12">
              <v-textarea label="Testimonial Message"></v-textarea>
              <v-select
                label="Status"
                :items="['Active', 'Inactive']"
              ></v-select>
              <v-btn block color="accent">Add Testimonial</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <v-dialog v-model="updateTestimonialDialog" max-width="500">
        <v-card flat class="pa-5">
          <v-card-title>Update Testimonial</v-card-title>
          <v-row>
            <v-col cols="12">
              <v-textarea label="Testimonial Message"></v-textarea>
              <v-select
                label="Status"
                :items="['Active', 'Inactive']"
              ></v-select>
              <v-btn block color="accent">Update Testimonial</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>

      <v-row>
        <v-col>
          <v-card flat>
            <v-card-title class="d-flex align-baseline">
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search..."
                single-line
                hide-details
              ></v-text-field>
              <!-- <v-btn
              color="accent"
              @click.stop="addTestimonialDialog = true"
              class="ml-4 font-weight-bold"
              >Add Testimonial</v-btn
            > -->
            </v-card-title>
            <v-data-table
              :items="arranged"
              :loading="arranged.length == 0"
              :headers="headers"
            >
              <template v-slot:[`item.image`]="{ item }">
                <v-avatar color="primary" size="45px">
                  <v-img
                    v-if="item.image != 'undefined' || !item.image"
                    :src="item.image"
                  ></v-img>
                  <span v-else>{{ `${item.fname[0]} ${item.lname[1]}` }}</span>
                </v-avatar>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <span>
                  {{ `${item.fname} ${item.lname}` }}
                </span>
              </template>
              <template v-slot:[`item.username`]="{ item }">
                <span class="font-weight-bold">{{ item.username }}</span>
                <br />
                <small class="">{{ item.date }}</small>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <span
                  :class="`text-capitalize rounded py-2 px-3 white--text ${
                    item.status ? 'success' : 'warning'
                  }`"
                  >{{ item.status ? 'Active' : 'Inactive' }}</span
                >
              </template>
              <template v-slot:[`item.details`]="{ item }">
                <div
                  style="font-size: 14px"
                  class="py-2"
                  v-html="item.details"
                ></div>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <!-- <v-list-item @click="updateTestimonialDialog = true" ripple>
                    <v-list-item-title>Update</v-list-item-title>
                  </v-list-item> -->
                    <v-list-item
                      v-if="!item.status"
                      @click="updateTestimonial(item, true)"
                      ripple
                    >
                      <v-list-item-title>Activate</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="item.status"
                      @click="updateTestimonial(item, false)"
                      ripple
                    >
                      <v-list-item-title>Deactivate</v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item
                    ripple
                    color="error"
                    @click="deleteTestimonialDialog = true"
                  >
                    <v-list-item-title class="error-text"
                      >Delete</v-list-item-title
                    >
                  </v-list-item> -->
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import userApi from '../../../api/admin/userManagement'

export default {
  data: () => ({
    search: '',
    addTestimonialDialog: false,
    updateTestimonialDialog: false,
    deleteTestimonialDialog: false,
    headers: [
      {
        text: 'Image',
        value: 'image'
      },
      {
        text: 'Username',
        value: 'username',
        sortable: false
      },
      {
        value: 'name',
        text: 'Full Name'
      },
      {
        value: 'details',
        text: 'Testimonials'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        text: '',
        value: 'action'
      }
    ],
    testimonials: []
  }),
  created() {
    this.getTestimonials()
  },
  computed: {
    arranged() {
      if (this.testimonials.length !== 0) {
        return this.testimonials.map((testimonial) => ({
          image: testimonial.image,
          fname: testimonial.user ? testimonial.user.fname : '',
          lname: testimonial.user ? testimonial.user.lname : '',
          id: testimonial._id,
          username: testimonial.user.username,
          date: new Date(testimonial.createdAt).toDateString(),
          status: testimonial.status,
          details: testimonial.details
        }))
      }
      return []
    },
    ...mapState({
      admin: (state) => state.admin.admin
    })
  },
  methods: {
    async getTestimonials() {
      const res = await userApi.users().getTestimonials()
      this.testimonials = res.data.data.data
    },
    async updateTestimonial(testimonial, status) {
      const res = await userApi
        .users()
        .updateTestimonialStatus(testimonial.id, { status })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        return
      }
      this.$store.dispatch('alert', {
        message: 'Update successful',

        status: true
      })
      this.getTestimonials()
    }
  }
}
</script>

<style></style>
