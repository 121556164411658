import Settings from '@/views/admin/settings.vue'
import SystemSettings from '@/views/admin/settings/SystemSettings.vue'
import BankAccount from '@/views/admin/settings/BankAccount.vue'
import SystemCurrency from '../../views/admin/settings/SystemCurrency.vue'
import SystemCurrencyRate from '../../views/admin/settings/SystemCurrencyRate.vue'
import Giftcards from '../../views/admin/settings/Giftcards.vue'
import EditGiftcard from '../../views/admin/settings/giftcard/EditGiftcard.vue'
import CreateGiftcard from '../../views/admin/settings/giftcard/CreateGiftcard.vue'
import Airtime from '../../views/admin/settings/Airtime.vue'
import WithdrawalMethod from '../../views/admin/settings/WithdrawalMethods.vue'
import Trustee from '../../views/admin/settings/trustee.vue'
import PaymentGateway from '../../views/admin/settings/PaymentGatways.vue'
import RankAndReward from '../../views/admin/settings/RankAndReward.vue'

const settingRoutes = {
  path: '/yurd2m/yurd2m/settings',
  component: Settings,
  name: 'SettingsGroup',
  meta: {
    title: 'Settings'
  },
  children: [
    {
      path: '/yurd2m/yurd2m/settings/system-settings',
      component: SystemSettings,
      name: 'SystemSettings',
      meta: {
        title: 'System Settings'
      }
    },
    {
      path: '/yurd2m/yurd2m/settings/payment-gateway',
      component: PaymentGateway,
      name: 'PaymentGateway',
      meta: {
        title: 'Payment Gateways'
      }
    },
    {
      path: '/yurd2m/yurd2m/settings/bank-account',
      component: BankAccount,
      name: 'BankAccountSettings',
      meta: {
        title: 'Bank Account Settings'
      }
    },
    {
      path: '/yurd2m/yurd2m/settings/withdrawal-methods',
      component: WithdrawalMethod,
      name: 'WithdrawalMethod',
      meta: {
        title: 'Withdrawal Methods'
      }
    },
    {
      path: '/yurd2m/yurd2m/settings/airtime',
      component: Airtime,
      name: 'AirtimeSettings',
      meta: {
        title: 'Airtime'
      }
    },
    {
      path: '/yurd2m/yurd2m/settings/system-currencies',
      component: SystemCurrency,
      name: 'SystemCurrencies',
      meta: {
        title: 'System Currencies'
      }
    },
    {
      path: '/yurd2m/yurd2m/settings/system-currencies-rate',
      component: SystemCurrencyRate,
      name: 'SystemCurrenciesRate',
      meta: {
        title: 'System Currencies Rate'
      }
    },
    {
      path: '/yurd2m/yurd2m/settings/trustees',
      component: Trustee,
      name: 'Trustees',
      meta: {
        title: 'Trustees'
      }
    },
    {
      path: '/yurd2m/yurd2m/settings/giftcard',
      component: Giftcards,
      name: 'GifcardsAdmin',
      meta: {
        title: 'Giftcards'
      }
    },
    {
      path: '/yurd2m/yurd2m/settings/giftcard/edit/:cardId',
      component: EditGiftcard,
      name: 'GifcardsAdminEdit',
      meta: {
        title: 'Edit Giftcards'
      }
    },
    {
      path: '/yurd2m/yurd2m/settings/giftcard/new',
      component: CreateGiftcard,
      name: 'GifcardsAdminCreate',
      meta: {
        title: 'Edit Giftcards'
      }
    },
    {
      path: '/yurd2m/yurd2m/settings/rank-rewards',
      component: RankAndReward,
      name: 'RankAndReward',
      meta: {
        title: 'Rank & Rewards'
      }
    }
  ]
}

export default settingRoutes
