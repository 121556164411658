import axios from 'axios'
import Store from '../store/index'

import { errorHandler, successHandler, generateQuery } from './helpers/handler'
import { BASEURL } from './const'
import adminHttp from './admin/http'

const URL = `${BASEURL}/data`

export default {
  data() {
    return {
      /**
       * Get Any Data
       *
       * @param {String} requester account making the request (user/admin)
       * @param {Object} param1 Object type and to be populated field
       */
      getData: async (
        requester = 'user',
        { type, limit = 0, populate = [], params = {} }
      ) => {
        let endpoint = `${URL}/${type}${generateQuery(populate, limit)}`
        let req

        if (requester === 'user') {
          req = axios
        } else {
          endpoint = `/data/${type}${generateQuery(populate, limit)}`
          req = adminHttp
        }

        try {
          const res = await req.get(endpoint, {
            params,
            headers: {
              ...Store.getters[
                `${
                  requester === 'user'
                    ? 'user/getRequestHeaders'
                    : 'admin/getRequestHeaders'
                }`
              ].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      /**
       *Get data of a type
       *
       * @param {String} requester account making the request (user/admin)
       * @param {*} param1 type, typeId, fields to be populated
       */
      getSingleData: async (requester = 'user', { type, params, typeId }) => {
        let endpoint = `${URL}/${type}/${typeId}`
        let req

        if (requester === 'user') {
          req = axios
        } else {
          endpoint = `/data/${type}/${typeId}`
          req = adminHttp
        }
        try {
          const res = await req.get(endpoint, {
            params,
            headers: {
              ...Store.getters[
                `${
                  requester === 'user'
                    ? 'user/getRequestHeaders'
                    : 'admin/getRequestHeaders'
                }`
              ].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      dashboardInfo: async (params) => {
        const endpoint = `/data/site-info`
        try {
          const res = await adminHttp.get(endpoint, {
            params,
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })

          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      userStats: async (params) => {
        const endpoint = `/data/admin/user-trx-stats`
        try {
          const res = await adminHttp.get(endpoint, {
            params,
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })

          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      cashbackStats: async (params) => {
        const endpoint = `/data/admin/cashback-stats`
        try {
          const res = await adminHttp.get(endpoint, {
            params,
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })

          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      createSystemData: async (type, data, headers = {}) => {
        const endpoint = `/data/${type}`
        try {
          const res = await adminHttp.post(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers,
              ...headers
            }
          })
          return successHandler(res)
        } catch (error) {
          errorHandler(error)
        }
      },
      editSystemData: async ({ type, typeId }, data, headers = {}) => {
        const endpoint = `/data/${type}/${typeId}`

        try {
          const res = await adminHttp.put(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers,
              ...headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      deleteSystemData: async ({ type, typeId }) => {
        const endpoint = `/data/${type}/${typeId}`
        try {
          const res = await adminHttp.delete(endpoint, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      siteSettings: async (requester = 'admin') => {
        let endpoint = `${URL}/settings`
        let req

        if (requester === 'user') {
          req = axios
        } else {
          endpoint = `/data/settings`
          req = adminHttp
        }
        try {
          const res = await req.get(endpoint, {
            headers: {
              ...Store.getters[
                `${
                  requester === 'user'
                    ? 'user/getRequestHeaders'
                    : 'admin/getRequestHeaders'
                }`
              ].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      countrySetting: async (requester = 'admin') => {
        let endpoint = `${URL}/country-settings`

        let req

        if (requester === 'user') {
          req = axios
        } else {
          endpoint = `/data/country-settings`
          req = adminHttp
        }
        try {
          const res = await req.get(endpoint, {
            headers: {
              ...Store.getters[
                `${
                  requester === 'user'
                    ? 'user/getRequestHeaders'
                    : 'admin/getRequestHeaders'
                }`
              ].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      updateSite: async (data) => {
        const endpoint = `/data/settings`
        try {
          const res = await adminHttp.put(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      updateCountrySetting: async (data) => {
        const endpoint = `/data/country-settings/${data._id}`
        try {
          const res = await adminHttp.put(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      /**
       * Get Leaderboards
       *
       * Type - sale/purchase
       * Category - coin/giftcard
       * period - day/year/month
       *
       * @param {String} requester account making the request (user/admin)
       * @param {*} param1 type, period, category
       */
      getLeaderboard: async ({
        period,
        category,
        type,
        requester = 'user',
        ...params
      }) => {
        let endpoint = `${URL}/${requester}/leaderboard`

        let req

        if (requester === 'user') {
          req = axios
        } else {
          endpoint = `/data/${requester}/leaderboard`
          req = adminHttp
        }
        try {
          const res = await req.get(endpoint, {
            params: {
              period,
              category,
              type,
              ...params
            },
            headers: {
              ...Store.getters[
                `${
                  requester === 'user'
                    ? 'user/getRequestHeaders'
                    : 'admin/getRequestHeaders'
                }`
              ].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
