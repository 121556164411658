<template>
  <v-container>
    <v-overlay :value="uploadingImage" opacity="0.8">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <v-toolbar flat outlined>
      <h2>Create Giftcard</h2>
      <v-spacer></v-spacer>
      <v-btn @click="$router.go(-1)" color="primary" text>
        <v-icon left> mdi-arrow-left</v-icon> Back</v-btn
      >
    </v-toolbar>
    <v-sheet class="pt-4">
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field filled v-model="card.name" label="Card Name">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-file-input
              :loading="sendingImage"
              filled
              v-model="card.imageFile"
              label="Card Image"
            >
            </v-file-input>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <div class="my-4">
          <p>
            Select a country and a currency, click on <b>Add Card Options</b> to
            add other card details
          </p>
          <v-row>
            <v-col cols="12" md="6">
              <v-autocomplete
                filled
                v-model="temp.country"
                :loading="countries.length === 0"
                :items="countries"
                item-text="name"
                item-value="_id"
                label="Select County"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                filled
                v-model="temp.currency"
                :loading="currencies.length === 0"
                :items="currencies"
                item-text="name"
                item-value="_id"
                label="Select Currency"
              >
                <template v-slot:item="{ item }">
                  <span> {{ item.name }} | {{ item.symbol }} </span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </div>
        <div class="pb-5">
          <div class="d-flex justify-end align-center">
            <v-btn
              :disabled="temp.country === '' || temp.currency === ''"
              @click="addCardOption"
              depressed
              text
              color="accent"
            >
              <v-icon>mdi-plus</v-icon>
              Add Card Options
            </v-btn>
            <v-btn
              @click="
                () => {
                  card.options.pop()
                }
              "
              v-if="card.options.length > 0"
              depressed
              text
              color="error"
            >
              Undo
            </v-btn>
          </div>
          <div class="extended-tabs">
            <v-tabs @change="changeCountryTab" v-model="tabs">
              <v-tab v-for="(option, i) in card.options" :key="i"
                >{{ countries.find((c) => c._id === option.country).name }} |
                {{ currencies.find((c) => c._id === option.currency).symbol }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabs">
              <v-tab-item v-for="(option, i) in card.options" :key="i">
                <v-container>
                  <h5>
                    County:
                    {{ countries.find((c) => c._id === option.country).name }}
                  </h5>
                  <h5>
                    Currency:
                    {{ currencies.find((c) => c._id === option.currency).name }}
                  </h5>
                  <p class="my-2">
                    Select a card type, you can add more one by clicking on add
                    more
                  </p>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        v-model="temp.type"
                        :items="giftcardTypes"
                        :loading="giftcardTypes.length === 0"
                        filled
                        item-value="_id"
                        item-text="name"
                        label="Select card type"
                        clearable
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-avatar tile color="accent">
                            <v-img :src="item.image" alt="item.name"></v-img>
                          </v-list-item-avatar>
                          <v-list-item-content
                            v-text="item.name"
                          ></v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Minimum Sell"
                        v-model="temp.minimumSell"
                        filled
                        suffix="$"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Default Naira Rate"
                        hint="Default Naira rate to use for giftcard type"
                        v-model="temp.defaultValue"
                        filled
                        suffix="N"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <div class="d-flex justify-end align-center">
                    <v-btn
                      :disabled="
                        temp.type === '' ||
                        temp.minimumSell === '' ||
                        temp.defaultValue === ''
                      "
                      @click="addCardOptionType(i)"
                      depressed
                      text
                      color="accent"
                    >
                      <v-icon>mdi-plus</v-icon>
                      Add Card Type Options
                    </v-btn>
                    <v-btn
                      @click="
                        () => {
                          card.options[i].cardTypes.pop()
                        }
                      "
                      v-if="card.options[i].cardTypes.length > 0"
                      depressed
                      text
                      color="error"
                    >
                      Undo
                    </v-btn>
                  </div>
                  <div class="extended-tab">
                    <v-tabs v-model="tabsCardTypes[i]">
                      <v-tab
                        v-for="option in card.options[i].cardTypes"
                        :key="option._id"
                        >{{ option.cardType }}
                      </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tabsCardTypes[i]">
                      <v-tab-item
                        v-for="(option, a) in card.options[i].cardTypes"
                        :key="a"
                      >
                        <v-container>
                          <h5>Card Type: {{ option.cardType }}</h5>

                          <div class="mt-4">
                            <div class="range">
                              <div>
                                <v-row>
                                  <v-col
                                    cols="12"
                                    md="6"
                                    style="border-right: 1px solid #333"
                                    class="border-r"
                                  >
                                    <p>Add Rate</p>
                                    <div
                                      v-for="(rate, r) in card.options[i]
                                        .cardTypes[a].options"
                                      :key="r"
                                    >
                                      <v-row
                                        v-if="
                                          !card.options[i].cardTypes[a].options[
                                            r
                                          ].default &&
                                          card.options[i].cardTypes[a].options[
                                            r
                                          ].isVariable
                                        "
                                      >
                                        <v-col cols="4">
                                          <v-text-field
                                            :prefix="prefixToUse"
                                            v-model="
                                              card.options[i].cardTypes[a]
                                                .options[r].minimum
                                            "
                                            label="Minimum price"
                                          ></v-text-field>
                                        </v-col>

                                        <v-col cols="4">
                                          <v-text-field
                                            :prefix="prefixToUse"
                                            v-model="
                                              card.options[i].cardTypes[a]
                                                .options[r].maximum
                                            "
                                            label="Maximum price"
                                          ></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                          <v-text-field
                                            prefix="₦"
                                            label="Naira Rate"
                                            v-model="
                                              card.options[i].cardTypes[a]
                                                .options[r].nairaRate
                                            "
                                          ></v-text-field>
                                        </v-col>
                                      </v-row>
                                    </div>
                                    <div
                                      class="d-flex flex-col justify-end align-end"
                                    >
                                      <p
                                        @click="addMorePrices(i, a)"
                                        class="mx-2 cursor-pointer font-weight-medium"
                                      >
                                        Add more
                                      </p>
                                    </div>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <p>Add Prices</p>
                                    <div
                                      v-for="(rate, r) in card.options[i]
                                        .cardTypes[a].options"
                                      :key="r"
                                    >
                                      <v-row
                                        v-if="
                                          !card.options[i].cardTypes[a].options[
                                            r
                                          ].default &&
                                          !card.options[i].cardTypes[a].options[
                                            r
                                          ].isVariable
                                        "
                                      >
                                        <v-col md="6" cols="12">
                                          <v-text-field
                                            :prefix="prefixToUse"
                                            v-model="
                                              card.options[i].cardTypes[a]
                                                .options[r].value
                                            "
                                            label="Value"
                                          ></v-text-field>
                                        </v-col>
                                        <v-col md="6" cols="12">
                                          <v-text-field
                                            prefix="₦"
                                            v-model="
                                              card.options[i].cardTypes[a]
                                                .options[r].nairaRate
                                            "
                                            label="Rate"
                                          ></v-text-field>
                                        </v-col>
                                      </v-row>
                                    </div>
                                    <div
                                      class="d-flex flex-col justify-end align-end"
                                    >
                                      <p
                                        @click="addMorePricesFixed(i, a)"
                                        class="mx-2 cursor-pointer font-weight-medium"
                                      >
                                        Add more
                                      </p>
                                    </div>
                                  </v-col>
                                </v-row>
                              </div>
                            </div>
                            <div
                              class="d-flex flex-col mt-5 justify-center align-end"
                            >
                              <p
                                @click="
                                  () => {
                                    card.options[i].cardTypes[a].options.pop()
                                  }
                                "
                                v-if="
                                  card.options[i].cardTypes[a].options.length >
                                  0
                                "
                                class="mx-2 error--text cursor-pointer font-weight-medium"
                              >
                                Undo
                              </p>
                            </div>
                          </div>
                        </v-container>
                      </v-tab-item>
                    </v-tabs-items>
                  </div>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </div>
        <v-btn @click="createGiftcard" class="ma-4" color="primary">
          Save
        </v-btn>
      </v-container>
    </v-sheet>
  </v-container>
</template>

<script>
import axios from 'axios'
import giftcard from '../../../../api/admin/giftcard'
import dataApi from '../../../../api/systemData'

export default {
  data() {
    return {
      tabs: null,
      tabsCardTypes: [],
      currencies: [],
      giftcardTypes: [],
      countries: [],
      temp: {
        country: '',
        currency: '',
        type: '',
        minimumSell: '',
        defaultValue: ''
      },
      cloudName: 'snappyexchange',
      preset: 'rwy1auea',
      tags: 'kyc',
      sendingImage: false,
      uploadingImage: false,
      prefixToUse: '',
      card: {
        name: '',
        image: '',
        imageFile: null,
        options: []
      }
    }
  },
  created() {
    this.getCurrencies()
    this.getCounties()
    this.getGiftCardTypes()
  },
  watch: {
    'card.options': function (val) {
      this.tabs = val.length - 1
    }
  },
  computed: {},
  methods: {
    changeCountryTab() {
      this.prefixToUse =
        this.currencies.find(
          (c) => c._id === this.card.options[this.tabs].currency
        ).symbol || ''
    },
    addMorePrices(i, a) {
      this.card.options[i].cardTypes[a].options.push({
        isVariable: true,
        default: false,
        value: 0,
        minimum: 0,
        maximum: 0,
        nairaRate: 0
      })
    },
    addMorePricesFixed(i, a) {
      this.card.options[i].cardTypes[a].options.push({
        isVariable: false,
        default: false,
        value: 0
      })
    },
    addCardOption() {
      this.card.options.push({
        country: this.temp.country,
        currency: this.temp.currency,
        cardTypes: []
      })
      this.temp.country = ''
      this.temp.currency = ''
    },
    addCardOptionType(i) {
      const cardType = this.giftcardTypes.find(
        (type) => type._id === this.temp.type
      )

      this.prefixToUse =
        this.currencies.find(
          (c) => c._id === this.card.options[this.tabs].currency
        ).symbol || ''

      this.card.options[i].cardTypes.push({
        cardType: cardType.name,
        image: cardType.image,
        description: cardType.details || '',
        minimumSell: this.temp.minimumSell || 0,
        options: [
          {
            isVariable: true,
            default: true,
            value: this.temp.defaultValue,
            nairaRate: this.temp.defaultValue
          }
        ]
      })
      this.temp.type = ''
      this.temp.minimumSell = ''
      this.temp.defaultValue = ''
    },
    async getCurrencies() {
      const res = await dataApi
        .data()
        .getData('admin', { type: 'systemcurrency' })
      this.currencies = res.data.data.data
    },
    async getGiftCardTypes() {
      const res = await dataApi
        .data()
        .getData('admin', { type: 'giftcardtype' })
      this.giftcardTypes = res.data.data.data.filter((d) => d.status)
    },
    async getCounties() {
      const res = await dataApi.data().getData('admin', { type: 'country' })
      this.countries = res.data.data.data
        .filter((country) => country.name)
        .reverse()
    },

    async createGiftcard() {
      this.uploadingImage = true
      const res = await giftcard.giftcards().create(this.card)
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.uploadingImage = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'Giftcard created successfully',
        status: true,
        error: false
      })
      this.uploadImage(res.data.data._id)
      this.uploadingImage = false
      setTimeout(() => {
        this.$router.push('/yurd2m/yurd2m/settings/giftcard')
      }, 3500)
    },

    async uploadImage(cardId) {
      this.uploadingImage = true
      const res = await giftcard
        .giftcards()
        .addImageToGiftcard({ cardId, image: this.card.imageFile })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.uploadingImage = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'Upload Successful',
        status: true,
        error: false
      })
      this.uploadingImage = false
      setTimeout(() => {
        this.$router.push('/yurd2m/yurd2m/settings/giftcard')
      }, 3500)
    },
    previewImage(e) {
      if (e !== undefined) {
        this.imagePreview = URL.createObjectURL(e)
      } else {
        this.newCard.image = ''
      }
    },
    uploadImageCloud() {
      if (!this.card.imageFile) return
      this.sendingImage = true
      const formData = new FormData()
      formData.append('file', this.card.imageFile)
      formData.append('upload_preset', this.preset)
      formData.append('tags', this.tags)

      axios
        .post(
          `https://api.cloudinary.com/v1_1/${this.cloudName}/upload`,
          formData
        )
        .then((res) => {
          this.card.image = res.data.secure_url
          this.sendingImage = false
        })
        .catch((err) => {
          console.log(err)
          this.$store.dispatch('alert', {
            message: 'INTERNET ERROR, PLEASE TRY AGAIN',
            error: true,
            status: true
          })

          this.sendingImage = false
        })
    }
  }
}
</script>

<style></style>
