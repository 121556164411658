import axios from 'axios'
import Store from '../store'
import { errorHandler, successHandler } from './helpers/handler'
import { BASEURL } from './const'
import adminHttp from './admin/http'

const URL = `${BASEURL}/promocodes`

export default {
  promoCodes() {
    return {
      createCode: async (data) => {
        const endpoint = `/promocodes`
        try {
          const res = await adminHttp.post(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getCodes: async ({ param, requester = 'user' }) => {
        let endpoint
        let req

        if (requester === 'user') {
          endpoint = `${URL}/user`
          req = axios
        } else {
          endpoint = `/promocodes`
          req = adminHttp
        }

        try {
          const res = await req.get(endpoint, {
            params: param,
            headers: {
              ...Store.getters[
                `${
                  requester === 'user'
                    ? 'user/getRequestHeaders'
                    : 'admin/getRequestHeaders'
                }`
              ].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getCode: async ({ param, id, requester }) => {
        let endpoint = `${URL}/${id}`
        let req

        if (requester === 'user') {
          req = axios
        } else {
          req = adminHttp
          endpoint = `/promocodes/${id}`
        }

        try {
          const res = await req.get(endpoint, {
            params: { param },
            headers: {
              ...Store.getters[
                `${
                  requester === 'user'
                    ? 'user/getRequestHeaders'
                    : 'admin/getRequestHeaders'
                }`
              ].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      updateCode: async (data) => {
        const endpoint = `promocodes/${data._id || data.id}`
        try {
          const res = await adminHttp.put(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      deleteCode: async (data) => {
        const endpoint = `promocodes/${data._id}`
        try {
          const res = await adminHttp.delete(endpoint, {
            data,
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getCodeUsage: async ({ params, requester = 'user' }) => {
        let endpoint = `${URL}/usage`
        let req

        if (requester === 'user') {
          endpoint = `${URL}/usage/user`
          req = axios
        } else {
          endpoint = `/promocodes/usage`
          req = adminHttp
        }

        try {
          const res = await req.get(endpoint, {
            params,
            headers: {
              ...Store.getters[
                `${
                  requester === 'user'
                    ? 'user/getRequestHeaders'
                    : 'admin/getRequestHeaders'
                }`
              ].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getCodeUsageById: async ({ param, id }) => {
        const endpoint = `${URL}/usage/${id}`
        try {
          const res = await axios.get(endpoint, {
            params: { param },
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      calculateCodeBonus: async (data) => {
        const endpoint = `${URL}/calculate-bonus`
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
