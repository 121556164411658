import Store from '../../store/index'

import { errorHandler, successHandler } from '../helpers/handler'
import adminHttp from './http'

const axios = adminHttp
const URL = `withdrawal`

export default {
  logs() {
    return {
      getAll: async ({ ...rest }) => {
        const endpoint = `${URL}/logs`

        try {
          const res = await axios.get(endpoint, {
            params: { ...rest },
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })

          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getSingle: async (logId) => {
        const endpoint = `${URL}/logs/${logId}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      updateWithdraw: async (logId, { status, message }) => {
        const endpoint = `${URL}/logs/${logId}`
        try {
          const res = await axios.put(
            endpoint,
            { status, message },
            {
              headers: {
                ...Store.getters['admin/getRequestHeaders'].headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
