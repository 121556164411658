<template>
  <v-container>
    <v-overlay :value="loadingData" opacity="0.8">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="deleteCardOptionDialog" max-width="700" persistent>
      <v-card flat color="error">
        <v-container>
          <v-card-title class="white--text">Remove Card Option</v-card-title>
          <v-card-text>
            <p class="body-2 white--text font-weight-medium">
              You are about to remove this card option, do you want to proceed?
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed @click="deleteCardOptionDialog = false">NO</v-btn>
            <v-btn depressed @click="onDeleteCardOption">Yes</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deletePriceDialog" max-width="700" persistent>
      <v-card flat color="error">
        <v-container>
          <v-card-title class="white--text">Remove Price Option</v-card-title>
          <v-card-text>
            <p class="body-2 white--text font-weight-medium">
              You are about to remove this option, do you want to proceed?
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed @click="deletePriceDialog = false">NO</v-btn>
            <v-btn depressed @click="onDeletePrice">Yes</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteRateDialog" max-width="700" persistent>
      <v-card flat color="error">
        <v-container>
          <v-card-title class="white--text">Remove Rate Option</v-card-title>
          <v-card-text>
            <p class="body-2 white--text font-weight-medium">
              You are about to remove this option, do you want to proceed?
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed @click="deleteRateDialog = false">NO</v-btn>
            <v-btn depressed @click="onDeleteRate">Yes</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <v-toolbar flat outlined>
      <h2>Edit {{ card ? card.name : '' }}</h2>
      <v-spacer></v-spacer>
      <v-btn @click="$router.go(-1)" color="primary" text>
        <v-icon left> mdi-arrow-left</v-icon> Back</v-btn
      >
    </v-toolbar>
    <v-sheet class="pt-4">
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field filled v-model="card.name" label="Card Name">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-file-input
              @change="uploadImage"
              :loading="sendingImage"
              filled
              v-model="card.imageFile"
              label="Card Image"
            >
            </v-file-input>
          </v-col>
        </v-row>
        <small class="d-block mb-2"
          >Update just the name and/or Image of this Giftcard</small
        >
        <v-btn @click="updateNameOrImage" color="primary" class="mb-4">
          Update
        </v-btn>
        <v-divider></v-divider>
        <div class="my-4">
          <p>
            Select a country and a currency, click on <b>Add Card Options</b> to
            add other card details
          </p>
          <v-row>
            <v-col cols="12" md="6">
              <v-autocomplete
                filled
                v-model="temp.country"
                :loading="countries.length === 0"
                :items="countries"
                item-text="name"
                item-value="_id"
                label="Select County"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                filled
                v-model="temp.currency"
                :loading="currencies.length === 0"
                :items="currencies"
                item-text="name"
                item-value="_id"
                label="Select Currency"
              >
                <template v-slot:item="{ item }">
                  <span> {{ item.name }} | {{ item.symbol }} </span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </div>
        <div class="pb-5">
          <div class="d-flex justify-end align-center">
            <v-btn
              :disabled="temp.country === '' || temp.currency === ''"
              @click="addCardOption"
              depressed
              text
              color="accent"
            >
              <v-icon>mdi-plus</v-icon>
              Add Card Options
            </v-btn>
            <v-btn
              @click="
                () => {
                  card.options.pop()
                }
              "
              v-if="card.options.length > 0"
              depressed
              text
              color="error"
            >
              Undo
            </v-btn>
          </div>
          <div class="extended-tabs">
            <v-tabs @change="changeCountryTab" v-model="tabs">
              <v-tab v-for="(option, i) in card.options" :key="i"
                >{{ countries.find((c) => c._id === option.country).name }} |
                {{ currencies.find((c) => c._id === option.currency).symbol }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabs">
              <v-tab-item v-for="(option, i) in card.options" :key="i">
                <v-container>
                  <div class="d-flex justify-space-between align-center">
                    <div>
                      <h5>
                        County:
                        {{
                          countries.find((c) => c._id === option.country).name
                        }}
                      </h5>
                      <h5>
                        Currency:
                        {{
                          currencies.find((c) => c._id === option.currency).name
                        }}
                      </h5>
                    </div>
                    <div>
                      <v-btn color="red" small @click="removeCardOption(i)"
                        >Remove</v-btn
                      >
                    </div>
                  </div>

                  <p class="my-2">
                    Select a card type, you can add more one by clicking on add
                    more
                  </p>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        v-model="temp.type"
                        :items="giftcardTypes"
                        :loading="giftcardTypes.length === 0"
                        filled
                        item-value="_id"
                        item-text="name"
                        label="Select card type"
                        clearable
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-avatar tile color="accent">
                            <v-img :src="item.image" alt="item.name"></v-img>
                          </v-list-item-avatar>
                          <v-list-item-content
                            v-text="item.name"
                          ></v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Minimum Sell"
                        v-model="temp.minimumSell"
                        filled
                        suffix="$"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Default Naira Rate"
                        hint="Default Naira rate to use for giftcard type"
                        v-model="temp.defaultValue"
                        filled
                        suffix="N"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <div class="d-flex justify-end align-center">
                    <v-btn
                      :disabled="
                        temp.type === '' ||
                        temp.minimumSell === '' ||
                        temp.defaultValue === ''
                      "
                      @click="addCardOptionType(i)"
                      depressed
                      text
                      color="accent"
                    >
                      <v-icon>mdi-plus</v-icon>
                      Add Card Type Options
                    </v-btn>
                    <v-btn
                      @click="
                        () => {
                          card.options[i].cardTypes.pop()
                        }
                      "
                      v-if="card.options[i].cardTypes.length > 0"
                      depressed
                      text
                      color="error"
                    >
                      Undo
                    </v-btn>
                  </div>
                  <div class="extended-tab">
                    <v-tabs
                      @change="tabsCardTypeChange"
                      v-model="tabsCardTypes[i]"
                    >
                      <v-tab
                        v-for="option in card.options[i].cardTypes"
                        :key="option._id"
                        >{{ option.cardType }}
                      </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tabsCardTypes[i]">
                      <v-tab-item
                        v-for="(option, a) in card.options[i].cardTypes"
                        :key="a"
                      >
                        <v-container>
                          <h5>Card Type: {{ option.cardType }}</h5>
                          <v-row>
                            <v-col cols="6">
                              <v-text-field
                                v-model="option.minimumSell"
                                label="Minimum Sell"
                                :error="option.minimumSell === ''"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-text-field
                                v-if="
                                  card.options[i].cardTypes[a].options.find(
                                    (c) => c.default === true
                                  ) !== undefined
                                "
                                v-model="
                                  card.options[i].cardTypes[a].options.find(
                                    (c) => c.default === true
                                  ).nairaRate
                                "
                                :error="
                                  card.options[i].cardTypes[a].options.find(
                                    (c) => c.default === true
                                  ).nairaRate === ''
                                "
                                label="Default Naira Rate"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <div class="mt-4">
                            <div class="range">
                              <div>
                                <v-row>
                                  <v-col
                                    cols="12"
                                    md="6"
                                    style="border-right: 1px solid #333"
                                    class="border-r"
                                  >
                                    <p>Add Rate Range</p>
                                    <div
                                      v-for="(rate, r) in card.options[i]
                                        .cardTypes[a].options"
                                      :key="r"
                                    >
                                      <v-row
                                        v-if="
                                          !card.options[i].cardTypes[a].options[
                                            r
                                          ].default &&
                                          card.options[i].cardTypes[a].options[
                                            r
                                          ].isVariable
                                        "
                                      >
                                        <v-col cols="3">
                                          <v-text-field
                                            :prefix="prefixToUse"
                                            v-model="
                                              card.options[i].cardTypes[a]
                                                .options[r].minimum
                                            "
                                            :error="
                                              card.options[i].cardTypes[a]
                                                .options[r].minimum === ''
                                            "
                                            label="Minimum price"
                                          ></v-text-field>
                                        </v-col>

                                        <v-col cols="3">
                                          <v-text-field
                                            :prefix="prefixToUse"
                                            v-model="
                                              card.options[i].cardTypes[a]
                                                .options[r].maximum
                                            "
                                            :error="
                                              card.options[i].cardTypes[a]
                                                .options[r].maximum === ''
                                            "
                                            label="Maximum price"
                                          ></v-text-field>
                                        </v-col>
                                        <v-col cols="3">
                                          <v-text-field
                                            prefix="₦"
                                            label="Naira Rate"
                                            v-model="
                                              card.options[i].cardTypes[a]
                                                .options[r].nairaRate
                                            "
                                            :error="
                                              card.options[i].cardTypes[a]
                                                .options[r].nairaRate === ''
                                            "
                                          ></v-text-field>
                                        </v-col>
                                        <v-col cols="3">
                                          <v-tooltip bottom>
                                            <template
                                              v-slot:activator="{ on, attrs }"
                                            >
                                              <v-icon
                                                @click="removePrice(i, a, r)"
                                                v-bind="attrs"
                                                v-on="on"
                                                >mdi-close</v-icon
                                              >
                                            </template>
                                            <span>Remove rate</span>
                                          </v-tooltip>
                                        </v-col>
                                      </v-row>
                                    </div>
                                    <div
                                      class="d-flex flex-col justify-end align-end"
                                    >
                                      <p
                                        @click="addMorePrices(i, a)"
                                        class="mx-2 cursor-pointer font-weight-medium"
                                      >
                                        Add more
                                      </p>
                                    </div>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <p>Add Fixed Prices</p>
                                    <div
                                      v-for="(rate, r) in card.options[i]
                                        .cardTypes[a].options"
                                      :key="r"
                                    >
                                      <v-row
                                        v-if="
                                          !card.options[i].cardTypes[a].options[
                                            r
                                          ].default &&
                                          !card.options[i].cardTypes[a].options[
                                            r
                                          ].isVariable
                                        "
                                      >
                                        <v-col md="5" cols="12">
                                          <v-text-field
                                            :prefix="prefixToUse"
                                            v-model="
                                              card.options[i].cardTypes[a]
                                                .options[r].value
                                            "
                                            label="Value"
                                            :error="
                                              card.options[i].cardTypes[a]
                                                .options[r].value === ''
                                            "
                                          ></v-text-field>
                                        </v-col>
                                        <v-col md="5" cols="12">
                                          <v-text-field
                                            prefix="₦"
                                            v-model="
                                              card.options[i].cardTypes[a]
                                                .options[r].nairaRate
                                            "
                                            :error="
                                              card.options[i].cardTypes[a]
                                                .options[r].nairaRate === ''
                                            "
                                            label="Rate"
                                          ></v-text-field>
                                        </v-col>
                                        <v-col cols="2">
                                          <v-tooltip bottom>
                                            <template
                                              v-slot:activator="{ on, attrs }"
                                            >
                                              <v-icon
                                                @click="
                                                  removePriceFixed(i, a, r)
                                                "
                                                v-bind="attrs"
                                                v-on="on"
                                                >mdi-close</v-icon
                                              >
                                            </template>
                                            <span>Remove prices</span>
                                          </v-tooltip>
                                        </v-col>
                                      </v-row>
                                    </div>
                                    <div
                                      class="d-flex flex-col justify-end align-end"
                                    >
                                      <p
                                        @click="addMorePricesFixed(i, a)"
                                        class="mx-2 cursor-pointer font-weight-medium"
                                      >
                                        Add more
                                      </p>
                                    </div>
                                  </v-col>
                                </v-row>
                              </div>
                            </div>
                          </div>
                        </v-container>
                      </v-tab-item>
                    </v-tabs-items>
                  </div>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </div>
        <v-btn @click="editGiftcard" class="ma-4" color="primary"> Save </v-btn>
      </v-container>
    </v-sheet>
  </v-container>
</template>

<script>
import axios from 'axios'
import giftcard from '../../../../api/admin/giftcard'
import dataApi from '../../../../api/systemData'

export default {
  data() {
    return {
      cardId: '',
      tabs: null,
      tabsCardTypes: [],
      currencies: [],
      giftcardTypes: [],
      tabsCardTypesObject: {},
      countries: [],
      temp: {
        country: '',
        currency: '',
        type: ''
      },
      cloudName: 'snappyexchange',
      preset: 'rwy1auea',
      tags: 'giftcard',
      sendingImage: false,
      uploadingImage: false,
      loadingData: false,
      prefixToUse: '',
      card: {
        name: '',
        image: '',
        imageFile: null,
        options: []
      },
      deleteCardOptionDialog: false,
      onDeleteCardOption: () => {},
      onDeletePrice: () => {},
      deletePriceDialog: false,
      onDeleteRate: () => {},
      deleteRateDialog: false
    }
  },
  created() {
    this.loadingData = true
    this.cardId = this.$route.params.cardId
    this.getCurrencies()
    this.getCounties()
    this.getGiftCardTypes()
    this.getCardData()
  },
  watch: {
    'card.options': function (val) {
      this.tabs = val.length - 1
    }
  },
  computed: {},

  methods: {
    removeCardOption(i) {
      this.deleteCardOptionDialog = true
      this.onDeleteCardOption = () => {
        this.card.options.splice(i, 1)
        this.deleteCardOptionDialog = false
      }
    },
    tabsCardTypeChange(n) {},
    changeCountryTab() {
      this.prefixToUse =
        this.currencies.find(
          (c) => c._id === this.card.options[this.tabs].currency
        ).symbol || ''
    },

    addMorePrices(i, a) {
      this.card.options[i].cardTypes[a].options.push({
        isVariable: true,
        default: false,
        value: 0,
        minimum: 0,
        maximum: 0,
        nairaRate: 0
      })
    },
    removePrice(i, a, r) {
      this.deletePriceDialog = true
      this.onDeletePrice = () => {
        this.card.options[i].cardTypes[a].options.splice(r, 1)
        this.deletePriceDialog = false
      }
    },
    addMorePricesFixed(i, a) {
      this.card.options[i].cardTypes[a].options.push({
        isVariable: false,
        default: false,
        value: 0
      })
    },
    removePriceFixed(i, a, r) {
      this.deleteRateDialog = true
      this.onDeleteRate = () => {
        this.card.options[i].cardTypes[a].options.splice(r, 1)
        this.deleteRateDialog = false
      }
    },
    addCardOption() {
      this.card.options.push({
        country: this.temp.country,
        currency: this.temp.currency,
        cardTypes: []
      })
      this.temp.country = ''
      this.temp.currency = ''
    },
    addCardOptionType(i) {
      const cardType = this.giftcardTypes.find(
        (type) => type._id === this.temp.type
      )

      this.card.options[i].cardTypes.push({
        cardType: cardType.name,
        image: cardType.image,
        description: cardType.details || '',
        minimumSell: this.temp.minimumSell || 0,
        options: [
          {
            isVariable: true,
            default: true,
            value: this.temp.defaultValue,
            nairaRate: this.temp.defaultValue
          }
        ]
      })

      this.temp.type = ''
      this.temp.minimumSell = ''
      this.temp.defaultValue = ''
    },
    async getCurrencies() {
      const res = await dataApi
        .data()
        .getData('admin', { type: 'systemcurrency' })
      this.currencies = res.data.data.data
    },
    async getGiftCardTypes() {
      const res = await dataApi
        .data()
        .getData('admin', { type: 'giftcardtype' })
      this.giftcardTypes = res.data.data.data.filter((d) => d.status)
    },
    async getCounties() {
      const res = await dataApi.data().getData('admin', { type: 'country' })
      this.countries = res.data.data.data
        .filter((country) => country.name)
        .reverse()
    },

    async updateNameOrImage() {
      this.loadingData = true

      const data = { ...this.card }

      delete data.options
      delete data.buy
      delete data.createdAt
      delete data.status
      delete data.updatedAt
      delete data.__v
      delete data._id

      const res = await giftcard.giftcards().edit({ data, cardId: this.cardId })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.loadingData = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'Upload Successful',
        status: true,
        error: false
      })
      this.loadingData = false
    },

    async editGiftcard() {
      this.loadingData = true
      const data = { ...this.card }

      delete data.name
      delete data.image
      delete data.buy
      delete data.createdAt
      delete data.status
      delete data.updatedAt
      delete data.__v
      delete data._id

      const res = await giftcard
        .giftcards()
        .edit({ data: this.card, cardId: this.cardId })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.loadingData = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'Upload Successful',
        status: true,
        error: false
      })
      this.loadingData = false
      setTimeout(() => {
        this.$router.push('/yurd2m/yurd2m/settings/giftcard')
      }, 3500)
    },

    async uploadImage() {
      this.uploadingImage = true
      console.log(this.imageFile)
      const res = await giftcard.giftcards().addImageToGiftcard({
        cardId: this.card._id,
        image: this.card.imageFile
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.uploadingImage = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'Upload Successful',
        status: true,
        error: false
      })
      this.uploadingImage = false
      setTimeout(() => {
        this.$router.push('/yurd2m/yurd2m/settings/giftcard')
      }, 3500)
    },
    async getCardData() {
      this.loadingData = true
      const res = await giftcard
        .giftcards()
        .getGiftCard({ cardId: this.cardId })

      this.card = res.data.data

      // this.prefixToUse =
      //   this.currencies.find(
      //     (c) => c._id === this.card.options[this.tabs].currency
      //   ).symbol || ''

      this.loadingData = false
    },
    previewImage(e) {
      if (e !== undefined) {
        this.imagePreview = URL.createObjectURL(e)
      } else {
        this.newCard.image = ''
      }
    },
    uploadImageCloud() {
      if (!this.card.imageFile) return
      this.sendingImage = true
      const formData = new FormData()
      formData.append('file', this.card.imageFile)
      formData.append('upload_preset', this.preset)
      formData.append('tags', this.tags)

      axios
        .post(
          `https://api.cloudinary.com/v1_1/${this.cloudName}/upload`,
          formData
        )
        .then((res) => {
          this.card.image = res.data.secure_url
          this.sendingImage = false
        })
        .catch((err) => {
          console.log(err)
          this.$store.dispatch('alert', {
            message: 'INTERNET ERROR, PLEASE TRY AGAIN',
            error: true,
            status: true
          })

          this.sendingImage = false
        })
    }
  }
}
</script>

<style></style>
