<template>
  <div v-if="imageLink !== undefined" class="flex flex-col">
    <div v-for="image in images" :key="image" class="flex-1 w-full">
      <img :src="image" alt="Image" class="w-full h-full" />
    </div>
  </div>
  <div v-else>
    <div class="center">
      <video width="65%" height="65%" controls>
        <source :src="videoLink" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageLink: '',
      images: [],
      videoLink: ''
    }
  },
  mounted() {
    this.imageLink = this.$route.query.imageLink
    this.videoLink = this.$route.query.videoLink

    const images = this.imageLink.split(',')
    this.images = images
  }
}
</script>

<style>
.image {
  width: 100%;
  height: 100%;
}

.center {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: burlywood;
}
</style>
