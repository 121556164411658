import PromoCodes from '../../views/admin/promoCode.vue'

const promoCodesRoutes = {
  path: '/yurd2m/yurd2m/promo-codes',
  redirect: '/yurd2m/yurd2m/promo-codes/promo-codes',
  component: PromoCodes,
  name: 'PromoCodesMain',
  meta: {
    title: 'PromoCodes'
  },
  children: [
    {
      path: '/yurd2m/yurd2m/promo-codes/promo-codes',
      component: () => import('../../views/admin/promoCodes/PromoCodes.vue'),
      name: 'PromoCodes',
      meta: {
        title: 'Promo Codes'
      }
    },
    {
      path: '/yurd2m/yurd2m/promo-codes/promo-code-history',
      component: () =>
        import('../../views/admin/promoCodes/promoCodeHistory.vue'),
      name: 'PromoCodesHistory',
      meta: {
        title: 'Promo Codes History'
      }
    }
  ]
}

export default promoCodesRoutes
