import Store from '../../store/index'

import {
  errorHandler,
  successHandler,
  generateNoOptionsQuery
} from '../helpers/handler'

import adminHttp from './http'
const axios = adminHttp

const URL = `transactions`

export default {
  transactions() {
    return {
      /**
       *
       * @param {Object} param0 type (sales, purchase, giftcard) | status (declined, pending, approved)
       */
      getAll: async ({
        limit,
        offset = 0,
        type,
        category = '',
        status,
        populate = [],
        keyword = '',
        params = {}
      }) => {
        const endpoint = `${URL}${generateNoOptionsQuery(
          populate,
          limit
        )}&type=${type}&status=${status}&category=${category}&offset=${offset}`

        const genParams = keyword ? { ...params, keyword } : params

        try {
          const res = await axios.get(endpoint, {
            params: genParams,
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })

          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      updateTransaction: async ({ trxId, status, details, image }) => {
        const endpoint = `${URL}/${trxId}/status`
        const formData = new FormData()

        formData.append('reason', details)
        formData.append('status', status)

        if (Array.isArray(image)) {
          Array.from(image).forEach((file) => {
            formData.append('images', file)
          })
        }

        try {
          const res = await axios.post(endpoint, formData, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers,
              'content-type': 'multipart/form-data'
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getSingle: async (transactionId) => {
        const endpoint = `${URL}/${transactionId}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      calculate: async ({ ...params }) => {
        const endpoint = `${URL}/calculator`
        try {
          const res = await axios.get(endpoint, {
            params,
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      viewImages: async (transactionId) => {
        const endpoint = `${URL}/${transactionId}/view-image`
        try {
          const res = await axios.post(
            endpoint,
            {},
            {
              headers: {
                ...Store.getters['admin/getRequestHeaders'].headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
