// User management
import UserManagement from '@/views/admin/userManagement.vue'
import UserStatsFilter from '@/views/admin/userManagement/userStatsFilter.vue'
import UMUser from '@/views/admin/userManagement/user.vue'
import UMViewUser from '@/views/admin/userManagement/userInfo.vue'
import UMStaffs from '@/views/admin/userManagement/staffs.vue'
import UMFeedbacks from '@/views/admin/userManagement/feedback.vue'
import UMAmbassador from '@/views/admin/userManagement/ambassador.vue'

import FundTransfer from '../../views/admin/userManagement/fundTransfer.vue'
// import AdminFundTransfer from '../../views/admin/userManagement/adminFundTransfer.vue'
import BonusConversion from '../../views/admin/userManagement/bonusConversion'
import Referrals from '../../views/admin/userManagement/referrals'
import Wallet from '../../views/admin/userManagement/wallet'
import WalletHistory from '../../views/admin/userManagement/walletHistory.vue'
import cashbackWalletHistory from '../../views/admin/userManagement/cashbackHistory.vue'

import PromoCodeHistory from '../../views/admin/promoCodes/promoCodeHistory.vue'
import TokenBonusConversion from '../../views/admin/userManagement/tokenConversion'
import Cashback from '@/views/admin/userManagement/cashback.vue'

const userRoutes = {
  path: '/yurd2m/yurd2m/users-management',
  component: UserManagement,
  redirect: '/yurd2m/yurd2m/sales/users-management/users',
  name: 'UserManagement',
  meta: {
    title: 'UserManagement'
  },
  children: [
    {
      path: '/yurd2m/yurd2m/users-management/users',
      component: UMUser,
      name: 'AllUsers',
      meta: {
        title: 'Users'
      }
    },
    {
      path: '/yurd2m/yurd2m/users-management/user-stats-filter',
      component: UserStatsFilter,
      name: 'UserStatsFilter',
      meta: {
        title: 'User Stats Filter'
      }
    },
    {
      path: '/yurd2m/yurd2m/users-management/view-user/:id',
      component: UMViewUser,
      name: 'ViewUser',
      meta: {
        title: 'View User'
      }
    },
    {
      path: '/yurd2m/yurd2m/users-management/feedbacks',
      component: UMFeedbacks,
      name: 'Feedbacks',
      meta: {
        title: 'Feedbacks'
      }
    },
    {
      path: '/yurd2m/yurd2m/users-management/staffs',
      component: UMStaffs,
      name: 'Staff',
      meta: {
        title: 'Staff'
      }
    },
    {
      path: '/yurd2m/yurd2m/users-management/ambassador',
      component: UMAmbassador,
      name: 'Ambassador',
      meta: {
        title: 'Ambassador'
      }
    },
    {
      path: '/yurd2m/yurd2m/users-management/funds-transfer',
      component: FundTransfer,
      name: 'FundTransfer',
      meta: {
        title: 'Fund Transfer'
      }
    },
    // {
    //   path: '/yurd2m/yurd2m/users-management/yurd2m-funds-transfer',
    //   component: AdminFundTransfer,
    //   name: 'AdminFundTransfer',
    //   meta: {
    //     title: 'Admin Fund Transfer'
    //   }
    // },
    {
      path: '/yurd2m/yurd2m/users-management/bonus-conversion',
      component: BonusConversion,
      name: 'BonusConversion',
      meta: {
        title: 'Bonus Conversion'
      }
    },
    {
      path: '/yurd2m/yurd2m/users-management/referrals',
      component: Referrals,
      name: 'ReferralsCount',
      meta: {
        title: 'Referral Count'
      }
    },
    {
      path: '/yurd2m/yurd2m/users-management/token-conversion',
      component: TokenBonusConversion,
      name: 'TokenBonusConversion',
      meta: {
        title: 'Token Bonus Conversion'
      }
    },
    {
      path: '/yurd2m/yurd2m/users-management/user-wallet',
      component: Wallet,
      name: 'UsersWallet',
      meta: {
        title: 'Wallet'
      }
    },
    {
      path: '/yurd2m/yurd2m/users-management/user-cashback',
      component: Cashback,
      name: 'UsersCashback',
      meta: {
        title: 'Cashback'
      }
    },
    {
      path: '/yurd2m/yurd2m/users-management/user-wallet-history',
      component: WalletHistory,
      name: 'UsersWalletHistory',
      meta: {
        title: 'Wallet History'
      }
    },
    {
      path: '/yurd2m/yurd2m/users-management/cashback-wallet-history',
      component: cashbackWalletHistory,
      name: 'UsersCashbackWalletHistory',
      meta: {
        title: 'Cashback History'
      }
    },
    {
      path: '/yurd2m/yurd2m/users-management/user-bill-history',
      component: () =>
        import('../../views/admin/userManagement/billHistory.vue'),
      name: 'UsersBillHistory',
      meta: {
        title: 'Bill History'
      }
    },
    {
      path: '/yurd2m/yurd2m/users-management/promo-code-history',
      component: PromoCodeHistory,
      name: 'PromoCodeHistory',
      meta: {
        title: 'Promo Code History'
      }
    }
  ]
}

export default userRoutes
