var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(!_vm.checkIfPermission('view testimonial', _vm.admin.permissions))?_c('div',{staticClass:"w-100 h-100 d-flex justify-center align-center"},[_c('h1',{staticClass:"error--text"},[_vm._v("You don't have access to this page")])]):_c('div',[_c('h1',[_vm._v("Testimonials")]),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.addTestimonialDialog),callback:function ($$v) {_vm.addTestimonialDialog=$$v},expression:"addTestimonialDialog"}},[_c('v-card',{staticClass:"pa-5",attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Create New Testimonial")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Testimonial Message"}}),_c('v-select',{attrs:{"label":"Status","items":['Active', 'Inactive']}}),_c('v-btn',{attrs:{"block":"","color":"accent"}},[_vm._v("Add Testimonial")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.updateTestimonialDialog),callback:function ($$v) {_vm.updateTestimonialDialog=$$v},expression:"updateTestimonialDialog"}},[_c('v-card',{staticClass:"pa-5",attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Update Testimonial")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Testimonial Message"}}),_c('v-select',{attrs:{"label":"Status","items":['Active', 'Inactive']}}),_c('v-btn',{attrs:{"block":"","color":"accent"}},[_vm._v("Update Testimonial")])],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"d-flex align-baseline"},[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search...","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"items":_vm.arranged,"loading":_vm.arranged.length == 0,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"color":"primary","size":"45px"}},[(item.image != 'undefined' || !item.image)?_c('v-img',{attrs:{"src":item.image}}):_c('span',[_vm._v(_vm._s(((item.fname[0]) + " " + (item.lname[1]))))])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(((item.fname) + " " + (item.lname)))+" ")])]}},{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.username))]),_c('br'),_c('small',{},[_vm._v(_vm._s(item.date))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{class:("text-capitalize rounded py-2 px-3 white--text " + (item.status ? 'success' : 'warning'))},[_vm._v(_vm._s(item.status ? 'Active' : 'Inactive'))])]}},{key:"item.details",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"py-2",staticStyle:{"font-size":"14px"},domProps:{"innerHTML":_vm._s(item.details)}})]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[(!item.status)?_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.updateTestimonial(item, true)}}},[_c('v-list-item-title',[_vm._v("Activate")])],1):_vm._e(),(item.status)?_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.updateTestimonial(item, false)}}},[_c('v-list-item-title',[_vm._v("Deactivate")])],1):_vm._e()],1)],1)]}}],null,true)})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }