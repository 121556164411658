import Store from '../../store'
import { errorHandler, successHandler } from '../helpers/handler'
import adminHttp from './http'

const axios = adminHttp

const URL = `permissions`

export default {
  permissions() {
    return {
      getAll: async ({ params = {} }) => {
        const endpoint = `${URL}`
        try {
          const res = await axios.get(endpoint, {
            params,
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers,
              'Content-Type': 'application/json'
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      updateUserPermission: async ({ userId, permissions }) => {
        const endpoint = `${URL}/${userId}/update`
        try {
          const res = await axios.post(
            endpoint,
            {
              permissions
            },
            {
              headers: {
                ...Store.getters['admin/getRequestHeaders'].headers,
                'Content-Type': 'application/json'
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getCurrentUserPermissions: async () => {
        const endpoint = `${URL}/current-user`
        try {
          const res = await axios.get(endpoint, {
            params: {
              populate: 'permissions'
            },
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers,
              'Content-Type': 'application/json'
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
