<template>
  <v-container>
    <div
      v-if="!checkIfPermission('create default data', admin.permissions)"
      class="w-100 h-100 d-flex justify-center align-center"
    >
      <h1 class="error--text">You don't have access to this page</h1>
    </div>
    <div v-else>
      <h1>System Settings</h1>
      <v-row>
        <v-col>
          <v-card flat>
            <v-card-title>System Settings</v-card-title>
            <v-card-subtitle
              >Modify platform general settings here
            </v-card-subtitle>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    v-model="siteSettings.currency"
                    hint="Platform base currency"
                    persistent-hint
                    label="System Currency"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    v-model="siteSettings.currency_sym"
                    hint="Platform base currency symbol"
                    persistent-hint
                    label="System Currency Symbol"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    hint="Customer Relation Contact Phone Number"
                    v-model="siteSettings.phone"
                    persistent-hint
                    label="Contact Number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    hint="Contact Email"
                    v-model="siteSettings.email"
                    persistent-hint
                    label="Platform Email Address"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    hint="Platform facebook link"
                    v-model="siteSettings.facebook"
                    persistent-hint
                    label="Facebook Link"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    hint="Platform Instagram Link"
                    v-model="siteSettings.instagram"
                    persistent-hint
                    label="Instagram Link"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    hint="Platform Twitter Link"
                    v-model="siteSettings.twitter"
                    persistent-hint
                    label="Twitter Link"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    dense
                    auto-grow
                    filled
                    :disabled="loading"
                    hint="Office Address"
                    v-model="siteSettings.address"
                    persistent-hint
                    label="Office Address"
                  ></v-textarea>
                </v-col>
                <!-- <v-col cols="12" md="6">
                <v-text-field
                  suffix="NGN"
                  filled
                  :disabled="loading"
                  hint="Bonus amount for user when there referral completes KYC verification. Value in N"
                  persistent-hint
                  label="Referral Bonus"
                ></v-text-field>
              </v-col> -->
                <!-- <v-col cols="12" md="6">
                <v-text-field
                  filled
                  :disabled="loading"
                  hint="What user earn daily"
                  persistent-hint
                  label="Daily Bonus Earning"
                ></v-text-field>
              </v-col> -->
                <v-col cols="12" md="6">
                  <v-text-field
                    suffix="NGN"
                    filled
                    :disabled="loading"
                    v-model="siteSettings.ref"
                    persistent-hint
                    label="Referral Bonus"
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="12" md="6">
                <v-text-field
                  suffix="NGN"
                  filled
                  :disabled="loading"
                  hint="% earning on successful sell transaction (This value x USD Amount = Bonus earning in NGN)"
                  persistent-hint
                  label="Referral Sell Bonus"
                ></v-text-field>
              </v-col> -->
                <!-- <v-col cols="12" md="6">
                <v-text-field
                  suffix="USD"
                  filled
                  :disabled="loading"
                  hint="Maximum Amount Only Email Verified Members can Buy in USD"
                  v-model="siteSettings.nokyclimit"
                  persistent-hint
                  label="Level 1 Verified Members Limit"
                ></v-text-field>
              </v-col> -->
                <!-- <v-col cols="12" md="6">
                <v-text-field
                  suffix="USD"
                  filled
                  :disabled="loading"
                  hint="Maximum Amount Only Email and KYC Verified Members can Buy in USD"
                  persistent-hint
                  label="Level 2 Verified Members Limit"
                ></v-text-field>
              </v-col> -->
                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    hint="Minimum Bonus Conversion"
                    persistent-hint
                    label="Minimum Bonus Conversion"
                    v-model="siteSettings.minbonus"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    hint="Business Profit Amount that charge VAT on"
                    persistent-hint
                    v-model="siteSettings.bizprofit"
                    label="Exchange Profit Amount"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    hint="Buy &amp; Sell Minimum Trade Amount in USD"
                    v-model="siteSettings.trademinimum"
                    persistent-hint
                    label="Minimum Trade Amount"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    suffix="%"
                    filled
                    :disabled="loading"
                    v-model="siteSettings.transcharge"
                    hint="Buy &amp; Sell Transaction Charge in %"
                    persistent-hint
                    label="Transaction Charge(VAT)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    suffix="MINS"
                    filled
                    :disabled="loading"
                    hint="Minimum confirmation time for giftcard sale"
                    v-model="siteSettings.giftcardResponseTime"
                    persistent-hint
                    label="Response Time"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    suffix="%"
                    filled
                    :disabled="loading"
                    hint="Percentage of Referral per transaction in %"
                    v-model="siteSettings.referPercentage"
                    persistent-hint
                    label="Referral Percentage"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    hint="Amount of snappy token given to users per transaction"
                    v-model="siteSettings.tokenBonusAmount"
                    persistent-hint
                    label="Token Amount"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    hint="Minimum Android Version"
                    v-model="siteSettings.minimumAndroidVersion"
                    persistent-hint
                    label="Minimum Android Version"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    hint="Minimum Ios Version"
                    v-model="siteSettings.minimumIosVersion"
                    persistent-hint
                    label="Minimum Ios Version"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-switch
                    filled
                    :disabled="loading"
                    hint="Turn off to take a break"
                    v-model="siteSettings.giftcardFeatureAvailable"
                    persistent-hint
                    label="Giftcard Feature Available"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    hint="Message user will see when taking a Giftcard transaction break"
                    v-model="siteSettings.giftcardFeatureMessage"
                    persistent-hint
                    label="Giftcard Feature Message"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-switch
                    filled
                    :disabled="loading"
                    hint="Turn off to take a break"
                    v-model="siteSettings.cryptoFeatureAvailable"
                    persistent-hint
                    label="Crypto Feature Available"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    hint="Message user will see when taking a Crypto transaction break"
                    v-model="siteSettings.cryptoFeatureMessage"
                    persistent-hint
                    label="Crypto Feature Message"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-switch
                    filled
                    :disabled="loading"
                    hint="Turn off to take a break"
                    v-model="siteSettings.airtimeFeatureAvailable"
                    persistent-hint
                    label="Airtime Feature Available"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    hint="Message user will see when taking a Airtime transaction break"
                    v-model="siteSettings.airtimeFeatureMessage"
                    persistent-hint
                    label="Airtime Feature Message"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-switch
                    filled
                    :disabled="loading"
                    hint="Turn off to take a break"
                    v-model="siteSettings.dataFeatureAvailable"
                    persistent-hint
                    label="Data Feature Available"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    hint="Message user will see when taking a Data transaction break"
                    v-model="siteSettings.dataFeatureMessage"
                    persistent-hint
                    label="Data Feature Message"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-switch
                    filled
                    :disabled="loading"
                    hint="Turn off to take a break"
                    v-model="siteSettings.cableFeatureAvailable"
                    persistent-hint
                    label="Cable Feature Available"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    hint="Message user will see when taking a Cable transaction break"
                    v-model="siteSettings.cableFeatureMessage"
                    persistent-hint
                    label="Cable Feature Message"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-switch
                    filled
                    :disabled="loading"
                    hint="Turn off to take a break"
                    v-model="siteSettings.bettingFeatureAvailable"
                    persistent-hint
                    label="Betting Feature Available"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    hint="Message user will see when taking a Betting transaction break"
                    v-model="siteSettings.bettingFeatureMessage"
                    persistent-hint
                    label="Betting Feature Message"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-switch
                    filled
                    :disabled="loading"
                    hint="Turn off to take a break"
                    v-model="siteSettings.electricityFeatureAvailable"
                    persistent-hint
                    label="Electricity Feature Available"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    hint="Message user will see when taking a Electricity transaction break"
                    v-model="siteSettings.electricityFeatureMessage"
                    persistent-hint
                    label="Electricity Feature Message"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-switch
                    filled
                    :disabled="loading"
                    hint="Turn off to take a break"
                    v-model="siteSettings.airtimeToCashFeatureAvailable"
                    persistent-hint
                    label="Airtime To Cash Feature Available"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    hint="Message user will see when taking a Electricity transaction break"
                    v-model="siteSettings.airtimeToCashFeatureMessage"
                    persistent-hint
                    label="Airtime to Cash Feature Message"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-switch
                    filled
                    :disabled="loading"
                    hint="Turn off to take a break"
                    v-model="siteSettings.withdrawalFeatureAvailable"
                    persistent-hint
                    label="Withdrawal Feature Available"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    hint="Message user will see when taking a Withdrawal transaction break"
                    v-model="siteSettings.withdrawalFeatureMessage"
                    persistent-hint
                    label="Withdrawal Feature Message"
                  ></v-text-field>
                </v-col>

                <v-col>
                  <v-text-field
                    filled
                    :disabled="loading"
                    type="date"
                    v-model="siteSettings.rankingStartDate"
                    persistent-hint
                    label="Ranking Start Date"
                  ></v-text-field>
                </v-col>

                <v-col>
                  <v-text-field
                    filled
                    :disabled="loading"
                    type="number"
                    v-model="siteSettings.signUpBonus"
                    persistent-hint
                    label="Sign Up Bonus"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    v-model="siteSettings.bettingRate"
                    persistent-hint
                    label="Betting Rate"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    v-model="siteSettings.minimumCashbackPoint"
                    persistent-hint
                    label="Minimum Cashback Point"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    v-model="siteSettings.cashbackNairaConversionRate"
                    persistent-hint
                    label="Cashback Naira Conversion Rate"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    v-model="siteSettings.cableRate"
                    persistent-hint
                    label="Cable Rate"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    hint="Electricity Rate"
                    v-model="siteSettings.electricityRate"
                    persistent-hint
                    label="Electricity Rate"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    hint="Airtime to Cash Rate"
                    v-model="siteSettings.airtimeToCashRate"
                    persistent-hint
                    suffix="%"
                    label="Airtime to Cash Rate in %"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    hint="Airtime Rate"
                    v-model="siteSettings.airtimeRate"
                    persistent-hint
                    suffix="%"
                    label="Airtime Rate in %"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    hint="Data Rate"
                    v-model="siteSettings.dataRate"
                    persistent-hint
                    suffix="%"
                    label="Data Rate in %"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    hint="Wallet Funding Bank Name"
                    v-model="siteSettings.walletFundingBankName"
                    persistent-hint
                    suffix="%"
                    label="Wallet Funding Bank Name"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    hint="Wallet Funding Account Name"
                    v-model="siteSettings.walletFundingAccountName"
                    persistent-hint
                    suffix="%"
                    label="Wallet Funding Account Name"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    hint="Wallet Funding Account Number"
                    v-model="siteSettings.walletFundingAccountNumber"
                    persistent-hint
                    suffix="%"
                    label="Wallet Funding Account Number"
                  ></v-text-field>
                </v-col>

                <!-- <v-col cols="12" md="6">
                <v-text-field
                  suffix="NGN"
                  filled
                  :disabled="loading"
                  hint="What user gets upon KYC verification"
                  persistent-hint
                  label="KYC Earning"
                ></v-text-field>
              </v-col> -->
              </v-row>
            </v-card-text>
            <v-card-actions
              v-if="checkIfPermission('manage default data', admin.permissions)"
            >
              <v-spacer></v-spacer>
              <v-btn
                color="accent"
                @click="updateSiteData"
                :loading="loading"
                depressed
                width="150"
                class="font-weight-bold"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>

          <v-card flat class="mt-10">
            <v-card-title>System Settings</v-card-title>
            <v-card-text>
              <div>
                <v-select
                  :items="availableCountries"
                  label="Country"
                  v-model="selectedCountry"
                />
              </div>
              <div>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        filled
                        :disabled="loading"
                        v-model="countryValues.referralBonus"
                        persistent-hint
                        :label="`${selectedCountry} Referral Bonus`"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        filled
                        :disabled="loading"
                        hint="Minimum Bonus"
                        v-model="countryValues.minimumBonus"
                        persistent-hint
                        :label="`${selectedCountry} Minimum Bonus`"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        filled
                        :disabled="loading"
                        hint="Minimum Token"
                        v-model="countryValues.minimumToken"
                        persistent-hint
                        :label="`${selectedCountry} Minimum Token`"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        filled
                        :disabled="loading"
                        hint="Stamp Duty"
                        v-model="countryValues.stampDuty"
                        persistent-hint
                        :label="`${selectedCountry} Stamp Duty`"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        filled
                        :disabled="loading"
                        v-model="countryValues.usdRate"
                        persistent-hint
                        :label="`${selectedCountry} USD Rate`"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        filled
                        :disabled="loading"
                        v-model="countryValues.vat"
                        persistent-hint
                        :label="`${selectedCountry} Vat`"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        filled
                        :disabled="loading"
                        v-model="countryValues.tokenRate"
                        persistent-hint
                        label="Token Rate"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-select
                        filled
                        :disabled="loading"
                        v-model="countryValues.autoPayout"
                        persistent-hint
                        label="Auto Withdrawal"
                        item-text="text"
                        item-value="value"
                        :items="[
                          { text: 'Yes', value: 'true' },
                          { text: 'No', value: 'false' }
                        ]"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions
                  v-if="
                    checkIfPermission(
                      'update country settings',
                      admin.permissions
                    )
                  "
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    color="accent"
                    @click="updateCurrencySiteData"
                    :loading="loadingCurrency"
                    depressed
                    width="150"
                    class="font-weight-bold"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import dataApi from '../../../api/systemData'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      loading: false,
      loadingCurrency: false,
      selectedCountry: 'NIGERIA',
      countryValues: {}
    }
  },
  computed: {
    ...mapState({
      siteSettings: (state) => state.admin.siteSettings,
      admin: (state) => state.admin.admin,
      countrySetting: (state) => state.admin.countrySetting,
      availableCountries() {
        return Object.keys(this.countrySetting).map((country) =>
          country.toUpperCase()
        )
      }
    })
  },
  watch: {
    selectedCountry(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.countryValues = this.countrySetting[
          this.selectedCountry?.toLocaleLowerCase()
        ]
      }
    }
  },
  created() {
    this.getSiteSettings()
    this.getCountrySetting()
  },
  methods: {
    async getSiteSettings() {
      const res = await dataApi.data().siteSettings()
      this.$store.commit('admin/setSiteSettings', res.data.data)
    },
    async updateSiteData() {
      this.loading = true
      const res = await dataApi.data().updateSite(this.siteSettings)
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        return
      }
      this.$store.dispatch('alert', {
        message: 'Updated Successfully',
        status: true
      })
      this.loading = false
    },
    async getCountrySetting() {
      const res = await dataApi.data().countrySetting()
      this.$store.commit('admin/setCountrySetting', res.data.data)
      this.countryValues = this.countrySetting[
        this.selectedCountry?.toLocaleLowerCase()
      ]
      if (this.countryValues?.autoPayout !== '') {
        this.countryValues.autoPayout = this.countryValues.autoPayout.toString()
      }
    },
    async updateCurrencySiteData() {
      this.loadingCurrency = true
      const res = await dataApi.data().updateCountrySetting({
        ...this.countryValues,
        autoPayout: this.countryValues.autoPayout === 'true'
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        return
      }
      this.$store.dispatch('alert', {
        message: 'Updated Successfully',
        status: true
      })
      this.loadingCurrency = false
    }
  }
}
</script>

<style></style>
